import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AREA_LEVEL_NONE, IAreaLevel, IAreaType } from '../../../../model';
import { RootState } from '../../../../store';
import { getAreaTypes, IAreaTypeNameById } from './area-epic';

export interface IAreaTypesState {
  loading: boolean;
  data: Array<IAreaType>;
  levels: IAreaLevel[];
  types: IAreaTypeNameById;
}

const initialState: IAreaTypesState = {
  loading: false,
  data: [],
  levels: [],
  types: {},
};

const slice = createSlice({
  name: 'areaTypes',
  initialState: initialState,
  reducers: {
    fetchAreaTypes(state) {
      state.loading = true;
      state.data = [];
      state.levels = [];
      state.types = {};
    },
    fetchAreaTypesSuccess(state, action: PayloadAction<IAreaType[]>) {
      state.loading = false;
      state.data = action.payload;
      state.levels = getAreaLevels(action.payload);
      state.types = getAreaTypes(action.payload);
    },
    fetchAreaTypesFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      console.log(action);
    },
  },
});

function parseAreaLevels(areaTypes: Array<IAreaType>): Array<IAreaLevel> {
  let result: Array<IAreaLevel> = [];

  const names: Array<{ name: string; order: number }> = [];
  let level: number | null = null;
  let revenueEnabled = false;

  areaTypes.forEach((t) => {
    if ((level === null || level === t.Level) && t.PluralName) {
      names.push({ name: t.PluralName, order: t.Order });
      level = t.Level;
      revenueEnabled = t.RevenueEnabled;
    }

    if (t.Descendants && t.Descendants.length > 0) {
      result = result.concat(parseAreaLevels(t.Descendants));
    }
  });

  if (names.length > 0 && level !== null) {
    names.sort((a, b) => a.order - b.order);
    result.unshift({
      id: level,
      name: names.map((l) => l.name).join(', '),
      level: level,
      revenueEnabled: revenueEnabled,
      order: names[names.length - 1].order,
    });
  }

  return result;
}

function getAreaLevels(areaTypes: Array<IAreaType>) {
  if (areaTypes.length === 0) {
    return [];
  }

  const areaLevels = parseAreaLevels(areaTypes);
  areaLevels.sort((a, b) => a.order - b.order);
  areaLevels.unshift(AREA_LEVEL_NONE);

  return areaLevels;
}

// Actions
export const areaTypesActions = slice.actions;

// Selectors
export const selectAreaTypesLoading = (state: RootState) => state.areaTypes.loading;
export const selectAreaTypes = (state: RootState) => state.areaTypes.data;
export const selectAreaLevels = (state: RootState) => state.areaTypes.levels;
export const selectAreaTypeNames = (state: RootState) => state.areaTypes.types;

// Reducer
export const areaTypesReducer = slice.reducer;
