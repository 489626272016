import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import { ISelectedBlockface } from '../../../../model/map/layers';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';
import {
  IKpiReportItem,
  IOccupancyReportItem,
  IOccupancySourceReport,
  IOccupancyTrafficReport,
  IRevenueItem,
  ITrafficReportItem,
  IViolationInfo,
  IZoneRevenueReportItem,
  OccupancySource,
} from '../../../../model';
import { ISpotOccupancyStatusChangeDto } from '../../../../services/signaling';

export interface ISelectedBlockfaceState {
  selected: ISelectedEntity<ISelectedBlockface>[];
  current: number | null;
}

const initialState: ISelectedBlockfaceState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedBlockfaces',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Blockfaces,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedBlockfacesActions.loadBlockface(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Blockfaces, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Blockfaces);
    },
    loadBlockface(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadBlockfaceSuccess(state, action: PayloadAction<{ blockface: ISelectedBlockface; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.blockface.Id,
        action.payload.blockface,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadBlockfaceFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Blockfaces, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Blockfaces);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Blockfaces, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedBlockface | null>) {
      state.current = action.payload?.Id || null;
    },

    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },
    fetchViolationReport(state, action: PayloadAction<{ blockfaceId: number; filter: IReportFilter }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IViolationInfo | null }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      if (!blockface.entity) {
        return;
      }
      blockface.entity.violationReport = action.payload.report;
    },
    fetchViolationReportFailed(state, action: PayloadAction<{ blockfaceId: number; error: string }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenueReport(state, action: PayloadAction<{ blockfaceId: number; filter: IReportFilter }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IZoneRevenueReportItem | null }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;

      if (!blockface.entity) {
        return;
      }
      blockface.entity.revenueReport = action.payload.report;
    },
    fetchRevenueReportFailed(state, action: PayloadAction<{ blockfaceId: number; error: string }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      console.error(action.payload.error);
    },

    fetchOccupancyTrafficRepor(
      state,
      action: PayloadAction<{ blockfaceId: number; filter: IReportFilter; occupancySource: OccupancySource }>,
    ) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = true;
    },
    fetchOccupancyTrafficReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IOccupancyTrafficReport | null }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      if (!blockface.entity) {
        return;
      }
      blockface.entity.occupancyTrafficReportData = action.payload.report;
    },
    fetchOccupancyTrafficReportFailed(state, action: PayloadAction<{ blockfaceId: number; error: string }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenue(state, action: PayloadAction<number>) {},
    fetchRevenueSuccess(state, action: PayloadAction<{ blockfaceId: number; revenue: IRevenueItem[] }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface?.entity) {
        return;
      }
      blockface.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchKpiReport(state, action: PayloadAction<number>) {},
    fetchKpiReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IKpiReportItem[] }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface?.entity) {
        return;
      }
      blockface.entity.kpiReport = action.payload.report;
    },
    fetchKpiReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchTrafficReport(state, action: PayloadAction<number>) {},
    fetchTrafficReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: ITrafficReportItem[] }>) {
      const blockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!blockface?.entity) {
        return;
      }
      blockface.entity.trafficReport = action.payload.report;
    },
    fetchTrafficReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancyReport(state, action: PayloadAction<{ blockfaceId: number; occupancySource: OccupancySource }>) {},
    fetchOccupancyReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IOccupancyReportItem[] }>) {
      const bockface = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!bockface?.entity) {
        return;
      }
      bockface.entity.occupancyReport = action.payload.report;
    },
    fetchOccupancyReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancySourceReport(
      state,
      action: PayloadAction<{ blockfaceId: number; period: [Date, Date]; occupancySource: OccupancySource }>,
    ) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancySourceReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IOccupancySourceReport | null }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!item?.entity) {
        return;
      }
      item.loading = false;
      item.entity.occupancySourceReport = action.payload.report;
    },
    fetchOccupancySourceReportFailed(state, action: PayloadAction<{ blockfaceId: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.blockfaceId);
      if (!item) {
        return;
      }
      item.loading = false;
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedBlockfacesActions = slice.actions;

// Selectors
export const selectedBlockfaces = (state: RootState) => state.selectedBlockfaces;
export const currentBlockface = (state: RootState) =>
  state.selectedBlockfaces.selected.find((x) => x.id === state.selectedBlockfaces.current);

// Reducer
export const selectedBlockfacesReducer = slice.reducer;
