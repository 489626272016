import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlert } from '../../../model';
import { RootState } from '../../../store';

export interface IAlertsState {
  alerts: Array<IAlert>;
}

const initialState: IAlertsState = {
  alerts: [],
};

const slice = createSlice({
  name: 'alerts',
  initialState: initialState,
  reducers: {
    addAlert(state, action: PayloadAction<IAlert>) {
      state.alerts = [...state.alerts, ...[action.payload]];
    },
    removeAlert(state, action: PayloadAction<IAlert>) {
      state.alerts = state.alerts.filter((x) => !(x.id === action.payload.id && x.type === action.payload.type));
    },
  },
});

// Actions
export const alertsActions = slice.actions;

// Selectors
export const selectAlertsState = (state: RootState) => state.alerts;

// Reducer
export const alertsReducer = slice.reducer;
