import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOffstreetCount, IOffstreetVendor, OffstreetZoneType, SavedOffstreetZoneLayer, TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

type TypeCount = { [key in OffstreetZoneType]: { Count: number; CityOwnershipCount: number } };

const getEmptyTypesCount = (): TypeCount => ({ Garage: { Count: 0, CityOwnershipCount: 0 }, Lot: { Count: 0, CityOwnershipCount: 0 } });

export interface IOffstreetZonesLayerState {
  enabled: boolean;
  vendors: Array<IOffstreetVendor>;
  vendorsFilter: TagFilter;
  showVendorLogo: boolean;
  typesFilter: TagFilter;
  count: number;
  showOnlyCityOwnedFacilities: boolean;
  typesCount: TypeCount;
}

const initialState: IOffstreetZonesLayerState = {
  enabled: false,
  vendors: [],
  vendorsFilter: new TagFilter(false),
  showVendorLogo: false,
  typesFilter: new TagFilter(true),
  count: 0,
  showOnlyCityOwnedFacilities: false,
  typesCount: getEmptyTypesCount(),
};

const slice = createSlice({
  name: 'offstreetZonesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchVendors(state) {},
    fetchVendorsSuccess(state, action: PayloadAction<Array<IOffstreetVendor>>) {
      state.vendors = action.payload;
      state.vendorsFilter = action.payload.length > 0 ? state.vendorsFilter.enable(true) : new TagFilter(false);
    },
    fetchVendorsFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setVendorsFilter(state, action: PayloadAction<TagFilter>) {
      state.vendorsFilter = action.payload;
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<IOffstreetCount[]>) {
      let count = 0;
      const typesCount = getEmptyTypesCount();
      for (const x of action.payload) {
        count += x.Count;
        typesCount[x.Type] = { Count: x.Count, CityOwnershipCount: x.CityOwnershipCount };
      }
      state.count = count;
      state.typesCount = typesCount;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.typesCount = getEmptyTypesCount();
      state.count = 0;
      console.log(action);
    },

    setVendorLogoVisible(state, action: PayloadAction<boolean>) {
      state.showVendorLogo = action.payload;
    },

    setShowOnlyCityOwnedFacilities(state, action: PayloadAction<boolean>) {
      state.showOnlyCityOwnedFacilities = action.payload;
    },

    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedOffstreetZoneLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.showVendorLogo = action.payload.showVendorLogo;
        state.vendorsFilter = TagFilter.fromSavedValue(action.payload.vendorsFilter);
        state.showOnlyCityOwnedFacilities = action.payload.showOnlyCityOwnedFacilities;
        if (action.payload.typesFilter) {
          state.typesFilter = TagFilter.fromSavedValue(action.payload.typesFilter);
        }
      }
    },
  },
});

// Actions
export const offstreetZonesLayerActions = slice.actions;

// Selectors
export const selectOffstreetZonesLayer = (state: RootState) => state.offstreetZonesLayer;
export const selectOffstreetZonesCount = (state: RootState) => state.offstreetZonesLayer.typesCount[OffstreetZoneType.Garage];
export const selectOffstreetVendors = (state: RootState) => state.offstreetZonesLayer.vendors;

// Reducer
export const offstreetZonesLayerReducer = slice.reducer;
