import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TagFilter } from '../../../../model';
import { RootState } from '../../../../store';

export interface ISuggestetStreetsGeoState {
  enforcements: FeatureCollection;
  enforcementsCount: number;
  plannings: FeatureCollection;
  planningsCount: number;
  managements: FeatureCollection;
  managementsCount: number;
  studyAreas: FeatureCollection;
}

const initialState: ISuggestetStreetsGeoState = {
  enforcements: EMPTY_FEATURE_COLLECTION,
  enforcementsCount: 0,
  plannings: EMPTY_FEATURE_COLLECTION,
  planningsCount: 0,
  managements: EMPTY_FEATURE_COLLECTION,
  managementsCount: 0,
  studyAreas: EMPTY_FEATURE_COLLECTION,
};

const slice = createSlice({
  name: 'suggestedStreetsGeo',
  initialState: initialState,
  reducers: {
    fetchStreets(state, action: PayloadAction<{ cityCode: string; types: TagFilter }>) {},
    fetchStreetsSuccess(
      state,
      action: PayloadAction<{ enforcements: FeatureCollection; plannings: FeatureCollection; managements: FeatureCollection }>,
    ) {
      state.enforcements = action.payload.enforcements;
      state.enforcementsCount = action.payload.enforcements.features.length;
      state.plannings = action.payload.plannings;
      state.planningsCount = action.payload.plannings.features.length;
      state.managements = action.payload.managements;
      state.managementsCount = action.payload.managements.features.length;
    },
    fetchStreetsFailed(state, action: PayloadAction<string>) {
      state = { ...initialState, studyAreas: state.studyAreas };
      console.log(action);
    },

    fetchStudyAreas(state, action: PayloadAction<{ cityCode: string }>) {},
    fetchStudyAreasSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.studyAreas = action.payload;
    },
    fetchStudyAreasFailed(state, action: PayloadAction<string>) {
      state = { ...initialState };
      console.log(action);
    },
  },
});

// Actions
export const suggestedStreetsGeoActions = slice.actions;

// Selectors
export const selectSuggestedStreetsGeo = (state: RootState) => state.suggestedStreetsGeo;

// Reducer
export const suggestedStreetsGeoReducer = slice.reducer;
