/* eslint-disable @typescript-eslint/no-explicit-any */
import { track as amplitudeTrack, identify, Identify, setUserId, getUserId } from '@amplitude/analytics-browser';
import { dateUtils } from '../utils';

export enum AmplitudeEvent {
  Login = 'login',
  Logout = 'logout',
  ReportPageOpen = 'reports_page_view',
  ReportOpen = 'reports_open',
  ForecastPageOpen = 'forecast_page_view',
  CityOverviewOpen = 'kpi_section_view',
  LayerEnabled = 'layer_action',
  PopupOpen = 'open_popup',
  PopupPin = 'pin_popup',
  SidebarFilter = 'sidebar_filters',
  OpenView = 'view_open',
  CreateView = 'view_create',
  UpdateView = 'view_update',
  RemoveView = 'view_remove',
  PopupMove = 'popup_move',
  PopupTabOpen = 'popup_tab_open',
  Error = 'error',
}

export enum NavigationSource {
  Popup = 'Popup',
  Sidebar = 'Sidebar',
  ReportsPage = 'ReportsPage',
  Map = 'Map',
  Search = 'Search',
  Navigation = 'Navigation',
}

export enum LayerName {
  Zones = 'Zones',
  RppZones = 'RppZones',
  RppAreas = 'RppAreas',
  OffstreetZones = 'OffstreetParking',
  CurbSpaces = 'CurbSpaces',
  Meters = 'Meters',
  StreetObjects = 'StreetObjects',
  Heatmap = 'Heatmap',
  Revenue = 'Revenue',
  Enforcement = 'Enforcement',
  ParkingDuration = 'ParkingDuration',
  Traffic = 'Traffic',
  Occupancy = 'Occupancy',
  Study = 'Study',
  Blockfaces = 'Blockfaces',
  Cameras = 'Cameras',
  SuggestedStreets = 'SuggestedStreets',
}

export enum PopupType {
  Areas = 'Areas',
  Zones = 'Zones',
  RppZones = 'RppZones',
  RppAreas = 'RppAreas',
  OffstreetParking = 'OffstreetParking',
  CurbSpaces = 'CurbSpaces',
  Meters = 'Meters',
  StreetObjects = 'StreetObjects',
  HeatmapZone = 'Heatmap.Zones',
  HeatmapBlockface = 'Heatmap.Blockfaces',
  HeatmapStudy = 'Heatmap.Study',
  Study = 'Study',
  Blockfaces = 'Blockfaces',
  Cameras = 'Cameras',
}

export enum FilterName {
  MetersType = 'MetersType',
  MetersStatus = 'MetersStatus',
  MetersVendor = 'MetersVendor',
  MetersShowVendorLogo = 'MetersShowVendorLogo',
  MetersPerformanceParking = 'MetersPerformanceParking',
  ShowParkingSpacesNumber = 'ShowParkingSpacesNumber',
  HeatmapPeriod = 'HeatmapPeriod',
  HeatmapWeekdays = 'HeatmapWeekdays',
  HeatmapDaytime = 'HeatmapDaytime',
  HeatmapRevenueLayer = 'HeatmapRevenueLayer',
  HeatmapTrafficLayer = 'HeatmapTrafficLayer',
  HeatmapRevenueAmount = 'HeatmapRevenueAmount',
  HeatmapEnforcementType = 'HeatmapEnforcementType',
  HeatmapEnforcementMeasure = 'HeatmapEnforcementMeasure',
  HeatmapOccupancySource = 'HeatmapOccupancySource',
  ReportOccupancySource = 'ReportOccupancySource',
  DisplayZoneId = 'DisplayZoneId',
  DisplayParkingTimeLimit = 'DisplayParkingTimeLimit',
  ZonesPerformanceParking = 'ZonesPerformanceParking',
  OffStreetParkingShowVendorLogo = 'OffStreetParkingShowVendorLogo',
  OffStreetParkingShowOnlyCityOwnedFacilities = 'OffStreetParkingShowOnlyCityOwnedFacilities',
  OffStreetParkingVendor = 'OffStreetParkingVendor',
  OffstreetParkingType = 'OffStreetParkingType',
  CurbSpacesStatus = 'CurbSpacesStatus',
  ParkingSpacesGroundSensor = 'ParkingSpacesGroundSensor',
  StreetObjectType = 'StreetObjectType',
  DisplayBlockfaceId = 'DisplayBlockfaceId',
  BlockfacesPerformanceParking = 'BlockfacesPerformanceParking',
  CamerasStatus = 'CamerasStatus',
  SuggestedStreetType = 'SuggestedStreetType',
}

export enum FilterProperty {
  FilterEnabled = 'FilterEnabled',
  HeatmapDaytimeFrom = 'HeatmapDaytimeFrom',
  HeatmapDaytimeTo = 'HeatmapDaytimeTo',
}

export enum PopupTab {
  ZonesGeneralInfo = 'Zones.GeneralInfo',
  ZonesRevenue = 'Zones.Revenue',
  ZonesEnforcement = 'Zones.Enforcement',
  ZonesOccupancy = 'Zones.Occupancy',
  ZonesOccupancyTraffic = 'Zones.OccupancyTraffic',
  OffStreetGeneral = 'OffStreet.General',
  OffStreetRevenue = 'OffStreet.Revenue',
  BlockfacesGeneralInfo = 'Blockfaces.GeneralInfo',
  BlockfacesRevenue = 'Blockfaces.Revenue',
  BlockfacesEnforcement = 'Blockfaces.Enforcement',
  BlockfacesOccupancy = 'Blockfaces.Occupancy',
  BlockfacesOccupancyTraffic = 'Blockfaces.OccupancyTraffic',
  StudyGeneralInfo = 'Study.GeneralInfo',
  StudyRevenue = 'Study.Revenue',
  StudyEnforcemnt = 'Study.Enforcement',
  StudyOccupancy = 'Study.Occupancy',
  StudyOccupancyTraffic = 'Study.OccupancyTraffic',
  HeatmapZoneRevenue = 'Heatmap.Zones.Revenue',
  HeatmapZoneDuration = 'Heatmap.Zones.Duration',
  HeatmapZoneEnforcement = 'Heatmap.Zones.Enforcement',
  HeatmapZoneOccupancyTraffic = 'Heatmap.Zones.OccupancyTraffic',
  HeatmapBlockfaceRevenue = 'Heatmap.Blockfaces.Revenue',
  HeatmapBlockfaceEnforcement = 'Heatmap.Blockfaces.Enforcement',
  HeatmapBlockfaceDuration = 'Heatmap.Blockfaces.Duration',
  HeatmapBlockfaceOccupancyTraffic = 'Heatmap.Blockfaces.OccupancyTraffic',
  HeatmapStudyRevenue = 'Heatmap.Study.Revenue',
  HeatmapStudyEnforcement = 'Heatmap.Study.Enforcement',
  HeatmapStudyDuration = 'Heatmap.Study.Duration',
  HeatmapStudyOccupancyTraffic = 'Heatmap.Study.OccupancyTraffic',
  SpotsGeneralInfo = 'Spots.GeneralInfo',
  SpotsSummary = 'Spots.Summary',
  SpotsOccupancy = 'Spots.Occupancy',
}

export enum ReportName {
  Enforcement = 'enforcement',
  OffStreetRevenue = 'offstreet-parking-revenue',
  ZoneRevenue = 'zone-revenue',
  MeterRevenue = 'meter-revenue',
  ParkingDuration = 'parking-duration',
  BlockfaceRevenue = 'blockface-revenue',
  StudyAreaAnalysis = 'study-areas-analysis',
  StudyAreaOccupancy = 'study-areas-occupancy',
  OccupancyOverview = 'occupancy-overview',
  StudyAreaRevenue = 'study-areas-revenue',
  SpotRevenue = 'spot-revenue',
}

export enum ErrorSource {
  Api = 'Api',
}

const track = (eventName: AmplitudeEvent, payload?: string | boolean | number) => {
  amplitudeTrack(eventName, payload !== undefined ? { payload } : undefined);
};

const trackReport = (reportName: ReportName | string, source: NavigationSource) => {
  amplitudeTrack(AmplitudeEvent.ReportOpen, { ReportName: reportName, Source: source });
};

const trackLayer = (LayerName: LayerName, enabled: boolean) => {
  amplitudeTrack(AmplitudeEvent.LayerEnabled, { LayerName: LayerName, LayerEnabled: enabled });
};

const trackPopupOpen = (type: PopupType, source: NavigationSource) => {
  amplitudeTrack(AmplitudeEvent.PopupOpen, { PopupType: type, Source: source });
};

const trackPopupPin = (type: PopupType) => {
  amplitudeTrack(AmplitudeEvent.PopupPin, { PopupType: type });
};

const trackFilter = (name: FilterName, value: any, property?: FilterProperty) => {
  const payload: Record<string, any> = { FilterName: name };
  payload[property || name] = value;
  amplitudeTrack(AmplitudeEvent.SidebarFilter, payload);
};

const trackFilterHeatmapDaytime = (from: number, to: number) => {
  const payload: Record<string, any> = { FilterName: FilterName.HeatmapDaytime };
  payload[FilterProperty.HeatmapDaytimeFrom] = dateUtils.toHoursString(from);
  payload[FilterProperty.HeatmapDaytimeTo] = dateUtils.toHoursString(to);
  amplitudeTrack(AmplitudeEvent.SidebarFilter, payload);
};

const trackPopupMove = (type: PopupType) => {
  amplitudeTrack(AmplitudeEvent.PopupMove, { PopupType: type });
};

const trackPopupTab = (tab: PopupTab) => {
  amplitudeTrack(AmplitudeEvent.PopupTabOpen, { TabName: tab });
};

const trackError = (source: ErrorSource, error: any) => {
  amplitudeTrack(AmplitudeEvent.Error, { Source: source, Error: error });
};

const setEmail = (userId: string) => {
  const identifyEvent = new Identify();
  identifyEvent.set('Email', userId);
  identify(identifyEvent);
};

const login = (userId: string) => {
  setUserId(userId);
  setEmail(userId);
  track(AmplitudeEvent.Login);
};

const logout = () => {
  track(AmplitudeEvent.Logout);
};

const setCity = (city: string) => {
  const identifyEvent = new Identify();
  identifyEvent.set('Customer', city);
  identify(identifyEvent);
};

const setUser = (userId: string) => {
  const currentUserId = getUserId();
  if (currentUserId !== userId) {
    setUserId(userId);
  }
  setEmail(userId);
};

export const amplitudeService = {
  track,
  trackReport,
  trackLayer,
  trackPopupOpen,
  trackPopupPin,
  trackFilter,
  trackFilterHeatmapDaytime,
  trackPopupMove,
  trackPopupTab,
  trackError,
  login,
  logout,
  setCity,
  setUser,
};
