import { FC } from 'react';

import styles from './StudyAreaIcon.module.scss';

interface Props {
  className?: string;
}

export const StudyAreaIcon: FC<Props> = ({ className }) => {
  return <span className={`${className || ''} ${styles.icon}`}></span>;
};
