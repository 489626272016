import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMeterVendor, SavedMeterLayer, TagFilter, IMeterTypeCount, MeterStatus, IMeterStatusCount } from '../../../../model';
import { RootState } from '../../../../store';

type StatusCount = { [key in MeterStatus]: number };

const getEmptyStatusCount = () => ({ Unknown: 0, Active: 0, TemporarilyInactive: 0 });

export interface IMetersLayerState {
  enabled: boolean;
  vendorsFilter: TagFilter;
  statusesFilter: TagFilter;
  typesFilter: TagFilter;
  types: IMeterTypeCount[];
  vendors: IMeterVendor[];
  showVendorLogo: boolean;
  showSpotsCount: boolean;
  count: number | null;
  showPerformanceParkingOnly: boolean;
  statusCount: StatusCount;
}

const initialState: IMetersLayerState = {
  enabled: false,
  vendorsFilter: new TagFilter(false),
  statusesFilter: new TagFilter(true),
  typesFilter: new TagFilter(true),
  types: [],
  vendors: [],
  showVendorLogo: false,
  showSpotsCount: true,
  count: null,
  showPerformanceParkingOnly: true,
  statusCount: getEmptyStatusCount(),
};

const slice = createSlice({
  name: 'metersLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchVendors(state) {},
    fetchVendorsSuccess(state, action: PayloadAction<IMeterVendor[]>) {
      state.vendors = action.payload;
      state.vendorsFilter = action.payload.length > 0 ? state.vendorsFilter.enable(true) : new TagFilter(false);
    },
    fetchVendorsFailed(state, action: PayloadAction<string>) {
      console.error(action.payload);
    },
    setVendorsFilter(state, action: PayloadAction<TagFilter>) {
      state.vendorsFilter = action.payload;
    },

    setVendorLogoVisible(state, action: PayloadAction<boolean>) {
      state.showVendorLogo = action.payload;
    },
    setSpotsCountVisible(state, action: PayloadAction<boolean>) {
      state.showSpotsCount = action.payload;
    },

    fetchTypes(state) {},
    fetchTypesSuccess(state, action: PayloadAction<IMeterTypeCount[]>) {
      const types = [...action.payload];
      types.sort((a, b) => a.TypeId - b.TypeId);

      state.types = types;
      state.count = types.reduce((acc, current) => acc + current.Count, 0);
      state.typesFilter = types.length > 0 ? state.typesFilter.enable(true) : new TagFilter(false);
    },
    fetchTypesFailed(state, action: PayloadAction<string>) {
      console.error(action.payload);
    },
    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    setStatusesFilter(state, action: PayloadAction<TagFilter>) {
      state.statusesFilter = action.payload;
    },

    setPerformanceParkingFilterValue(state, action: PayloadAction<boolean>) {
      state.showPerformanceParkingOnly = action.payload;
    },

    fetchStatusCount(state) {},
    fetchStatusCountSuccess(state, action: PayloadAction<IMeterStatusCount[]>) {
      const statusCount: StatusCount = getEmptyStatusCount();
      for (const x of action.payload) {
        statusCount[x.Status] = x.Count;
      }
      state.statusCount = statusCount;
    },
    fetchStatusCountFailed(state, action: PayloadAction<string>) {
      state.statusCount = getEmptyStatusCount();
      console.log(action);
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedMeterLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.showVendorLogo = action.payload.showVendorLogo;
        state.showSpotsCount = action.payload.showSpotsCount;
        state.statusesFilter = TagFilter.fromSavedValue(action.payload.statusesFilter);
        state.typesFilter = TagFilter.fromSavedValue(action.payload.typesFilter);
        state.vendorsFilter = TagFilter.fromSavedValue(action.payload.vendorsFilter);
        state.showPerformanceParkingOnly = action.payload.showPerformanceParkingOnly ?? initialState.showPerformanceParkingOnly;
      }
    },
  },
});

// Actions
export const metersLayerActions = slice.actions;

// Selectors
export const selectMetersLayer = (state: RootState) => state.metersLayer;
export const selectMetersEnabled = (state: RootState) => state.metersLayer.enabled;
export const selectMetersShowVendorLogo = (state: RootState) => state.metersLayer.showVendorLogo;
export const selectMetersShowSpotsCount = (state: RootState) => state.metersLayer.showSpotsCount;
export const selectMeterVendors = (state: RootState) => state.metersLayer.vendors;

// Reducer
export const metersLayerReducer = slice.reducer;
