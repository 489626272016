import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox, WeekDay } from '../../../../model';
import { RootState } from '../../../../store';

export interface IRevenueOffstreetZoneLevelGeoState {
  loading: boolean;
  data: FeatureCollection;
}

const initialState: IRevenueOffstreetZoneLevelGeoState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
};

const slice = createSlice({
  name: 'revenueOffstreetZoneGeo',
  initialState: initialState,
  reducers: {
    fetch(
      state,
      action: PayloadAction<{
        box: TilesBoundingBox;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesMin: number;
        minutesMax: number;
      }>,
    ) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },
  },
});

// Actions
export const revenueOffstreetZoneGeoActions = slice.actions;

// Selectors
export const selectRevenueOffstreetZoneGeo = (state: RootState) => state.revenueOffstreetZoneGeo.data;

// Reducer
export const revenueOffstreetZoneGeoReducer = slice.reducer;
