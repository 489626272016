import { Action } from 'redux';
import { combineEpics } from 'redux-observable';

import { catchError, concatMap, filter, from, map, Observable, of } from 'rxjs';

import { keyMetricsActions } from './key-metrics-slice';
import { reports } from '../../../services';

const loadCityKeyMetricsReport = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(keyMetricsActions.loadReport.match),
    concatMap((action) =>
      from(reports.getCityOverviewReport())
        .pipe(
          map((x) => keyMetricsActions.loadReportSuccess(x)),
          catchError((err) => of(keyMetricsActions.loadReportFailed(err.message))),
        ),
    ),
  );

export const keymetricsEpic = combineEpics(
  loadCityKeyMetricsReport
);

