import { FunctionComponent, useEffect, useState } from 'react';
import { Chart, ChartProps } from '@progress/kendo-react-charts';

export type ChartWrapperProps = ChartProps & { refreshOn?: unknown[] };

export const RefreshChartWrapper: FunctionComponent<ChartWrapperProps> = ({ ...props }) => {
  const [refreshChart, setRefreshChart] = useState(true);

  useEffect(() => {
    setRefreshChart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props.refreshOn || []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
    if (refreshChart || !props.refreshOn) {
      chartInstance.setOptions(chartOptions, themeOptions);
    }

    setRefreshChart(false);
  };

  return (
    <Chart onRefresh={handleChartRefresh} {...props}>
      {props.children}
    </Chart>
  );
};
