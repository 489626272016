export interface ISensorSpot {
  SensorId: number;
  SensorName: string;
  SpotId: number;
}

export enum SensorOccupancyStatus {
  Unknown = 'Unknown',
  Vacant = 'Vacant',
  Occupied = 'Occupied',
}

export interface ISensorTelemetry {
  Id: number;
  Name: string;
  Status: SensorOccupancyStatus;
  ChargeLevel: number | null;
  Temperature: number | null;
  UpdatedAt: Date;
}

export interface ISpotStatistics {
  Occupancy: number;
  DailyStateChange: number;
  DailyTurnover: number;
  DailyDwellTime: number;
}
