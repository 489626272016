import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedStreetSign } from '../../../../model';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedStreetSignState {
  selected: ISelectedEntity<ISelectedStreetSign, string>[];
  current: string | null;
}

const initialState: ISelectedStreetSignState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedStreetSigns',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.StreetSigns,
        initialState,
        (id) => id,
        (selected) => {
          selected.map((x) => store.dispatch(selectedStreetSignsActions.loadSign(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: string; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.StreetSigns, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.StreetSigns);
    },
    loadSign(state, action: PayloadAction<{ id: string; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadFailed(state, action: PayloadAction<{ id: string; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    selectSign(state, action: PayloadAction<{ sign: ISelectedStreetSign; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoad(
        state,
        action.payload.sign.Id,
        action.payload.position,
        action.payload.sign,
        null,
        action.payload.initPosition ? action.payload.initPosition : action.payload.position,
      );
    },

    closePopup(state, action: PayloadAction<string | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.StreetSigns, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.StreetSigns);
    },
    pinPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.StreetSigns, action.payload);
    },
  },
});

// Actions
export const selectedStreetSignsActions = slice.actions;

// Selectors
export const selectedStreetSigns = (state: RootState) => state.selectedStreetSigns;
export const currentStreetSign = (state: RootState) =>
  state.selectedStreetSigns.selected.find((x) => x.id === state.selectedStreetSigns.current);

// Reducer
export const selectedStreetSignsReducer = slice.reducer;
