import { IRevenueItem, Period, WeekDay } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';
import { PeriodTypeNumeric } from './reports';

const BASE_URL = '/Revenue';

export interface IRevenueAverageHeatmapData {
  Id: number;
  Revenue: number;
  NormalizedRevenue: number;
  AverageRevenue: number;
  NormalizedAverageRevenue: number;
}

export interface IRevenueHeatmapData {
  Id: number;
  Revenue: number;
  NormalizedRevenue: number;
}

const getRevenue = (query: string): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/${query}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getRevenueHeatmapData = <T>(
  urlSegment: string,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
) => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<T>>(
      `${BASE_URL}${urlSegment}?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
        period[1],
      )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

const getRevenueByArea = (areaId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-area?id=${areaId}`)
    .then((response) => response.data || []);
};

const getRevenueByZone = (zoneId: number) => getRevenue(`by-zone?id=${zoneId}`);

const getRevenueByBlockface = (blockfaceId: number) => getRevenue(`by-blockface?id=${blockfaceId}`);

const getRevenueByStudyArea = (studyAreaId: number) => getRevenue(`by-study-area?id=${studyAreaId}`);

const getRevenueByMeter = (meterId: number) => getRevenue(`by-meter?id=${meterId}`);

const getRevenueByOffstreetZone = (offstreetZoneId: number) => getRevenue(`by-offstreetZone?id=${offstreetZoneId}`);

const getRevenueBySpot = (spotId: number) => getRevenue(`by-spot?id=${spotId}`);

const getStudyAreaRevenueHeatmapData = (period: [Date, Date], weekDays: WeekDay[], minutesStart: number, minutesEnd: number) =>
  getRevenueHeatmapData<IRevenueAverageHeatmapData>('/study-area/heatmap-data', period, weekDays, minutesStart, minutesEnd);

const getSpotRevenueHeatmapData = (period: [Date, Date], weekDays: WeekDay[], minutesStart: number, minutesEnd: number) =>
  getRevenueHeatmapData<IRevenueHeatmapData>('/spot/heatmap-data', period, weekDays, minutesStart, minutesEnd);

export const revenueData = {
  getRevenueByArea,
  getRevenueByZone,
  getRevenueByBlockface,
  getRevenueByStudyArea,
  getRevenueByMeter,
  getRevenueByOffstreetZone,
  getStudyAreaRevenueHeatmapData,
  getRevenueBySpot,
  getSpotRevenueHeatmapData,
};
