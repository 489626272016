import { IEntityBase } from './entity-base';

export enum SignStatus {
  Offline = 'Offline',
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface ISignState {
  SignId: number;
  LastUpdated: Date | null;
  Status: SignStatus;
  ActiveScreen: ISignScreen | null;
}

export interface ISignScreen {
  Id: number;
  Led: number;
}

export interface ISignStateWithPictureUrl extends ISignState {
  PictureUrl: string | null;
}

export interface ISign extends IEntityBase {
  Name: string;
  ZoneId: number | null;
  SpotIds: Array<number>;
  DeviceId: number;
  Address: string;
  Position: [number, number];
  //ActiveScreenId: number | null;
  ImagesIds: Array<number>;
}

export interface ISignTelemetry {
  SignId: number;
  Firmware: string;
  State: number;
  Battery: number;
  RSSI: string;
  Light: number;
  LastUpdated: Date;
}

export interface IScreenChangeRequest {
  ChangeType: ScreenChangeType;
}

export interface ICreateScreenChangeRequest extends IScreenChangeRequest {
  Led: number;
  File: File;
  SignId: number | null;
}

export interface IUpdateScreenChangeRequest extends IScreenChangeRequest {
  Id: number;
  Led: number | null;
  File: File | null;
  SignId: number | null;
}

export interface IRemoveScreenChangeRequest extends IScreenChangeRequest {
  Id: number;
}

export enum ScreenChangeType {
  Create = 1,
  Update,
  Remove,
}

export interface ISignStatusCount {
  Status: SignStatus;
  Count: number;
}
