import { FunctionComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Link.module.scss';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
}

export const Link: FunctionComponent<Props> = ({ className, children, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={cx(
        'k-button k-button-sm k-button-link k-button-link-primary',
        'button',
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
