import { Action } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { geoProcessor, offstreetZones, reports, revenueData } from '../../../../services';
import { RootState } from '../../../../store';
import { mapStateActions } from '../../map-state';
import { selectedZoneHeatmapsActions } from '../heatmaps/selected-zone-heatmaps-slice';
import { revenueBlockfaceGeoActions } from './revenue-blockface-geo-slice';
import { revenueMeterGeoActions } from './revenue-meter-geo-slice';
import { revenueOffstreetZoneGeoActions } from './revenue-offstreet-zone-geo-slice';
import { revenueDataActions } from './revenue-slice';
import { revenueZoneGeoActions } from './revenue-zone-geo-slice';
import { selectedRevenueOffstreetZonesActions } from './selected-revenue-offstreet-zones-slice';
import { citiesActions } from '../../../common';

const loadOffstreetZone = (id: number, state: RootState) => {
  const existing = state.selectedRevenueOffstreetZones.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  const reportFilter = state.heatmapsFilter.periodFilter.heatmapPeriod.calcReportFilter(state.heatmapsFilter.periodFilter.period);

  return zip(from(offstreetZones.get(id)), from(reports.getOffstreetZoneRevenueReport(id, reportFilter))).pipe(
    map(([zone, revenueReport]) => ({
      ...zone,
      revenueReport: revenueReport,
    })),
  );
};

const fetchMeterRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueMeterGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadMetersRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueMeterGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueMeterGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchZoneRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueZoneGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadZonesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueZoneGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueZoneGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchOffstreetZoneRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueOffstreetZoneGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadOffstreetZonesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueOffstreetZoneGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueOffstreetZoneGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const revenueOffstreetZoneSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedRevenueOffstreetZonesActions.loadRevenueZone.match),
    concatMap((action) =>
      loadOffstreetZone(action.payload.id, state$.value).pipe(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mergeMap((x) =>
          of(
            selectedRevenueOffstreetZonesActions.loadRevenueZoneSuccess({
              zone: x,
              position: action.payload.position!,
              initPosition: action.payload.initPosition ? action.payload.initPosition : action.payload.position!,
            }),
          ),
        ),
        catchError((err) => of(selectedRevenueOffstreetZonesActions.loadRevenueZoneFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    mergeMap((x) => of(selectedRevenueOffstreetZonesActions.closePopup(), selectedZoneHeatmapsActions.closePopup())),
  );

const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedRevenueOffstreetZonesActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getOffstreetZoneRevenueReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedRevenueOffstreetZonesActions.fetchRevenueReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedRevenueOffstreetZonesActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchBlockfaceRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueBlockfaceGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadBlockfacesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueBlockfaceGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueBlockfaceGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchStudyAreaRevenueDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueDataActions.fetchStudyAreaRevenueData.match),
    switchMap((action) =>
      from(
        revenueData.getStudyAreaRevenueHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => revenueDataActions.fetchStudyAreaRevenueDataSuccess(x)),
        catchError((err) => of(revenueDataActions.fetchStudyAreaRevenueDataFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(selectedRevenueOffstreetZonesActions.collapsePopups())),
  );

const fetchSpotRevenueHeatmapDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueDataActions.fetchSpotRevenueHeatmapData.match),
    switchMap((action) =>
      from(
        revenueData.getSpotRevenueHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => revenueDataActions.fetchSpotRevenueHeatmapDataSuccess(x)),
        catchError((err) => of(revenueDataActions.fetchSpotRevenueHeatmapDataFailed(err.message))),
      ),
    ),
  );

export const revenueEpic = combineEpics(
  fetchMeterRevenueEpic,
  fetchZoneRevenueEpic,
  fetchOffstreetZoneRevenueEpic,
  revenueOffstreetZoneSelectedEpic,
  closePopupsEpic,
  fetchRevenueReportEpic,
  fetchBlockfaceRevenueEpic,
  fetchStudyAreaRevenueDataEpic,
  citySelectedEpic,
  fetchSpotRevenueHeatmapDataEpic,
);
