import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStudyAreaName, SavedStydyAreasLayer } from '../../../../model';
import { RootState } from '../../../../store';

export interface IStudyAreasLayerState {
  enabled: boolean;
  studyAreas: IStudyAreaName[];
  selectedStudyAreas: number[];
}

const initialState: IStudyAreasLayerState = {
  enabled: false,
  studyAreas: [],
  selectedStudyAreas: [],
};

const sortStudyAreaName = (a: IStudyAreaName, b: IStudyAreaName) => {
  const aCode = parseInt(a.Name.replace(/\D/g, '')); //10A
  const bCode = parseInt(b.Name.replace(/\D/g, '')); //10B
  return aCode !== bCode ? aCode - bCode : a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0;
};

const slice = createSlice({
  name: 'studyAreasLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },

    fetchStudyAreas(state) {},
    fetchStudyAreasSuccess(state, action: PayloadAction<IStudyAreaName[]>) {
      state.studyAreas = [...action.payload].sort(sortStudyAreaName);
      if (state.studyAreas.length === 0) {
        state.selectedStudyAreas = [];
        state.enabled = false;
      }
    },
    fetchStudyAreasFailed(state, action: PayloadAction<string>) {
      state.studyAreas = [];
      console.log(action);
    },

    selectStudyAreas(state, action: PayloadAction<number[]>) {
      state.selectedStudyAreas = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedStydyAreasLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.selectedStudyAreas = action.payload.selectedStudyAreas;
      }
    },
  },
});

// Actions
export const studyAreasLayerActions = slice.actions;

// Selectors
export const selectStudyAreasLayer = (state: RootState) => state.studyAreasLayer;

// Reducer
export const studyAreasLayerReducer = slice.reducer;
