import { Action } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, from, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { areas, cameras, geoProcessor, meters, reports, revenueData, sensors, signs, spots, studyAreas, zones } from '../../../../services';
import { RootState } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { selectedZonesActions } from './selected-zones-slice';
import { zonesGeoActions } from './zones-geo-slice';
import { zonesLayerActions } from './zones-layer-slice';
import { createInactiveCameraState } from '../../../../model';

const fetchEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(zonesGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadZones(action.payload, action.payload.zoom, state$.value.zonesLayer.showPerformanceParkingOnly).pipe(
        map((x) => zonesGeoActions.fetchSuccess({ data: x.data, limits: x.limits })),
        catchError((err) => of(zonesGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const loadZone = (id: number, state: RootState) => {
  const existing = state.selectedZones.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(
    from(zones.get(id)),
    from(spots.getZoneSpotsStates(id)),
    from(signs.getZoneSigns(id)),
    from(meters.getZoneMeterNames(id)),
    from(sensors.getZoneSensorSpots(id)),
    from(studyAreas.getZoneStudyAreaNames(id)),
    from(zones.getPolicies(id)),
  ).pipe(
    mergeMap(([zone, spotsStates, signIds, metersNames, sensors, studyAreas, policies]) =>
      zip(
        of({
          zone,
          spotsStates,
          signIds,
          metersNames,
          sensors,
          studyAreas,
          policies,
        }),
        spotsStates.length ? from(cameras.getCameraBySpotsIds(spotsStates.map((x) => x.SpotId))) : of([]),
        zone.AreaId ? from(areas.get(zone.AreaId)) : of(null),
        signIds.length ? zip(...signIds.map((x) => from(signs.getSignState(x)))) : of([]),
      ),
    ),
    mergeMap(([zone, camerasIds, area, signsStates]) =>
      (camerasIds.length > 0
        ? zip(
            ...camerasIds.map((x) =>
              from(cameras.getState(x.CameraId)).pipe(map((s) => (s === null ? createInactiveCameraState(x.CameraId) : s))),
            ),
          )
        : of([])
      ).pipe(
        map((x) => ({
          zone,
          area,
          signsStates,
          camerasStates: x,
        })),
      ),
    ),
    map((res) => ({
      ...res.zone.zone,
      spotsStates: res.zone.spotsStates,
      metersNames: res.zone.metersNames,
      area: res.area,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      camerasStates: res.camerasStates.filter((x) => x !== null).map((x) => x!),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      signsStates: res.signsStates.filter((x) => x !== null).map((x) => x!),
      sensors: res.zone.sensors,
      studyAreas: res.zone.studyAreas,
      policies: res.zone.policies,
    })),
  );
};

const findZoneFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  const result = state.zonesGeo.data.features.find((x) => x.properties?.id === id);
  return result || null;
};

const fetchRevenueReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getZoneRevenueReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedZonesActions.fetchRevenueReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZonesActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchViolationReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getZoneEnforcementReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedZonesActions.fetchViolationReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZonesActions.fetchViolationReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportDataEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchOccupancyTrafficReportData.match),
    concatMap((action) =>
      from(reports.getZoneOccupancyTrafficReport(action.payload.zoneId, action.payload.filter, action.payload.occupancySource)).pipe(
        map((x) => selectedZonesActions.fetchOccupancyTrafficReportDataSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZonesActions.fetchOccupancyTrafficReportDataFailed(err.message))),
      ),
    ),
  );
};

const zoneSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedZonesActions.loadZone.match),
    concatMap((action) =>
      loadZone(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              zonesLayerActions.setEnabled(true),
              selectedZonesActions.loadZoneSuccess({ zone: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findZoneFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: geoUtils.toGeometry(x.Positions),
              properties: {},
            };

            const center = geoUtils.findCenter(feature.geometry).coordinates;
            return of(
              zonesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedZonesActions.loadZoneSuccess({ zone: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedZonesActions.loadZoneFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedZonesActions.closePopups()),
  );

const fetchCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(zonesLayerActions.fetchCount.match),
    concatMap(() =>
      from(zones.getCount()).pipe(
        map((x) => zonesLayerActions.fetchCountSuccess(x)),
        catchError((err) => of(zonesLayerActions.fetchCountFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(zonesLayerActions.fetchCount(), selectedZonesActions.collapsePopups())),
  );

const fetchPerformanceParkingSuccessEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchPerformanceParkingCountSuccess.match),
    filter((x) => x.payload === 0),
    map((_) => zonesLayerActions.setPerformanceParkingFilterValue(false)),
  );

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueByZone(action.payload)).pipe(
        map((x) => selectedZonesActions.fetchRevenueSuccess({ zoneId: action.payload, revenue: x })),
        catchError((err) => of(selectedZonesActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

const fetchKpiReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchKpiReport.match),
    concatMap((action) => {
      return from(reports.getZoneKpiReport(action.payload)).pipe(
        map((x) => selectedZonesActions.fetchKpiReportSuccess({ zoneId: action.payload, report: x })),
        catchError((err) => of(selectedZonesActions.fetchKpiReportFailed(err.message))),
      );
    }),
  );
};

const fetchViolationMetricReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchViolationMetricReport.match),
    concatMap((action) => {
      return from(reports.getZoneMetricReport(action.payload)).pipe(
        map((x) => selectedZonesActions.fetchViolationMetricReportSuccess({ zoneId: action.payload, report: x })),
        catchError((err) => of(selectedZonesActions.fetchViolationMetricReportFailed(err.message))),
      );
    }),
  );
};

const fetchTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchTrafficReport.match),
    concatMap((action) => {
      return from(reports.getZoneTrafficReport(action.payload)).pipe(
        map((x) => selectedZonesActions.fetchTrafficReportSuccess({ zoneId: action.payload, report: x })),
        catchError((err) => of(selectedZonesActions.fetchTrafficReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchOccupancyReport.match),
    concatMap((action) => {
      return from(reports.getZoneOccupancyReport(action.payload.zoneId, action.payload.occupancySource)).pipe(
        map((x) => selectedZonesActions.fetchOccupancyReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZonesActions.fetchOccupancyReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancySourceReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZonesActions.fetchOccupancySourceReport.match),
    concatMap((action) => {
      return from(reports.getZoneOccupancySourceReport(action.payload.zoneId, action.payload.occupancySource, action.payload.period)).pipe(
        map((x) => selectedZonesActions.fetchOccupancySourceReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) =>
          of(selectedZonesActions.fetchOccupancySourceReportFailed({ zoneId: action.payload.zoneId, error: err.message })),
        ),
      );
    }),
  );
};

export const zonesEpic = combineEpics(
  fetchEpic,
  zoneSelectedEpic,
  closePopupsEpic,
  fetchCount,
  citySelectedEpic,
  fetchRevenueReportEpic,
  fetchViolationReportEpic,
  fetchOccupancyTrafficReportDataEpic,
  fetchPerformanceParkingSuccessEpic,
  fetchRevenueEpic,
  fetchKpiReportEpic,
  fetchViolationMetricReportEpic,
  fetchTrafficReportEpic,
  fetchOccupancyReportEpic,
  fetchOccupancySourceReportEpic,
);
