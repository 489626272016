import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../../services';
import { ISelectedRppArea } from '../../../../../model';
import { RootState, store } from '../../../../../store';
import { selectedSliceLogic } from '../../selected-slice-logic';

export interface ISelectedRppAreaState {
  selected: ISelectedEntity<ISelectedRppArea, string>[];
  current: string | null;
}

const initialState: ISelectedRppAreaState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRppAreas',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.RppAreas,
        initialState,
        (id) => id,
        (selected) => {
          selected.map((x) => store.dispatch(selectedRppAreasActions.loadRppArea(x)));
        },
      ).selected;
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.RppAreas);
    },
    loadRppArea(state, action: PayloadAction<{ id: string; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadRppAreaSuccess(state, action: PayloadAction<{ area: ISelectedRppArea; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.area.Id,
        action.payload.area,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadRppAreaFailed(state, action: PayloadAction<{ id: string; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    setCurrent(state, action: PayloadAction<ISelectedRppArea | null>) {
      state.current = action.payload?.Id || null;
    },

    change(state, action: PayloadAction<{ id: string; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.RppAreas, action.payload.position);
    },
    closePopup(state, action: PayloadAction<string | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.RppAreas, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.RppAreas);
    },
    openPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.RppAreas, action.payload);
    },
  },
});

// Actions
export const selectedRppAreasActions = slice.actions;

// Selectors
export const selectedRppAreas = (state: RootState) => state.selectedRppAreas;
export const currentRppArea = (state: RootState) => state.selectedRppAreas.selected.find((x) => x.id === state.selectedRppAreas.current);

// Reducer
export const selectedRppAreasReducer = slice.reducer;
