import { Action } from '@reduxjs/toolkit';
import { Observable } from 'rxjs';
import { map, filter, concatMap } from 'rxjs/operators';

import { getAppSettings } from '../../../services/settings-service';

import { appSettingsActions } from './app-settings-slice';

export const appSettingsEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(appSettingsActions.fetchSettings.match),
        concatMap(() => getAppSettings()),
        map(settings => appSettingsActions.fetchSettingsSuccess(settings))
    );