import { Action, AnyAction } from '@reduxjs/toolkit';
import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { combineEpics } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { zoneObjectsGeoActions, zoneObjectsLayerActions } from '../..';
import { EMPTY_FEATURE_COLLECTION } from '../../../../../constants';
import { TagFilter } from '../../../../../model';
import { zoneObjects } from '../../../../../services';
import { citiesActions } from '../../../../common';

const fetchZoneObjectsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(zoneObjectsGeoActions.fetch.match),
    concatMap((action) =>
      loadData(action.payload.cityCode).pipe(
        map((x) => processData(x, action.payload.curbfacesFilter)),
        map((curbfaces) => zoneObjectsGeoActions.fetchSuccess({ curbfaces })),
        catchError((err) => of(zoneObjectsGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    filter((action) => action.payload?.Code !== undefined),
    mergeMap((action) => of(zoneObjectsLayerActions.fetchData({ cityCode: action.payload!.Code }))),
  );

const fetchTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(zoneObjectsLayerActions.fetchData.match),
    concatMap((action) =>
      loadData(action.payload.cityCode).pipe(
        map((x) => extractLayerData(x)),
        map((x) =>
          zoneObjectsLayerActions.fetchDataSuccess({
            cityCode: action.payload.cityCode,
            types: x.types,
            curbfacesCount: x.curbfacesCount,
          }),
        ),
        catchError((err) => of(zoneObjectsLayerActions.fetchDataFailed(err.message))),
      ),
    ),
  );

export const zoneObjectsEpic = combineEpics<AnyAction>(fetchZoneObjectsEpic, citySelectedEpic, fetchTypesEpic);

function loadData(cityCode: string): Observable<FeatureCollection> {
  return from(zoneObjects.getCurbfaces(cityCode));
}

function processData(x: FeatureCollection, filter: TagFilter | null): FeatureCollection {
  const curbfaces = filter
    ? applyPropsFilter(
        filter,
        (p, f) => {
          if (p?.CURBCOLOR && f.tags[p.CURBCOLOR]) {
            return true;
          }

          return false;
        },
        x,
      )
    : x;

  return curbfaces;
}

function applyPropsFilter(
  filter: TagFilter,
  featureChecker: (props: GeoJsonProperties, filter: TagFilter) => boolean,
  data: FeatureCollection,
): FeatureCollection {
  if (!filter.enabled || filter.allTagsEnabled()) {
    return data;
  }

  if (!featureChecker) {
    return EMPTY_FEATURE_COLLECTION;
  }

  const result: FeatureCollection = {
    type: data.type,
    features: data.features.filter((f) => featureChecker(f.properties, filter)),
  };

  return result;
}

function extractLayerData(x: FeatureCollection): {
  types: Array<{ id: string; count: number }>;
  curbfacesCount: number;
} {
  const typesMap: { [key: string]: number } = {};

  x.features.forEach((f) => {
    const color = f.properties?.CURBCOLOR;
    const currentCount = typesMap[color] || 0;
    typesMap[color] = currentCount + 1;
  });

  return {
    types: Object.getOwnPropertyNames(typesMap)
      .filter((x) => x !== 'null')
      .map((x) => ({ id: x, count: typesMap[x] })),
    curbfacesCount: x.features?.length || 0,
  };
}
