import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import {
  IOccupancySourceReport,
  IOccupancyTrafficReport,
  IParkingDurationReportItem,
  ISelectedStudyAreaHeatmap,
  IViolationInfo,
  IZoneRevenueReportItem,
} from '../../../../model';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';
import { OccupancySource } from '../../../../model/api/occupancy';

export interface ISelectedStudyAreaHeatmapState {
  selected: ISelectedEntity<ISelectedStudyAreaHeatmap>[];
  current: number | null;
}

const initialState: ISelectedStudyAreaHeatmapState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRevenueStudyAreas',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.StudyAreasHeatmap,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedStudyAreaHeatmapsActions.loadHeatmap(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.StudyAreasHeatmap, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.StudyAreasHeatmap);
    },
    loadHeatmap(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadHeatmapSuccess(state, action: PayloadAction<{ heatmap: ISelectedStudyAreaHeatmap; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.heatmap.studyArea.Id,
        action.payload.heatmap,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadHeatmapFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    fetchViolationReport(state, action: PayloadAction<{ studyAreaId: number; filter: IReportFilter }>) {
      const studyArea = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IViolationInfo | null }>) {
      const studyArea = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = false;
      if (!studyArea.entity) {
        return;
      }
      studyArea.entity.enforcementReport = action.payload.report;
    },
    fetchViolationReportFailed(state, action: PayloadAction<{ studyAreaId: number; error: string }>) {
      const studyArea = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = false;
      console.error(action.payload.error);
    },

    fetchDurationReport(state, action: PayloadAction<{ id: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchDurationReportSuccess(state, action: PayloadAction<{ id: number; report: IParkingDurationReportItem | null }>) {
      const item = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;

      if (!item.entity) {
        return;
      }
      item.entity.durationReport = action.payload.report;
    },
    fetchReportFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenueReport(state, action: PayloadAction<{ id: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ id: number; report: IZoneRevenueReportItem | null }>) {
      const item = state.selected.find((x) => x.entity?.studyArea.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;

      if (!item.entity) {
        return;
      }
      item.entity.revenueReport = action.payload.report;
    },

    fetchOccupancyTrafficReport(state, action: PayloadAction<{ id: number; filter: IReportFilter; occupancySource: OccupancySource }>) {
      const item = state.selected.find((x) => x.entity?.studyArea?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancyTrafficReportSuccess(state, action: PayloadAction<{ id: number; report: IOccupancyTrafficReport | null }>) {
      const item = state.selected.find((x) => x.entity?.studyArea?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      if (!item.entity) {
        return;
      }
      item.entity.occupancyTrafficReport = action.payload.report;
    },

    fetchOccupancyReport(state, action: PayloadAction<{ id: number; period: [Date, Date]; occupancySource: OccupancySource }>) {
      const item = state.selected.find((x) => x.entity?.studyArea?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancyReportSuccess(state, action: PayloadAction<{ id: number; report: IOccupancySourceReport | null }>) {
      const item = state.selected.find((x) => x.entity?.studyArea?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      if (!item.entity) {
        return;
      }
      item.entity.occupancySourceReport = action.payload.report;
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.StudyAreasHeatmap, action.payload);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.StudyAreasHeatmap, action.payload);
    },
  },
});

// Actions
export const selectedStudyAreaHeatmapsActions = slice.actions;

// Selectors
export const selectedStudyAreaHeatmaps = (state: RootState) => state.selectedStudyAreaHeatmaps;

export const currentStudyAreaHeatmap = (state: RootState) =>
  state.selectedStudyAreaHeatmaps.selected.find((x) => x.id === state.selectedStudyAreaHeatmaps.current);

// Reducer
export const selectedStudyAreaHeatmapsReducer = slice.reducer;
