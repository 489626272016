interface ITags {
  [key: string | number]: boolean;
}

export interface ISavedTagFilter {
  enabled: boolean;
  tags: Array<string | number>;
}

export class TagFilter {
  public static fromSavedValue(value: ISavedTagFilter) {
    const tags: { [key: string | number]: boolean } = {};
    for (const tag of value.tags) {
      tags[tag] = true;
    }
    return new TagFilter(value.enabled, tags);
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public get tags(): ITags {
    return this._tags;
  }

  constructor(private _enabled = false, private _tags: ITags = {}) {}

  clone(): TagFilter {
    return new TagFilter(this.enabled, { ...this._tags });
  }

  enable(value: boolean): TagFilter {
    this._enabled = value;
    return this.clone();
  }

  toggleTag(tag: string | number): TagFilter {
    this._tags[tag] = !this._tags[tag];
    return this.clone();
  }

  enableTag(tag: string | number, value: boolean): TagFilter {
    this._tags[tag] = value;
    return this.clone();
  }

  isTagEnabled(tag: string | number, ignoreFilterEnabled = false): boolean {
    if (!ignoreFilterEnabled && !this._enabled) {
      return false;
    }

    if (!Object.getOwnPropertyNames(this._tags).find((x) => !!this._tags[x])) {
      return true;
    }

    return !!this._tags[tag];
  }

  allTagsEnabled(): boolean {
    const tags = Object.getOwnPropertyNames(this._tags);
    if (!tags.find((x) => !!this._tags[x])) {
      return true;
    }

    if (tags.find((x) => !this._tags[x])) {
      return false;
    }

    return false;
  }

  anyTagEnabled(): boolean {
    if (!this._enabled) {
      return false;
    }

    const tags = Object.getOwnPropertyNames(this._tags);
    if (!tags.find((x) => !!this._tags[x])) {
      return true;
    }

    if (tags.find((x) => !!this._tags[x])) {
      return true;
    }

    return false;
  }

  toSavedValue(): ISavedTagFilter {
    const tags = Object.getOwnPropertyNames(this._tags);
    const enabledTags = tags.filter((x) => !!this._tags[x]);
    return { enabled: this._enabled, tags: enabledTags };
  }
}
