import { ICameraWithSpots, ISpotState, OccupancyStatus } from '../api';

export class SpotOccupancyCalculator {
  public static getColor(occupancy: OccupancyStatus): string {
    switch (occupancy) {
      case OccupancyStatus.Vacant:
        return '#00B62D';

      case OccupancyStatus.Booked:
        return '#cccc00';

      case OccupancyStatus.Occupied:
        return 'red';

      default:
        return '#A4A6AA';
    }
  }

  public static getCountSpotStatuses(spotStates: OccupancyStatus[], spotsCount?: number) {
    let countVacant = 0;
    let countBooked = 0;
    let countOccupied = 0;
    let countNotMonitored = 0;
    for (const state of spotStates) {
      if (state === OccupancyStatus.Vacant) countVacant++;
      if (state === OccupancyStatus.Booked) countBooked++;
      if (state === OccupancyStatus.Occupied) countOccupied++;
      if (state === OccupancyStatus.Unknown) countNotMonitored++;
    }
    //add PayStation spots to 'NotMonitored'
    if (spotsCount) {
      const payStationSpots = spotsCount - spotStates.length;
      if (payStationSpots > 0) {
        countNotMonitored += payStationSpots;
      }
    }

    return {
      vacant: countVacant,
      booked: countBooked,
      occupied: countOccupied,
      notMonitored: countNotMonitored,
    };
  }

  public static getSpotStatesWithCamera(
    camerasIds: Array<ICameraWithSpots>,
    spotsStates: Array<ISpotState>,
  ): Array<ISpotState> {
    const spotsIds: number[] = camerasIds.map((x) => {
      return x.SpotIds.reduce((y: number) => {
        return y;
      });
    });
    return spotsStates.filter((x: ISpotState) => {
      return spotsIds.includes(x.SpotId);
    });
  }
}

export class WeekDaysSchedule {
  public static getAmericanHour(stringHour: string): string {
    const hour = Number(stringHour.split(':')[0]);
    const minutes = Number(stringHour.split(':')[1]);
    const preparedMinutes: string = minutes === 0 ? '' : ':' + String(minutes);

    if (hour === 0) {
      return '12AM';
    }

    if (stringHour === '23:59' || hour === 24) {
      return '12PM';
    }

    if (hour === 12) {
      return hour + preparedMinutes + 'AM';
    }

    if (hour > 12) {
      return hour - 12 + preparedMinutes + 'PM';
    }
    return hour + preparedMinutes + ' AM';
  }

  public static diffHourDescription(from: string, to: string): string {
    if (from) {
      const hourFrom = Number(from.split(':')[0]);
      const hourTo = to ? Number(to.split(':')[0]) : 0;
      const minutesFrom = Number(from.split(':')[1]);
      const minutesTo = to ? Number(to.split(':')[1]) : 0;

      const diffHour: number = hourTo !== 0 ? hourTo - hourFrom : hourFrom;
      const diffMinutes: number = minutesTo - minutesFrom;
      const prefix: string = hourFrom === 0 ? 'first' : '';
      const suffix: string = hourTo === 0 ? '+' : '';

      const descriptionHour = diffHour > 1 ? ' hours' : ' hour';
      const descriptionMinutes: string = diffMinutes !== 0 ? ' and ' + diffMinutes + 'minutes' : '';

      return prefix + String(diffHour) + descriptionHour + descriptionMinutes + suffix;
    }
    return '';
  }
}