import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { traffic } from '../../../../services';
import { citiesActions } from '../../../common/city/cities-slice';
import { heatmapsFilterActions } from '../heatmaps';

const selectCityEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    switchMap((action) =>
      from(traffic.getSegmentsCount()).pipe(
        map((x) => heatmapsFilterActions.setTrafficSegmentsCountSuccess(x)),
        catchError((err) => of(heatmapsFilterActions.setTrafficSegmentsCountFailed(err.message))),
      ),
    ),
  );

export const trafficLayerEpic = combineEpics(selectCityEpic);
