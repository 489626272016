import { FunctionComponent } from 'react';
import { RouteObject, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb, BreadcrumbLinkKeyDownEvent, BreadcrumbLinkMouseEvent, DataModel } from '@progress/kendo-react-layout';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbRouteObject = RouteObject & {
  breadcrumb?: string;
};

interface IProps {
  routes: BreadcrumbRouteObject[];
  omitRoot?: boolean;
}

export const Breadcrumbs: FunctionComponent<IProps> = ({ routes, omitRoot = true }) => {
  const navigate = useNavigate();
  let breadcrumbs = useBreadcrumbs(routes);
  if (omitRoot) {
    breadcrumbs = breadcrumbs.slice(1);
  }

  const getBreadcrumbText = (pathname: string) => routes.find((x) => x.path && pathname.endsWith(x.path))?.breadcrumb;

  const data: DataModel[] = [];
  for (const { match } of breadcrumbs) {
    const text = getBreadcrumbText(match.pathname) ?? '';
    if (text) {
      data.push({
        id: match.pathname,
        text: text,
      });
    }
  }

  const handleItemSelect = (event: BreadcrumbLinkMouseEvent) => {
    event.id && navigate(event.id);
  };
  const handleKeyDown = (event: BreadcrumbLinkKeyDownEvent) => {
    event.id && navigate(event.id);
  };

  return <Breadcrumb data={data} onItemSelect={handleItemSelect} onKeyDown={handleKeyDown} className={styles.breadcrumbs} />;
};
