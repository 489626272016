import { Action } from '@reduxjs/toolkit';
import { Observable, of, zip, from } from 'rxjs';
import { map, filter, concatMap, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { combineEpics, StateObservable } from 'redux-observable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

import { camerasGeoActions } from './cameras-geo-slice';
import { selectedCamerasActions } from './selected-cameras-slice';
import { camerasLayerActions } from './cameras-layer-slice';
import { geoProcessor, cameras, parkingEvents, spots, studyAreas } from '../../../../services';
import { ISelectedCamera } from '../../../../model';
import { RootState } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { mapStateActions } from '../../map-state';
import { citiesActions } from '../../../common';

const fetchCamerasEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(camerasGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadCameras(action.payload.box, action.payload.box.zoom, action.payload.statuses).pipe(
        map((x) => camerasGeoActions.fetchSuccess(x)),
        catchError((err) => of(camerasGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const loadCamera = (id: string, state: RootState): Observable<ISelectedCamera> => {
  const existing = state.selectedCameras.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(from(cameras.get(id)), from(cameras.getState(id))).pipe(
    mergeMap(([camera, state]) =>
      zip(
        of({ camera, state }),
        camera.SpotIds.length > 0 ? from(spots.getSpotsStates(camera.SpotIds)) : of([]),
        camera.SpotIds.length > 0 ? from(studyAreas.getSpotStudyAreaNames(camera.SpotIds)) : of([]),
      ),
    ),
    map(([res, spotsStates, studyAreas]) => ({ ...res.camera, state: res.state, spotsStates, studyAreas })),
  );
};

const findCameraFeature = (id: string, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  return state.camerasGeo.data.features.find((x) => x.properties?.id === id) || null;
};

const cameraSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedCamerasActions.loadCamera.match),
    concatMap((action) =>
      loadCamera(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              camerasLayerActions.setEnabled(true),
              selectedCamerasActions.loadCameraSuccess({ camera: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findCameraFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: x.Position,
              },
              properties: {},
            };

            const center = geoUtils.findCenter(feature.geometry).coordinates;
            return of(
              camerasLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedCamerasActions.loadCameraSuccess({ camera: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedCamerasActions.loadCameraFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedCamerasActions.closePopups()),
  );

const fetchCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(camerasLayerActions.fetchStatusCount.match),
    concatMap(() =>
      from(cameras.getStatusCount()).pipe(
        map((x) => camerasLayerActions.fetchStatusCountSuccess(x)),
        catchError((err) => of(camerasLayerActions.fetchStatusCountFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(camerasLayerActions.fetchStatusCount(), selectedCamerasActions.collapsePopups())),
  );

const fetchEventsEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCamerasActions.fetchEvents.match),
    concatMap((action) => {
      return from(parkingEvents.getByCamera(action.payload)).pipe(
        map((x) => selectedCamerasActions.fetchEventsSuccess({ cameraId: action.payload, events: x })),
        catchError((err) => of(selectedCamerasActions.fetchEventsFailed(err.message))),
      );
    }),
  );
};

export const camerasEpic = combineEpics(
  fetchCamerasEpic,
  cameraSelectedEpic,
  closePopupsEpic,
  fetchCount,
  citySelectedEpic,
  fetchEventsEpic,
);
