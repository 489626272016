import { FunctionComponent } from 'react';
import { SignStatus } from '../../../../model';

import styles from './SignStatusIcon.module.scss';

interface Props {
  className?: string;
  status: SignStatus;
}

export const SignStatusIcon: FunctionComponent<Props> = ({ className, status }) => {
  return <span className={`${className || ''} ${styles.icon} status-${SignStatus[status]}`}></span>;
};
