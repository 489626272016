import { FC } from 'react';
import {
  DropDownList as DropDownListKendo,
  DropDownListProps,
} from '@progress/kendo-react-dropdowns';
import classNames from 'classnames/bind';

import styles from './DropDownList.module.scss';

const cx = classNames.bind(styles);

export const DropDownList: FC<DropDownListProps> = (props) => (
  <DropDownListKendo {...props} rounded={null} className={cx('dropdownlist', props.className)} />
);
