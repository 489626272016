import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../constants';

const get = (cityCode: string, fileName: string): Promise<FeatureCollection> => {
  return fetch(`/zone-objects//${cityCode.toLowerCase()}/${fileName}`)
    .then((res) => res.json())
    .catch((_) => EMPTY_FEATURE_COLLECTION);
};

const getCurbfaces = (cityCode: string) => get(cityCode, 'curbfaces.geojson');

export const zoneObjects = {
  getCurbfaces,
};
