import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WeekDay } from '../../../../model';
import { RootState } from '../../../../store';
import { IRevenueAverageHeatmapData, IRevenueHeatmapData } from '../../../../services';

export interface IRevenueState {
  studyAreaRevenueData: IRevenueAverageHeatmapData[];
  studyAreaRevenueDataLoading: boolean;

  spotRevenueHeatmapData: IRevenueHeatmapData[];
  spotRevenueHeatmapDataLoading: boolean;
}

const initialState: IRevenueState = {
  studyAreaRevenueData: [],
  studyAreaRevenueDataLoading: false,

  spotRevenueHeatmapData: [],
  spotRevenueHeatmapDataLoading: false,
};

const slice = createSlice({
  name: 'revenueData',
  initialState: initialState,
  reducers: {
    fetchStudyAreaRevenueData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.studyAreaRevenueDataLoading = true;
    },
    fetchStudyAreaRevenueDataSuccess(state, action: PayloadAction<IRevenueAverageHeatmapData[]>) {
      state.studyAreaRevenueData = action.payload;
      state.studyAreaRevenueDataLoading = false;
    },
    fetchStudyAreaRevenueDataFailed(state, action: PayloadAction<string>) {
      state.studyAreaRevenueDataLoading = false;
      console.error(action);
    },

    fetchSpotRevenueHeatmapData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.spotRevenueHeatmapDataLoading = true;
    },
    fetchSpotRevenueHeatmapDataSuccess(state, action: PayloadAction<IRevenueHeatmapData[]>) {
      state.spotRevenueHeatmapData = action.payload;
      state.spotRevenueHeatmapDataLoading = false;
    },
    fetchSpotRevenueHeatmapDataFailed(state, action: PayloadAction<string>) {
      state.spotRevenueHeatmapDataLoading = false;
      console.error(action);
    },
  },
});

// Actions
export const revenueDataActions = slice.actions;

// Selectors
export const selectRevenueData = (state: RootState) => state.revenueData;

// Reducer
export const revenueDataReducer = slice.reducer;
