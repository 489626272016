import { Action } from '@reduxjs/toolkit';

import { combineEpics } from 'redux-observable';
import { Observable, catchError, filter, from, map, of, switchMap } from 'rxjs';
import { occupancyData } from '../../../../services';
import { citiesActions } from '../../../common';
import { heatmapsFilterActions } from './heatmaps-slice';

const fetchSourcesEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(heatmapsFilterActions.fetchSources.match),
    switchMap((action) =>
      from(occupancyData.getSources()).pipe(
        map((x) => heatmapsFilterActions.fetchSourcesSuccess(x)),
        catchError((err) => of(heatmapsFilterActions.fetchSourcesFailed(err.message))),
      ),
    ),
  );
};

const citySelectedEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(citiesActions.selectCity.match),
    switchMap(() => of(heatmapsFilterActions.fetchSources())),
  );
};

export const heatmapEpic = combineEpics(citySelectedEpic, fetchSourcesEpic);
