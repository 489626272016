import { FunctionComponent, useCallback, useMemo } from 'react';
import styles from './CitySelector.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { citiesActions, selectCities } from '../../../../features';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocation } from 'react-router-dom';
import { ReportsBuilder } from '../../../../utils';

const CitySelector: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const cities = useAppSelector(selectCities);

  const filteredCities = useMemo(
    () =>
      location.pathname.toLowerCase().indexOf('/reports') === 0 || location.pathname.toLowerCase().indexOf('/forecast') === 0
        ? cities.cities.filter((x) => new ReportsBuilder(x?.Settings || []).hasReports())
        : cities.cities,
    [cities.cities, location.pathname],
  );

  const onCitySelected = useCallback(
    (evt: DropDownListChangeEvent) => {
      dispatch(citiesActions.selectCity(evt.target.value));
    },
    [dispatch],
  );

  if (!filteredCities.length) {
    return <></>;
  }

  return (
    <>
      {filteredCities.length > 1 ? (
        <DropDownList
          className={styles.cityDropdown}
          data={filteredCities}
          dataItemKey='Id'
          textField='Name'
          onChange={onCitySelected}
          value={cities.selectedCity}
        />
      ) : (
        <span className={`${styles.cityName} k-button k-button-md k-button-link k-button-link-light k-rounded-sm k-button-text`}>
          {cities.selectedCity?.Name}
        </span>
      )}
    </>
  );
};

export default CitySelector;
