import { useCallback } from 'react';
import { mapStateActions } from '../features';
import { useAppDispatch } from '../store/hooks';

export const useOpenPopupDispatch = () => {
  const dispatch = useAppDispatch();

  const func = useCallback(
    (action: { type: string; payload: unknown }) => {
      dispatch(mapStateActions.closePopups());
      setTimeout(() => {
        dispatch(action);
      }, 10);
    },
    [dispatch],
  );

  return func;
};
