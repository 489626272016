import { FC } from 'react';

import styles from './PopupBodyPlaceholder.module.scss';

interface Props {
  className?: string;
  rows?: number;
}

export const PopupBodyPlaceholder: FC<Props> = ({ className, rows }) => {
  rows = rows === undefined ? 1000 : rows;

  return (
    <div className={`${styles.body} ${className || ''}`}>
      <div className={`ph-item`}>
        <div className='ph-row'>
          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-3'></div>
              <div className='ph-col-8 empty'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-3'></div>
              <div className='ph-col-8 empty'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}

          {rows-- > 0 ? (
            <>
              <div className='ph-col-4'></div>
              <div className='ph-col-7'></div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
