import { CameraStatus } from './camera';
import { SignStatus } from './sign';
import { OccupancyStatus } from './spot';

export interface ISpotSearchResultItem {
  Id: number;
  Name: string;
  PolicyTypeId: number;
  Status: OccupancyStatus;
  ParkingSensorId: number | null;
}
export interface IMeterSearchResultItem {
  Id: number;
  Name: string;
  TypeId: number;
  VendorId: number;
  Status: number;
  PerformanceParking: boolean | null;
}
export interface ICameraSearchResultItem {
  Id: number;
  Name: string;
  Status: CameraStatus;
}
export interface ISignSearchResultItem {
  Id: number;
  Name: string;
  Status: SignStatus;
}
export interface IZoneSearchResultItem {
  Id: number;
  Name: string;
  PerformanceParking: boolean | null;
}
export interface IBlockfaceSearchResultItem {
  Id: number;
  Name: string;
  PerformanceParking: boolean | null;
}
export interface ILookupValue<T = number> {
  Id: T;
  Name: string;
}
export interface IOffstreetZoneSearchResultItem {
  Id: number;
  Name: string;
  Address: string;
  VendorId: number;
}
export interface ISensorSerchResultItem {
  Id: number;
  Name: string;
  SpotId: number;
}

export interface ISearchResult {
  Spots: ISpotSearchResultItem[];
  Meters: IMeterSearchResultItem[];
  Cameras: ICameraSearchResultItem[];
  Signs: ISignSearchResultItem[];
  Zones: IZoneSearchResultItem[];
  OffstreetZones: IOffstreetZoneSearchResultItem[];
  Areas: ILookupValue[];
  Blockfaces: IBlockfaceSearchResultItem[];
  Sensors: ISensorSerchResultItem[];
  StudyAreas: ILookupValue[];
  RppZones: ILookupValue<string>[];
  RppAreas: ILookupValue<string>[];
}

export const DEFAULT_SEARCH_RESULT: ISearchResult = {
  Spots: [],
  Meters: [],
  Cameras: [],
  Signs: [],
  Zones: [],
  OffstreetZones: [],
  Areas: [],
  Blockfaces: [],
  Sensors: [],
  StudyAreas: [],
  RppZones: [],
  RppAreas: [],
};
