import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../store';
import { SavedBlockfaceLayer } from '../../../../model';

export interface IBlockfacesLayerState {
  enabled: boolean;
  count: number;
  displayBlockfaceId: boolean;
  showPerformanceParkingOnly: boolean;
}

const initialState: IBlockfacesLayerState = {
  enabled: false,
  count: 0,
  displayBlockfaceId: true,
  showPerformanceParkingOnly: true,
};

const slice = createSlice({
  name: 'blockfacesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },

    setDisplayBlockfaceId(state, action: PayloadAction<boolean>) {
      state.displayBlockfaceId = action.payload;
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.count = 0;
      console.log(action);
    },

    setPerformanceParkingFilterValue(state, action: PayloadAction<boolean>) {
      state.showPerformanceParkingOnly = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedBlockfaceLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.displayBlockfaceId = action.payload.displayBlockfaceId;
        state.showPerformanceParkingOnly = action.payload.showPerformanceParkingOnly ?? initialState.showPerformanceParkingOnly;
      }
    },
  },
});

// Actions
export const blockfacesLayerActions = slice.actions;

// Selectors
export const selectBlockfacesLayer = (state: RootState) => state.blockfacesLayer;

// Reducer
export const blockfacesLayerReducer = slice.reducer;
