import { BrowserAuthError, ClientAuthError } from '@azure/msal-browser';
import { notifications } from '../notifications-service';
import { ErrorSource, amplitudeService } from '../amplitude-service';

const messageExtractors: Array<{ test: (err: unknown) => boolean; message: (err: unknown) => string }> = [
  {
    test: (err) => err instanceof ClientAuthError && err.message?.startsWith('endpoints_resolution_error'),
    message: (err) => 'Please check your network connection and try again.',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiErrorHandler(err: any) {
  if (!(err instanceof BrowserAuthError) && err.status !== 401 && err.code !== 'ERR_CANCELED' && err.code !== 'ECONNABORTED') {
    const message = messageExtractors.find((e) => e.test(err))?.message(err) || err.message;
    notifications.error(message);
    amplitudeService.trackError(ErrorSource.Api, err);
  }
}
