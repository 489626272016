import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import classNames from 'classnames/bind';

import styles from './DemoMenu.module.scss';

export interface NavigationUrl {
  text: string;
  url: string;
}

interface Props {
  title: string;
  icon: string;
  urls: NavigationUrl[];
  className?: string;
}

const cx = classNames.bind(styles);

export const DemoMenu: FC<Props> = ({ title, icon, urls, className }) => {
  const navigate = useNavigate();

  const onMenuItemClick = (text: string) => {
    const url = urls.find((x) => x.text === text)?.url;
    if (url) {
      if (url.startsWith('/')) {
        navigate(url);
      } else {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <div className={cx('menuDemoContainer', className)}>
      <img src={icon} alt='logo' />
      <DropDownButton
        text={title}
        icon='arrow-chevron-down'
        themeColor='light'
        fillMode='link'
        rounded='small'
        popupSettings={{ popupClass: styles.dropdownPopup }}
        buttonClass={cx('dropdownIcon', 'menuButton')}
        onItemClick={(evt) => onMenuItemClick(evt.item.text)}
      >
        {urls.map((x, idx) => (
          <DropDownButtonItem key={idx} text={x.text} />
        ))}
      </DropDownButton>
    </div>
  );
};
