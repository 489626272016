import React, { FunctionComponent } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import styles from './Sidebar.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
};

export const Sidebar: FunctionComponent<Props> = ({
  title,
  children,
  isOpen,
  onClose,
  className,
}) => {
  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (onClose) onClose();
  };

  return (
    <div className={`${styles.sidebar} ${isOpen ? '' : styles.collapsed} ${className || ''}`}>
      <div className={styles.sidebarHeader}>
        <h4>{title}</h4>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>
      {children}
    </div>
  );
};
