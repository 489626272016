import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox } from '../../../../model';
import { RootState } from '../../../../store';

export interface ISignsLevelPolygonsState {
  loading: boolean;
  data: FeatureCollection;
  count: number;
}

const initialState: ISignsLevelPolygonsState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'signsGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.loading = false;
      state.data = action.payload;
      state.count = action.payload.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const signsGeoActions = slice.actions;

// Selectors
export const selectSignsGeoLoading = (state: RootState) => state.signsGeo.loading;
export const selectSignsGeo = (state: RootState) => state.signsGeo.data;
export const selectSignsGeoCount = (state: RootState) => state.signsGeo.count;

// Reducer
export const signsGeoReducer = slice.reducer;
