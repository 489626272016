import { FunctionComponent } from 'react';

import styles from './MeterVendorIcon.module.scss';

interface Props {
  className?: string;
  vendorId: number | undefined;
}

export const MeterVendorIcon: FunctionComponent<Props> = ({ className, vendorId }) => {
  return <span className={`${className || ''} ${styles.icon} vendor-${vendorId}`}></span>;
};
