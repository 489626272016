import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TagFilter, TilesBoundingBox } from '../../../../model';

import { RootState } from '../../../../store';

export interface IOffstreetZonesLevelGeoState {
  loading: boolean;
  data: FeatureCollection;
  center: FeatureCollection;
  count: number;
}

const initialState: IOffstreetZonesLevelGeoState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
  center: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'offstreetZonesGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ box: TilesBoundingBox; vendors: TagFilter; types: TagFilter; showCityOwned: boolean }>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<{ data: FeatureCollection; center: FeatureCollection }>) {
      state.loading = false;
      state.data = action.payload.data;
      state.center = action.payload.center;
      state.count = action.payload.data.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      state.center = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const offstreetZonesGeoActions = slice.actions;

// Selectors
export const selectOffstreetZonesGeoLoading = (state: RootState) => state.offstreetZonesGeo.loading;
export const selectOffstreetZonesGeo = (state: RootState) => state.offstreetZonesGeo.data;
export const selectOffstreetZonesGeoCenter = (state: RootState) => state.offstreetZonesGeo.center;
export const selectOffstreetZonesGeoCount = (state: RootState) => state.offstreetZonesGeo.count;

// Reducer
export const offstreetZonesGeoReducer = slice.reducer;
