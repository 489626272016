import { useMemo } from 'react';
import { selectAppSettings } from '../features';
import { useAppSelector } from '../store/hooks';

export enum Environment {
  Unknown,
  Dev = 'DEV',
  DevOps = 'DEVOPS',
  QA = 'QA',
  Demo = 'DEMO',
  Prod = 'PROD',
}

export const useGetEnvironment = () => {
  const settings = useAppSelector(selectAppSettings);

  return useMemo(() => {
    if (settings?.environment) {
      const env = settings.environment.toUpperCase();
      if (Object.values(Environment).some((x) => x === env)) {
        return env as Environment;
      }
    }
    return Environment.Unknown;
  }, [settings?.environment]);
};
