import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';
import { SavedRppAreaLayer } from '../../../../../model';

export interface IRppAreasLayerState {
  enabled: boolean;
  display: boolean;
  count: number;
}

const initialState: IRppAreasLayerState = {
  enabled: false,
  display: false,
  count: 0,
};

const slice = createSlice({
  name: 'rppAreasLayer',
  initialState: initialState,
  reducers: {
    fetchCount(state, action: PayloadAction) {
      state.enabled = false;
    },
    fetchCountSuccess(state, action: PayloadAction<{ count: number }>) {
      state.enabled = action.payload.count > 0;
      state.count = action.payload.count;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      console.log(action);
      state.count = 0;
      state.enabled = false;
    },

    setDisplayRppAreas(state, action: PayloadAction<boolean>) {
      state.display = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedRppAreaLayer | undefined>) {
      if (action.payload) {
        state.display = action.payload.display;
      }
    },
  },
});

// Actions
export const rppAreasLayerActions = slice.actions;

// Selectors
export const selectRppAreasLayer = (state: RootState) => state.rppAreasLayer;

// Reducer
export const rppAreasLayerReducer = slice.reducer;
