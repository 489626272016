import {
  IBlockface,
  ICamera,
  ICameraState,
  IKpiReportItem,
  IMeterName,
  IOccupancyReportItem,
  IOccupancySourceReport,
  IParkingEvent,
  IRevenueItem,
  ISensorTelemetry,
  ISignState,
  ISpot,
  ISpotRegulation,
  ISpotState,
  ISpotStatistics,
  IStudyAreaName,
  IZone,
} from '../../api';
import { ITransaction } from '../../api/meter-transactions';

export interface ISelectedCurbSpace extends ISpot {
  signState: ISignState | null;
  camera: ICamera | null;
  zone: IZone | null;
  regulation?: ISpotRegulation | null;
  metersNames: IMeterName[];
  state: ISpotState | null;
  cameraState: ICameraState | null;
  events?: IParkingEvent[];
  cameraEvents: IParkingEvent[];
  sensorTelemetry: ISensorTelemetry | null;
  statistics: ISpotStatistics | null;
  blockface: IBlockface | null;
  occupancyReport?: Array<IOccupancyReportItem>;
  studyAreas: IStudyAreaName[];
  transactions?: ITransaction[];
  revenue?: IRevenueItem[];
  kpiReport?: IKpiReportItem[];
  occupancySourceReport?: IOccupancySourceReport | null;
}

export enum SensorFilter {
  All = 'All',
  WithSensors = 'WithSensors',
  WithoutSensors = 'WithoutSensors',
}
