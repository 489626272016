import { AxiosError } from 'axios';
import { ISensorSpot, ISensorTelemetry, ISpotStatistics } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/sensor';
const getSensorsAllowedErrors = [404, 500]; // It's OK to skip HTTP500 since we're invoking external API

const getZoneSensorSpots = (zoneId: number): Promise<ISensorSpot[]> => {
  return getApi()
    .get<ISensorSpot[]>(`${BASE_URL}/by-zone/?id=${zoneId}`)
    .then((response) => response.data || []);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

const getTelemetry = (id: number) => {
  return getApi({ ignoreErrorStatuses: getSensorsAllowedErrors })
    .get<ISensorTelemetry>(`${BASE_URL}/${id}/telemetry`)
    .then((response) => response.data)
    .then((x) => ({
      ...x,
      UpdatedAt: new Date(x.UpdatedAt),
    }))
    .catch((err: AxiosError) => {
      if (getSensorsAllowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });
};

const getSpotStatistics = (spotDataSourceEntityId: string, from: Date, to: Date) => {
  const fromDate = dateUtils.toDateString(from);
  const toDate = dateUtils.toDateString(to);
  return getApi({ ignoreErrorStatuses: getSensorsAllowedErrors })
    .get<ISpotStatistics>(`${BASE_URL}/statistics/by-spot?spotDataSourceEntityId=${spotDataSourceEntityId}&from=${fromDate}&to=${toDate}`)
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      if (getSensorsAllowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });
};

const getStudyAreaSensorSpots = (studyAreaId: number): Promise<ISensorSpot[]> => {
  return getApi()
    .get<ISensorSpot[]>(`${BASE_URL}/by-study-area/?id=${studyAreaId}`)
    .then((response) => response.data || []);
};

const getBlockfaceSensorSpots = (blockfaceId: number): Promise<ISensorSpot[]> => {
  return getApi()
    .get<ISensorSpot[]>(`${BASE_URL}/by-blockface/?id=${blockfaceId}`)
    .then((response) => response.data || []);
};

export const sensors = {
  getZoneSensorSpots,
  getCount,
  getTelemetry,
  getSpotStatistics,
  getStudyAreaSensorSpots,
  getBlockfaceSensorSpots,
};
