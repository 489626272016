import { useMemo } from 'react';
import { selectCity } from '../features';
import { useAppSelector } from '../store/hooks';
import { ReportsBuilder } from '../utils';

export const useReportBuilder = () => {
  const city = useAppSelector(selectCity);

  const builder = useMemo(() => new ReportsBuilder(city?.Settings || []), [city?.Settings]);
  return builder;
};
