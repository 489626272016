import { FunctionComponent } from 'react';

interface IProps {
  url: string;
}

export const ReportIFrame: FunctionComponent<IProps> = ({ url }) => {
  return (
    <iframe
      style={{ flex: 1 }}
      src={url}
      title='report'
      frameBorder='0'
      allowFullScreen={true}
    ></iframe>
  );
};
