import { IMeter, IMeterName, IMeterStatusCount, IMeterTypeCount, IMeterVendor } from '../../model';
import { getApi, getMessagingApi } from './api';

const BASE_URL = '/meter';

const get = (id: number): Promise<IMeter> =>
  getApi()
    .get<IMeter>(`${BASE_URL}/${id}`)
    .then((response) => response.data);

const getZoneMeterNames = (zoneId: number): Promise<Array<IMeterName>> =>
  getApi()
    .get<Array<IMeterName>>(`${BASE_URL}/by-zone/?id=${zoneId}`)
    .then((response) => response.data);

const getSpotMeterNames = (spotId: number): Promise<Array<IMeterName>> =>
  getApi()
    .get<Array<IMeterName>>(`${BASE_URL}/by-spot/?id=${spotId}`)
    .then((response) => (response.data ? response.data : []));

const getVendors = (): Promise<Array<IMeterVendor>> =>
  getApi()
    .get<Array<IMeterVendor>>(`${BASE_URL}/vendors`)
    .then((response) => response.data || []);

const getTypes = async (): Promise<Array<IMeterTypeCount>> =>
  getApi()
    .get<Array<IMeterTypeCount>>(`${BASE_URL}/types`)
    .then((response) => response.data || []);

const getBlockfaceMeterNames = (blockfaceId: number): Promise<Array<IMeterName>> =>
  getApi()
    .get<Array<IMeterName>>(`${BASE_URL}/by-blockface/?id=${blockfaceId}`)
    .then((response) => response.data);

const update = (meters: Array<{ Id: number; NewPosition: [number, number] }>): Promise<object> =>
  getMessagingApi()
    .put(`${BASE_URL}`, meters)
    .then((response) => response.data);

const getPerformanceParkingCount = (): Promise<number> =>
  getApi()
    .get<{ Count: number }>(`${BASE_URL}/count-performance-parking`)
    .then((response) => response.data.Count);

const getStudyAreaMeterNames = (studyAreaId: number): Promise<Array<IMeterName>> =>
  getApi()
    .get<Array<IMeterName>>(`${BASE_URL}/by-study-area/?id=${studyAreaId}`)
    .then((response) => response.data);

const getStatusCount = (): Promise<Array<IMeterStatusCount>> =>
  getApi()
    .get<Array<IMeterStatusCount>>(`${BASE_URL}/status-count`)
    .then((response) => response.data || []);

export const meters = {
  get,
  getZoneMeterNames,
  getSpotMeterNames,
  getVendors,
  getTypes,
  update,
  getBlockfaceMeterNames,
  getPerformanceParkingCount,
  getStudyAreaMeterNames,
  getStatusCount,
};
