import { WeekDay } from '../common';

export const DEFAULT_WEEK_DAYS: Array<WeekDay> = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday,
];

export const DEFAULT_MINUTES_START = 0;
export const DEFAULT_MINUTES_END: number = 24 * 60;
