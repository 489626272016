import { Observable, catchError, map, of, tap, zip } from 'rxjs';

import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Position } from 'geojson';

import { MIN_ZOOM } from '../constants';
import { Direction, IMeterState, ITile, LayerName, SensorFilter, TagFilter, TilesBoundingBox, WeekDay } from '../model';
import { IGeoEntityBase, IMeterGeo, IOffstreetZoneGeo, IZoneGeoBase } from '../model/api/geo-models';
import { MeterType } from '../model/api/meter';
import { store } from '../store';
import { dateUtils, geoUtils } from '../utils';
import { geoIndexes } from './api';

interface IFeaturesByY<T> {
  [y: number]: { data: Array<T>; expiresAt: Date };
}
interface IFeaturesByX {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: number]: IFeaturesByY<any>;
}
interface IFeaturesByZ {
  [z: number]: IFeaturesByX;
}
interface IFeaturesByItem {
  [item: string]: IFeaturesByZ;
}
interface IFeaturesByCity {
  [city: string]: IFeaturesByItem;
}

const EXPIRATION_SECS = 0;

export interface IMapLayersSource {
  meters: FeatureCollection;
  cameras: FeatureCollection;
  signs: FeatureCollection;
  spots: FeatureCollection;
  zones: FeatureCollection;
  offstreetZones: FeatureCollection;
  metersRevenue: FeatureCollection;
  zonesRevenue: FeatureCollection;
}

const _cache: IFeaturesByCity = {};

const loadMeters = (
  box: TilesBoundingBox,
  zoom: number,
  vendorsFilter: TagFilter,
  statusesFilter: TagFilter,
  typesFilter: TagFilter,
  showPerformanceParkingOnly: boolean,
  editedMeters: { [id: number]: IMeterState },
  editedMeter: IMeterState | null,
  isEditing: boolean,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();

  const tasks = tiles.map((tile) => loadItems(LayerName.Meters, tile, zoom, () => geoIndexes.getMeters(tile.x, tile.y, zoom)));

  const itemsSource = zip(tasks);

  return itemsSource.pipe(
    map((itemsArrays) => {
      let features: Array<Feature<Geometry, GeoJsonProperties>> = [];

      itemsArrays.forEach((items) => {
        items.forEach((f) => {
          if (!isEditing || !editedMeters[f.Id]) {
            if (f.TypeId === MeterType.DoubleSpot && f.Group) {
              const offset = directionToOffset(f.Direction);
              features.push(
                createMeterFeature(
                  f,
                  f.Position,
                  editedMeters,
                  isEditing,
                  f.Group.map((g) => g.Id),
                  offset,
                ),
              );
              f.Group.forEach((g) => {
                features.push(createMeterFeature(g, f.Position, editedMeters, isEditing, [f.Id], oppositeOffset(offset)));
              });
            } else {
              features.push(createMeterFeature(f, f.Position, editedMeters, isEditing));
            }
          }
        });
      });

      if (statusesFilter.enabled && !statusesFilter.allTagsEnabled()) {
        features = features.filter((f) => !!statusesFilter.tags[f.properties?.status]);
      }

      if (!vendorsFilter.allTagsEnabled()) {
        features = features.filter((f) => !!vendorsFilter.tags[f.properties?.vendorId]);
      }

      if (typesFilter.enabled && !typesFilter.allTagsEnabled()) {
        features = features.filter((f) => !!typesFilter.tags[f.properties?.type]);
      }

      if (showPerformanceParkingOnly) {
        features = features.filter((f) => f.properties?.performanceParking === true);
      }

      return {
        type: 'FeatureCollection',
        features: features,
      } as FeatureCollection;
    }),
  );
};

function createMeterFeature(
  f: IMeterGeo,
  position: Position,
  editState: { [id: number]: IMeterState },
  isEditing: boolean,
  group: Array<number> = [],
  offset = 'center',
): Feature {
  const editingOverride = isEditing ? editState[f.Id] : null;

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: editingOverride?.position || position,
    },
    properties: {
      id: f.Id,
      status: f.Status,
      policy: f.PolicyTypeId,
      vendorId: f.VendorId,
      offset: f.TypeId !== MeterType.MultiSpot ? offset : MeterType[MeterType.MultiSpot],
      type: f.TypeId,
      group: group,
      spotsCount: f.SpotsCount,
      performanceParking: f.PerformanceParking,
    },
  };
}

const loadTraffic = (box: TilesBoundingBox, zoom: number): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.Traffic, tile, zoom, () => geoIndexes.getTraffic(tile.x, tile.y, zoom)));
  return zip(tasks).pipe(
    map((x) => {
      const trafficSegments = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const ts of trafficSegments) {
        features[ts.Id] = {
          type: 'Feature',
          geometry: geoUtils.toGeometry(ts.Positions),
          properties: {
            id: ts.Id,
            code: ts.Code,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

const loadCameras = (box: TilesBoundingBox, zoom: number, statusesFilter: TagFilter): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.Cameras, tile, zoom, () =>
      geoIndexes.getCameras(tile.x, tile.y, zoom).pipe(
        map((x) => {
          if (!statusesFilter.enabled || statusesFilter.allTagsEnabled()) {
            return x;
          }

          return x.filter((i) => !!statusesFilter.tags[i.Status]);
        }),
      ),
    ),
  );
  return zip(tasks).pipe(map((x) => mapPoints(x)));
};

const loadSigns = (box: TilesBoundingBox, zoom: number, statusesFilter: TagFilter): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.Signs, tile, zoom, () =>
      geoIndexes.getSigns(tile.x, tile.y, zoom).pipe(
        map((x) => {
          if (!statusesFilter.enabled || statusesFilter.allTagsEnabled()) {
            return x;
          }

          return x.filter((i) => !!statusesFilter.tags[i.Status]);
        }),
      ),
    ),
  );

  return zip(tasks).pipe(map((x) => mapPoints(x)));
};

const loadSpots = (
  box: TilesBoundingBox,
  zoom: number,
  policiesFilter: TagFilter,
  statusesFilter: TagFilter,
  sensorFilter: SensorFilter,
): Observable<{ data: FeatureCollection; center: FeatureCollection }> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.Spots, tile, zoom, () => geoIndexes.getSpots(tile.x, tile.y, zoom)));

  return zip(tasks).pipe(
    map((itemsArray) => {
      const dataFeatures: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      const centerFeatures: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};

      itemsArray.forEach((items) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newItems: any[] = [];
        items.forEach((item) => {
          const offset = directionToOffset(item.Direction);
          const geometry = geoUtils.toGeometryPointOrPolygon(item.Positions);

          newItems.push({
            Positions: item.Positions,
            Center: item.Center,
            id: item.Id,
            status: item.Status,
            policyType: item.PolicyTypeId,
            companyName: item.CompanyName,
            parkingSensorId: item.ParkingSensorId,
            offset: item.Group?.length && geometry.type !== 'Polygon' ? offset : 'center',
          });

          if (item.Group?.length) {
            item.Group.forEach((x) => {
              const geometry = geoUtils.toGeometryPointOrPolygon(x.Positions);
              newItems.push({
                Positions: geometry.type !== 'Polygon' ? item.Positions : x.Positions,
                Center: x.Center,
                id: x.Id,
                status: x.Status,
                policyType: x.PolicyTypeId,
                companyName: x.CompanyName,
                parkingSensorId: item.ParkingSensorId,
                offset: geometry.type !== 'Polygon' ? oppositeOffset(offset) : 'center',
              });
            });
          }
        });

        if (policiesFilter.enabled && !policiesFilter.allTagsEnabled()) {
          newItems = newItems.filter((f) => !!policiesFilter.tags[f.policyType]);
        }

        if (statusesFilter.enabled && !statusesFilter.allTagsEnabled()) {
          newItems = newItems.filter((f) => !!statusesFilter.tags[f.status]);
        }

        if (sensorFilter === SensorFilter.WithSensors) {
          newItems = newItems.filter((f) => !!f.parkingSensorId);
        } else if (sensorFilter === SensorFilter.WithoutSensors) {
          newItems = newItems.filter((f) => !f.parkingSensorId);
        }

        for (const item of newItems) {
          dataFeatures[item.id] = { type: 'Feature', geometry: geoUtils.toGeometryPointOrPolygon(item.Positions), properties: item };
          delete item.Positions;

          if (item.Center) {
            centerFeatures[item.id] = {
              type: 'Feature',
              geometry: { type: 'Point', coordinates: item.Center },
              properties: { id: item.id, parkingSensorId: item.parkingSensorId },
            };
            delete item.Center;
          }
        }
      });

      return {
        data: {
          type: 'FeatureCollection',
          features: Object.values(dataFeatures),
        },
        center: {
          type: 'FeatureCollection',
          features: Object.values(centerFeatures),
        },
      };
    }),
  );
};

const loadZones = (
  box: TilesBoundingBox,
  zoom: number,
  showPerformanceParkingOnly: boolean,
): Observable<{ data: FeatureCollection; limits: FeatureCollection }> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.Zones, tile, zoom, () => geoIndexes.getZones(tile.x, tile.y, zoom)));
  return zip(tasks).pipe(
    map((x) => {
      let zones = x;
      if (showPerformanceParkingOnly) {
        zones = zones.map((z) => z.filter((x) => x.PerformanceParking === true));
      }

      return {
        data: mapZonesGeometry(zones, (f) => ({
          id: f.Id,
          center: f.Center,
          code: f.Code,
          color: f.Color,
        })),
        limits: mapZonesGeometry(
          zones,
          (f) => ({
            id: f.Id,
            limit: f.ParkingLimit,
            limitStr: dateUtils.formatMinutes(f.ParkingLimit),
          }),
          (f) => ({ type: 'Point', coordinates: f.Center || f.Positions[0][0] }),
          (f) => !!f.Center && !!f.ParkingLimit,
        ),
      };
    }),
  );
};

const loadLoadingZones = (
  box: TilesBoundingBox,
  zoom: number,
): Observable<{ data: FeatureCollection; spots: FeatureCollection; occupiedCount: FeatureCollection }> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.Zones, tile, zoom, () => geoIndexes.getLoadingZones(tile.x, tile.y, zoom)));

  return zip(tasks).pipe(
    map((x) => {
      const items = x.reduce((a, b) => a.concat(b));
      const spotsMap: { [key: number]: boolean } = {};
      const spots: Array<Feature<Geometry, GeoJsonProperties>> = [];
      const occupiedCountMap: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};

      const zonesFeatures: Array<Feature<Geometry, GeoJsonProperties>> = items.map((f) => {
        f.Spots.forEach((s) => {
          if (!spotsMap[s.Id]) {
            spotsMap[s.Id] = true;
            spots.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: s.Position,
              },
              properties: {
                id: s.Id,
                status: s.Status,
              },
            });
          }
        });

        occupiedCountMap[f.Id] = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: f.Positions[0][f.Positions.length - 1],
          },
          properties: {
            id: f.Id,
            spotsCount: f.SpotsCount,
            occupiedSpotsCount: f.OccupiedSpotsCount,
          },
        };

        return {
          type: 'Feature',
          geometry: geoUtils.toGeometry(f.Positions),
          properties: {
            id: f.Id,
            code: f.Code,
          },
        };
      });

      return {
        data: {
          type: 'FeatureCollection',
          features: zonesFeatures,
        },
        spots: {
          type: 'FeatureCollection',
          features: spots,
        },
        occupiedCount: {
          type: 'FeatureCollection',
          features: Object.values(occupiedCountMap),
        },
      };
    }),
  );
};

const createOffstreetZoneProprerties = (zone: IOffstreetZoneGeo) => ({
  id: zone.Id,
  name: zone.Name,
  cityOwnership: zone.CityOwnership,
  type: zone.Type,
  vendorId: zone.VendorId,
});

const loadOffstreetZones = (
  box: TilesBoundingBox,
  zoom: number,
  vendorsFilter: TagFilter,
  typesFilter: TagFilter,
  showCityOwned: boolean,
): Observable<{ data: FeatureCollection; center: FeatureCollection }> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.OffstreetZones, tile, zoom, () =>
      geoIndexes.getOffstreetZones(tile.x, tile.y, zoom).pipe(
        map((x) => {
          let zones = x;

          if (!vendorsFilter.allTagsEnabled()) {
            zones = zones.filter((i) => !!vendorsFilter.tags[i.VendorId]);
          }

          if (typesFilter.enabled && !typesFilter.allTagsEnabled()) {
            zones = zones.filter((i) => !!typesFilter.tags[i.Type]);
          }

          if (showCityOwned) {
            zones = zones.filter((i) => i.CityOwnership);
          }

          return zones;
        }),
      ),
    ),
  );

  return zip(tasks).pipe(
    map((x) => {
      const zones = x.reduce((a, b) => a.concat(b));
      const dataFeatures: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      const centerFeatures: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const zone of zones) {
        dataFeatures[zone.Id] = {
          type: 'Feature',
          geometry: geoUtils.toGeometryPointOrPolygon(zone.Positions),
          properties: createOffstreetZoneProprerties(zone),
        };

        if (zone.Center) {
          centerFeatures[zone.Id] = {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: zone.Center },
            properties: createOffstreetZoneProprerties(zone),
          };
        }
      }

      return {
        data: {
          type: 'FeatureCollection',
          features: Object.values(dataFeatures),
        },
        center: {
          type: 'FeatureCollection',
          features: Object.values(centerFeatures),
        },
      };
    }),
  );
};

const loadMetersRevenue = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.MetersRevenue, tile, zoom, () =>
      geoIndexes.getMetersRevenue(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );
  return zip(tasks).pipe(
    map((x) =>
      mapPoints(x, (f) => ({
        id: f.Id,
        status: f.Status,
        type: f.Type,
        revenue: f.Revenue || 0,
        normalizedRevenue: f.NormalizedRevenue || 0,
      })),
    ),
  );
};

const loadZonesRevenue = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.ZonesRevenue, tile, zoom, () =>
      geoIndexes.getZonesRevenue(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );
  return zip(tasks).pipe(
    map((x) =>
      mapZonesGeometry(x, (f) => ({
        id: f.Id,
        revenue: f.Revenue || 0,
        normalizedRevenue: f.NormalizedRevenue || 0,
        averageRevenue: f.AverageRevenue || 0,
        normalizedAverageRevenue: f.NormalizedAverageRevenue || 0,
        center: f.Center,
        code: f.Code,
      })),
    ),
  );
};

const loadOffstreetZonesRevenue = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.OffstreetZonesRevenue, tile, zoom, () =>
      geoIndexes.getOffstreetZonesRevenue(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );
  return zip(tasks).pipe(
    map((x) =>
      mapPoints(x, (f) => ({
        id: f.Id,
        revenue: f.Revenue || 0,
        normalizedRevenue: f.NormalizedRevenue || 0,
      })),
    ),
  );
};

const loadZoneParkingDurations = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.ZonesRevenue, tile, zoom, () =>
      geoIndexes.getZoneParkingDurations(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );

  return zip(tasks).pipe(
    map((x) =>
      mapZonesGeometry(x, (f) => ({
        id: f.Id,
        normalizedDuration: f.NormalizedAvgDuration || 0,
        duration: f.AvgDuration || 0,
        center: f.Center,
        code: f.Code,
      })),
    ),
  );
};

const loadBlockfaces = (box: TilesBoundingBox, zoom: number, showPerformanceParkingOnly: boolean): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.Blockfaces, tile, zoom, () => geoIndexes.getBlockfaces(tile.x, tile.y, zoom)));
  return zip(tasks).pipe(
    map((x) => {
      const blockfaces = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const b of blockfaces) {
        if (showPerformanceParkingOnly && !b.PerformanceParking) {
          continue;
        }

        features[b.Id] = {
          type: 'Feature',
          geometry: geoUtils.toGeometry(b.Positions),
          properties: {
            id: b.Id,
            code: b.Code,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

const loadBlockfacesRevenue = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.BlockfacesRevenue, tile, zoom, () =>
      geoIndexes.getBlockfacesRevenue(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );
  return zip(tasks).pipe(
    map((x) => {
      const blockfaces = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const b of blockfaces) {
        features[b.Id] = {
          type: 'Feature',
          geometry: geoUtils.toGeometry(b.Positions),
          properties: {
            id: b.Id,
            code: b.Code,
            revenue: b.Revenue || 0,
            normalizedRevenue: b.NormalizedRevenue || 0,
            averageRevenue: b.AverageRevenue || 0,
            normalizedAverageRevenue: b.NormalizedAverageRevenue || 0,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

const loadBlockfacesDuration = (
  box: TilesBoundingBox,
  zoom: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.BlockfacesDuration, tile, zoom, () =>
      geoIndexes.getBlockfaceParkingDurations(tile.x, tile.y, zoom, period, weekDays, minutesStart, minutesEnd),
    ),
  );
  return zip(tasks).pipe(
    map((x) => {
      const blockfaces = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const b of blockfaces) {
        features[b.Id] = {
          type: 'Feature',
          geometry: geoUtils.toGeometry(b.Positions),
          properties: {
            id: b.Id,
            code: b.Code,
            normalizedDuration: b.NormalizedAvgDuration || 0,
            duration: b.AvgDuration || 0,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

const loadStudyAreas = (box: TilesBoundingBox, zoom: number): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) => loadItems(LayerName.StudyAreas, tile, zoom, () => geoIndexes.getStudyAreas(tile.x, tile.y, zoom)));
  return zip(tasks).pipe(
    map((x) => {
      const areas = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const a of areas) {
        features[a.Id] = {
          type: 'Feature',
          geometry: { type: 'Polygon', coordinates: [a.Positions] },
          properties: {
            id: a.Id,
            name: a.Name,
            primary: a.Primary ? 1 : 0,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

const loadHeatmapStudyAreas = (box: TilesBoundingBox, zoom: number): Observable<FeatureCollection> => {
  const tiles = box.getTiles();
  const tasks = tiles.map((tile) =>
    loadItems(LayerName.HeatmapStudyAreas, tile, zoom, () => geoIndexes.getHeatmapStudyAreas(tile.x, tile.y, zoom)),
  );
  return zip(tasks).pipe(
    map((x) => {
      const areas = x.reduce((a, b) => a.concat(b));
      const features: { [key: number]: Feature<Geometry, GeoJsonProperties> } = {};
      for (const a of areas) {
        features[a.Id] = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: a.Positions,
          },
          properties: {
            id: a.Id,
            name: a.Name,
            primary: a.Primary ? 1 : 0,
          },
        };
      }

      return {
        type: 'FeatureCollection',
        features: Object.values(features),
      };
    }),
  );
};

export const geoProcessor = {
  loadMeters,
  loadCameras,
  loadSigns,
  loadSpots,
  loadZones,
  loadLoadingZones,
  loadOffstreetZones,
  loadMetersRevenue,
  loadZonesRevenue,
  loadOffstreetZonesRevenue,
  loadZoneParkingDurations,
  loadBlockfaces,
  loadTraffic,
  loadBlockfacesRevenue,
  loadBlockfacesDuration,
  loadStudyAreas,
  loadHeatmapStudyAreas,
};

function loadItems<T>(itemName: LayerName, tile: ITile, zoom: number, loader: () => Observable<Array<T>>): Observable<Array<T>> {
  if (zoom < MIN_ZOOM) {
    return of([]);
  }

  if (EXPIRATION_SECS === 0) {
    return loader().pipe(
      catchError((err) => {
        return of([]);
      }),
    );
  }

  const cityCode = store.getState().cities?.selectedCity?.Code || '';
  let cityLevel = _cache[cityCode];
  if (!cityLevel) {
    cityLevel = _cache[cityCode] = {};
  }

  let itemLevel = cityLevel[itemName];
  if (!itemLevel) {
    itemLevel = cityLevel[itemName] = {};
  }

  let zoomLevel = itemLevel[zoom];
  if (!zoomLevel) {
    zoomLevel = itemLevel[zoom] = {};
  }

  let xLevel = zoomLevel[tile.x];
  if (!xLevel) {
    xLevel = zoomLevel[tile.x] = {};
  }

  const yLevel = xLevel[tile.y];
  if (!!yLevel && new Date() <= yLevel.expiresAt) {
    return of(yLevel.data);
  }

  return loader().pipe(
    tap((items) => {
      xLevel[tile.y] = { data: items, expiresAt: getExpirationDate() };
    }),
    catchError((err) => {
      return of([]);
    }),
  );
}

function mapPoints<TEntityStatus, TId extends number | string, T extends IGeoEntityBase<Position, TEntityStatus, TId>>(
  itemsArrays: T[][],
  propertiesFactory: (f: T) => GeoJsonProperties = (f) => ({
    id: f.Id,
    status: f.Status,
  }),
  geometryFactory: (f: T) => Geometry = (f) => ({
    type: 'Point',
    coordinates: f.Position,
  }),
): FeatureCollection {
  const items = itemsArrays.reduce((a, b) => a.concat(b));

  const features: Array<Feature<Geometry, GeoJsonProperties>> = items.map((f) => {
    return {
      type: 'Feature',
      geometry: geometryFactory(f),
      properties: propertiesFactory(f),
    };
  });

  return {
    type: 'FeatureCollection',
    features: features,
  };
}

function mapZonesGeometry<T extends IZoneGeoBase>(
  itemsArrays: T[][],
  propertiesFactory: (f: T) => GeoJsonProperties = (f) => ({
    id: f.Id,
    code: f.Code,
    center: f.Center,
  }),
  geometryFactory: (f: T) => Geometry = (f) => {
    return geoUtils.toGeometry(f.Positions);
  },
  predicate: (f: T) => boolean = (f) => true,
): FeatureCollection {
  const items = itemsArrays.reduce((a, b) => a.concat(b));

  const features: Array<Feature<Geometry, GeoJsonProperties>> = items.filter(predicate).map((f) => {
    return {
      type: 'Feature',
      geometry: geometryFactory(f),
      properties: propertiesFactory(f),
    };
  });

  return {
    type: 'FeatureCollection',
    features: features,
  };
}

function getExpirationDate(): Date {
  return new Date(new Date().getTime() + EXPIRATION_SECS * 1000);
}

function directionToOffset(direction: Direction): string {
  switch (direction) {
    case Direction.East:
      return 'left';
    case Direction.West:
      return 'right';
    case Direction.North:
      return 'top';
    case Direction.South:
      return 'bottom';
    default:
      return 'center';
  }
}

function oppositeOffset(offset: string): string {
  switch (offset) {
    case 'right':
      return 'left';
    case 'left':
      return 'right';
    case 'bottom':
      return 'top';
    case 'top':
      return 'bottom';
    default:
      return 'center';
  }
}
