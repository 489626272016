import { getApi } from './api';
import { IParkingEvent } from '../../model';
import { Utils } from '../../utils';

const BASE_URL = '/ParkingEvent';

const adjustParkingEvent = (event: IParkingEvent) => {
  if (event.Start) {
    event.Start = new Date(event.Start);
  }
  if (event.End) {
    event.End = new Date(event.End);
  }
  event.VehicleType = Utils.capitalize(event.VehicleType);
};

const getByCamera = (cameraId: string): Promise<IParkingEvent[]> => {
  return getApi()
    .get<IParkingEvent[]>(`${BASE_URL}/by-camera?id=${cameraId}`)
    .then((x) => {
      const result = x.data || [];
      result.forEach(adjustParkingEvent);
      return result;
    });
};

const getBySpotId = (spotId: number) => {
  return getApi()
    .get<IParkingEvent[]>(`${BASE_URL}/by-spot?id=${spotId}`)
    .then((x) => {
      const result = x.data || [];
      result.forEach(adjustParkingEvent);
      return result;
    });
};

export const parkingEvents = {
  getByCamera,
  getBySpotId,
};
