import { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { getSelectedState } from '@progress/kendo-react-data-tools';
import { Grid, GridColumn, GridNoRecords, GridPageChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';

import styles from './KendoGrid.module.scss';

const DATA_ITEM_KEY = 'id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

interface IProps {
  onAdd?: () => void;
  onDelete?: (ids: number[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  skip: number;
  take: number;
  total: number;
  pageChange: (event: GridPageChangeEvent) => void;
  children: React.ReactNode;
  noRecords?: React.ReactNode;
  className?: string;
}

export const KendoGrid: FC<IProps> = ({ onAdd, onDelete, data, skip, take, total, pageChange, children, noRecords, className }) => {
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState],
  );

  useEffect(() => {
    setSelectedState({});
  }, [skip, take]);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div className={styles.gridActions}>
        {!!onAdd && (
          <Button icon='plus' onClick={onAdd} className={styles.button}>
            Add
          </Button>
        )}
        {!!onDelete && (
          <Button
            icon='delete'
            onClick={() => {
              onDelete(data.filter((item) => selectedState[idGetter(item)]).map((item) => idGetter(item)));
              setSelectedState({});
            }}
            className={styles.button}
            disabled={data.findIndex((item) => selectedState[idGetter(item)]) === -1}
          >
            Delete
          </Button>
        )}
      </div>

      <Grid
        className={styles.grid}
        style={{ height: '400px' }}
        data={data.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        skip={skip}
        take={take}
        total={total}
        pageable={{
          previousNext: true,
        }}
        onPageChange={pageChange}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        onSelectionChange={onSelectionChange}
      >
        {noRecords ? <GridNoRecords>{noRecords}</GridNoRecords> : null}

        {!!onDelete && <GridColumn width={50} field={SELECTED_FIELD} />}

        {children}
      </Grid>
    </div>
  );
};
