import { getApi } from './api';
import { IBlockface, ILookupValue } from '../../model';

const BASE_URL = '/blockface';

const get = (id: number): Promise<IBlockface> => {
  return getApi()
    .get<IBlockface>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

const getStudyAreaBlockfaceNames = (studyAreaId: number) => {
  return getApi()
    .get<ILookupValue[]>(`${BASE_URL}/by-study-area/?id=${studyAreaId}`)
    .then((response) => response.data || []);
};

export const blockfaces = {
  get,
  getCount,
  getStudyAreaBlockfaceNames,
};
