import { WeekDay } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/traffic';

export interface ISpeedData {
  Id: number;
  Speed: number;
  NormalizedSpeed: number;
}

const getSegmentsCount = (): Promise<number> =>
  getApi()
    .get<{ Count: number }>(`${BASE_URL}/segment/count`)
    .then((response) => response.data.Count);

const getSpeedData = (period: [Date, Date], weekDays: WeekDay[], minutesStart: number, minutesEnd: number): Promise<Array<ISpeedData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<ISpeedData>>(
      `${BASE_URL}/heatmap-data?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
        period[1],
      )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

export const traffic = {
  getSpeedData,
  getSegmentsCount,
};
