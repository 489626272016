import { EMPTY_FEATURE_COLLECTION } from '../constants';

const getSuggestedStreets = (cityCode: string, fileName: string) => {
  return fetch(`/study-objects/${cityCode.toLowerCase()}/${fileName}`)
    .then((res) => res.json())
    .catch((_) => EMPTY_FEATURE_COLLECTION);
};

const listEnforcements = (cityCode: string) => getSuggestedStreets(cityCode, 'parking-enforcement.geojson');

const listPlannings = (cityCode: string) => getSuggestedStreets(cityCode, 'planning.geojson');

const listManagements = (cityCode: string) => getSuggestedStreets(cityCode, 'parking-management.geojson');

const listStudyAreas = (cityCode: string) => getSuggestedStreets(cityCode, 'study-areas-boundaries.geojson');

export const suggestedStreets = {
  listEnforcements,
  listPlannings,
  listManagements,
  listStudyAreas,
};
