import { Action, AnyAction } from '@reduxjs/toolkit';
import { from, Observable, of, zip } from 'rxjs';
import { map, filter, concatMap, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { FeatureCollection } from 'geojson';

import { suggestedStreets } from '../../../../services';
import { citiesActions } from '../../../common';
import { SuggestedStreetType, TagFilter } from '../../../../model';
import { suggestedStreetsGeoActions, suggestedStreetsLayerActions } from '..';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';

function loadStrets(cityCode: string) {
  return zip(
    from(suggestedStreets.listEnforcements(cityCode)),
    from(suggestedStreets.listPlannings(cityCode)),
    from(suggestedStreets.listManagements(cityCode)),
  );
}

function processData(x: [FeatureCollection, FeatureCollection, FeatureCollection], filter: TagFilter | null) {
  return [
    applyTypeFilter(filter, SuggestedStreetType.Enforcement, x[0]),
    applyTypeFilter(filter, SuggestedStreetType.Planning, x[1]),
    applyTypeFilter(filter, SuggestedStreetType.Management, x[2]),
  ];
}

function applyTypeFilter(filter: TagFilter | null, type: string, data: FeatureCollection) {
  return filter && filter.enabled && !filter.allTagsEnabled() && !filter.tags[type] ? EMPTY_FEATURE_COLLECTION : data;
}

function extractTypes(x: [FeatureCollection, FeatureCollection, FeatureCollection]) {
  const types = new Array<SuggestedStreetType>();
  x[0].features.length && types.push(SuggestedStreetType.Enforcement);
  x[1].features.length && types.push(SuggestedStreetType.Planning);
  x[2].features.length && types.push(SuggestedStreetType.Management);
  return types;
}

const fetchStudyAreasEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(suggestedStreetsGeoActions.fetchStudyAreas.match),
    concatMap((action) =>
      from(suggestedStreets.listStudyAreas(action.payload.cityCode)).pipe(
        map((x) => suggestedStreetsGeoActions.fetchStudyAreasSuccess(x)),
        catchError((err) => of(suggestedStreetsGeoActions.fetchStudyAreasFailed(err.message))),
      ),
    ),
  );

const fetchSuggestedStreetsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(suggestedStreetsGeoActions.fetchStreets.match),
    concatMap((action) =>
      loadStrets(action.payload.cityCode).pipe(
        map((x) => processData(x, action.payload.types)),
        map(([enforcements, plannings, managements]) =>
          suggestedStreetsGeoActions.fetchStreetsSuccess({ enforcements, plannings, managements }),
        ),
        catchError((err) => of(suggestedStreetsGeoActions.fetchStreetsFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((action) => (action.payload?.Code ? of(suggestedStreetsLayerActions.fetchTypes({ cityCode: action.payload.Code })) : of())),
  );

const fetchTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(suggestedStreetsLayerActions.fetchTypes.match),
    concatMap((action) =>
      loadStrets(action.payload.cityCode).pipe(
        map((x) => extractTypes(x)),
        map((x) => suggestedStreetsLayerActions.fetchTypesSuccess({ cityCode: action.payload.cityCode, types: x })),
        catchError((err) => of(suggestedStreetsLayerActions.fetchTypesFailed(err.message))),
      ),
    ),
  );

export const suggestedStreetsEpic = combineEpics<AnyAction>(
  fetchSuggestedStreetsEpic,
  citySelectedEpic,
  fetchTypesEpic,
  fetchStudyAreasEpic,
);
