import { Action, AnyAction } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { ICity } from '../../../model';
import { amplitudeService, layersStateSerivce, meters } from '../../../services';
import { cities } from '../../../services/api';
import { areaTypesActions, savedViewsActions } from '../../map';
import { citiesActions } from './cities-slice';

const getCitiesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchCities.match),
    concatMap((action) =>
      from(cities.getAll()).pipe(
        mergeMap((x) => of(citiesActions.fetchCitiesSuccess(x), citiesActions.selectCity(getSavedCity(x)))),
        catchError((err) => of(citiesActions.fetchCitiesFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((action) => {
      localStorage.setItem('NEXCITY-CITY', action.payload?.Code || '');

      if (action.payload) {
        amplitudeService.setCity(action.payload.Name);
        const settings = layersStateSerivce.getLayersSettings(action.payload.Code);
        if (settings) {
          return of(
            areaTypesActions.fetchAreaTypes(),
            savedViewsActions.applySavedLayersSettings(settings),
            citiesActions.fetchPerformanceParkingCount(),
          );
        }
      }

      return of(areaTypesActions.fetchAreaTypes(), citiesActions.fetchPerformanceParkingCount());
    }),
  );

const fetchViewStateEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchViewState.match),
    mergeMap(() => {
      const viewStateJson = localStorage.getItem('VIEW_STATE');
      return of(citiesActions.setViewState(viewStateJson ? JSON.parse(viewStateJson) : undefined));
    }),
  );

const saveViewStateEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.saveViewState.match),
    mergeMap((action) => {
      const viewStateJson = JSON.stringify(action.payload);
      localStorage.setItem('VIEW_STATE', viewStateJson);
      return of();
    }),
  );

const fetchPerformanceParkingCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchPerformanceParkingCount.match),
    concatMap(() =>
      from(meters.getPerformanceParkingCount()).pipe(
        map((x) => citiesActions.fetchPerformanceParkingCountSuccess(x)),
        catchError((err) => of(citiesActions.fetchPerformanceParkingCountFailed(err.message))),
      ),
    ),
  );

export const citiesEpic = combineEpics<AnyAction>(
  citySelectedEpic,
  getCitiesEpic,
  fetchViewStateEpic,
  saveViewStateEpic,
  fetchPerformanceParkingCount,
);

function getSavedCity(cities: Array<ICity>): ICity | null {
  const cityCode = localStorage.getItem('NEXCITY-CITY');
  if (!cityCode) {
    return cities[0];
  }

  return cities.find((x) => x.Code === cityCode) || null;
}
