import { IViolationGroup, WeekDay } from '../../model';
import { getApi } from './api';
import { dateUtils } from '../../utils';

const BASE_URL = '/enforcement';

export interface IViolationHeatmapData {
  Id: number;
  Total: number;
  NormalizedTotal: number;
  Count: number;
  NormalizedCount: number;
}

const buildQueryString = (period: [Date, Date], weekDays: WeekDay[], minutesStart: number, minutesEnd: number, groupId?: number | null) => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  let queryString = `period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
    period[1],
  )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`;
  if (groupId && groupId > 0) {
    queryString += `&groupId=${groupId}`;
  }

  return queryString;
};

const getViolationGroups = (): Promise<Array<IViolationGroup>> =>
  getApi()
    .get<Array<IViolationGroup>>(`${BASE_URL}/groups`)
    .then((response) => response.data || []);

const getStudyAreaViolationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
  groupId?: number | null,
): Promise<Array<IViolationHeatmapData>> => {
  const queryString = buildQueryString(period, weekDays, minutesStart, minutesEnd, groupId);
  return getApi()
    .get<Array<IViolationHeatmapData>>(`${BASE_URL}/study-area/heatmap-data?${queryString}`)
    .then((response) => response.data || []);
};

const getBlockfaceViolationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
  groupId?: number | null,
): Promise<Array<IViolationHeatmapData>> => {
  const queryString = buildQueryString(period, weekDays, minutesStart, minutesEnd, groupId);
  return getApi()
    .get<Array<IViolationHeatmapData>>(`${BASE_URL}/blockface/heatmap-data?${queryString}`)
    .then((response) => response.data || []);
};

const getZoneViolationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
  groupId?: number | null,
): Promise<Array<IViolationHeatmapData>> => {
  const queryString = buildQueryString(period, weekDays, minutesStart, minutesEnd, groupId);
  return getApi()
    .get<Array<IViolationHeatmapData>>(`${BASE_URL}/zone/heatmap-data?${queryString}`)
    .then((response) => response.data || []);
};

const getMeterViolationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
  groupId?: number | null,
): Promise<Array<IViolationHeatmapData>> => {
  const queryString = buildQueryString(period, weekDays, minutesStart, minutesEnd, groupId);
  return getApi()
    .get<Array<IViolationHeatmapData>>(`${BASE_URL}/meter/heatmap-data?${queryString}`)
    .then((response) => response.data || []);
};

const getSpotViolationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
  groupId?: number | null,
): Promise<Array<IViolationHeatmapData>> => {
  const queryString = buildQueryString(period, weekDays, minutesStart, minutesEnd, groupId);
  return getApi()
    .get<Array<IViolationHeatmapData>>(`${BASE_URL}/spot/heatmap-data?${queryString}`)
    .then((response) => response.data || []);
};

const spotViolationHeatmapExists = () =>
  getApi()
    .get<{ Value: boolean }>(`${BASE_URL}/spot/heatmap-data-exists`)
    .then((response) => response.data.Value);

export const enforcements = {
  getViolationGroups,
  getStudyAreaViolationHeatmapData,
  getBlockfaceViolationHeatmapData,
  getZoneViolationHeatmapData,
  getMeterViolationHeatmapData,
  getSpotViolationHeatmapData,
  spotViolationHeatmapExists,
};
