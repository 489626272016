import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames/bind';

import {
  ExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelActionEvent,
} from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';

import styles from './Collapsible.module.scss';

const cx = classNames.bind(styles);

interface Props {
  header: string;
  children: React.ReactNode;
  expanded?: boolean;
  className?: string;
}

export const Collapsible: FunctionComponent<Props> = ({
  header,
  children,
  expanded,
  className,
}) => {
  const [collapsed, setCollapsed] = useState(!expanded);

  return (
    <ExpansionPanel
      className={cx('expander', className)}
      title={header}
      expanded={!collapsed}
      onAction={(event: ExpansionPanelActionEvent) => {
        setCollapsed(event.expanded);
      }}
    >
      <Reveal>{!collapsed && <ExpansionPanelContent>{children}</ExpansionPanelContent>}</Reveal>
    </ExpansionPanel>
  );
};
