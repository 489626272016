import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';
import { MapboxMap } from 'react-map-gl';

export interface ISelectedCustomLayer {
  FeatureId: string | number | undefined;
  LayerId: string;
  LayerName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Properties: any;
}

export interface ISelectedCustomLayerState {
  selected: ISelectedEntity<ISelectedCustomLayer, string>[];
}

const initialState: ISelectedCustomLayerState = {
  selected: [],
};

const slice = createSlice({
  name: 'selectedCustomLayers',
  initialState: initialState,
  reducers: {
    init(state, action: PayloadAction<{ map: MapboxMap }>) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.CustomLayers,
        initialState,
        (id) => id,
        (selected) => {
          selected.map((x) =>
            store.dispatch(
              selectedCustomLayersActions.loadCustomLayer({
                ...x,
                map: action.payload.map,
              }),
            ),
          );
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: string; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.CustomLayers, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.CustomLayers);
    },
    selectCustomaLayer(state, action: PayloadAction<{ layer: ISelectedCustomLayer; position: Position; initPosition?: Position }>) {
      const id = `${action.payload.layer.LayerId}:${action.payload.layer.FeatureId}`;
      selectedSliceLogic.handleLoad(state, id, action.payload.position, null, null, action.payload.initPosition);
      selectedSliceLogic.handleLoadSuccess(state, id, action.payload.layer, action.payload.position, action.payload.initPosition);
    },
    loadCustomLayer(
      state,
      action: PayloadAction<{ map: MapboxMap; id: string; position: Position | null; initPosition?: Position | null }>,
    ) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadCustomLayerSuccess(state, action: PayloadAction<{ layer: ISelectedCustomLayer; position: Position; initPosition?: Position }>) {
      const id = `${action.payload.layer.LayerId}:${action.payload.layer.FeatureId}`;
      selectedSliceLogic.handleLoadSuccess(state, id, action.payload.layer, action.payload.position, action.payload.initPosition);
    },
    loadCustomLayerFailed(state, action: PayloadAction<{ id: string; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    closePopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.CustomLayers, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.CustomLayers);
    },
    openPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.CustomLayers, action.payload);
    },
  },
});

// Actions
export const selectedCustomLayersActions = slice.actions;

// Selectors
export const selectedCustomLayers = (state: RootState) => state.selectedCustomLayers;

// Reducer
export const selectedCustomLayersReducer = slice.reducer;
