import React, { FC, useEffect, useState } from 'react';
import { ListItemProps, MultiSelect, MultiSelectChangeEvent, TagData } from '@progress/kendo-react-dropdowns';
import { Subject, debounceTime } from 'rxjs';

import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';

import styles from './MultiSelectCheckBox.module.scss';

interface IProps {
  items: Item[];
  selected: string[];
  placeholder?: string;
  onChange: (items: string[]) => void;
}

interface Item {
  id: string;
  text: string;
  order?: number;
}

const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  const itemChildren = (
    <span>
      <>
        <input type='checkbox' readOnly checked={itemProps.selected} />
        &nbsp;{li.props.children}
      </>
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const tagRender = (tagData: TagData) => <span key={tagData.data[0].id}>{tagData.text}</span>;

export const MultiSelectCheckBox: FC<IProps> = ({ items, selected, placeholder, onChange }) => {
  const localization = useExtendedLocalization();
  const [value, setValue] = useState<Item[]>([]);
  const [open, setOpen] = useState(false);
  const [onOpenChange$] = useState(() => new Subject<boolean>());

  useEffect(() => {
    setValue(items.filter((x) => selected.find((i) => i === x.id)));
  }, [selected, items]);

  const handleChange = (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
    setValue(values);
    onChange(values.map((x) => x.id));
  };

  useEffect(() => {
    const subscription = onOpenChange$.pipe(debounceTime(150)).subscribe({
      next: (val) => {
        setOpen(val);
      },
    });

    return () => subscription.unsubscribe();
  }, [onOpenChange$]);

  const handleOpen = () => {
    onOpenChange$.next(true);
  };

  const handleClose = () => {
    onOpenChange$.next(false);
  };

  return (
    <MultiSelect
      className={styles.multiselect}
      data={items}
      opened={open}
      onChange={handleChange}
      value={value}
      textField='text'
      dataItemKey='id'
      allowCustom={true}
      onOpen={handleOpen}
      onBlur={handleClose}
      itemRender={itemRender}
      tagRender={tagRender}
      tags={value
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((x, idx) => ({ text: x.text + (idx < value.length - 1 ? ',' : ''), data: [x] }))}
      placeholder={placeholder ?? localization.toLanguageStringF('common.pleaseSelect', ['...'])}
    />
  );
};
