import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';
import { SavedZoneObjectsLayer, TagFilter } from '../../../../../model';

export interface IZoneObjectsLayerState {
  cityCode: string | null;
  curbfacesAvailable: boolean;
  displayCurbfaces: boolean;
  curbfaceTypes: Array<{ id: string; count: number }>;
  curbfaceTypesFilter: TagFilter;
}

const initialState: IZoneObjectsLayerState = {
  cityCode: null,

  curbfacesAvailable: false,
  displayCurbfaces: false,

  curbfaceTypes: [],
  curbfaceTypesFilter: new TagFilter(true),
};

const slice = createSlice({
  name: 'zoneObjectsLayer',
  initialState: initialState,
  reducers: {
    fetchData(state, action: PayloadAction<{ cityCode: string }>) {},
    fetchDataSuccess(
      state,
      action: PayloadAction<{
        cityCode: string;
        curbfacesCount: number;
        types: Array<{ id: string; count: number }>;
      }>,
    ) {
      state.cityCode = action.payload.cityCode;
      state.curbfacesAvailable = action.payload.curbfacesCount > 0;
      state.curbfaceTypes = action.payload.types;
      state.curbfaceTypesFilter = action.payload.types.length > 0 ? state.curbfaceTypesFilter.enable(true) : new TagFilter(false);
    },
    fetchDataFailed(state, action: PayloadAction<string>) {
      console.log(action);
      state.curbfacesAvailable = false;
      state.curbfaceTypes = [];
      state.curbfaceTypesFilter = new TagFilter(false);
    },

    setDisplayCurbfaces(state, action: PayloadAction<boolean>) {
      state.displayCurbfaces = action.payload;
    },

    setCurbfaceTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.curbfaceTypesFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedZoneObjectsLayer | undefined>) {
      if (action.payload) {
        state.displayCurbfaces = action.payload.displayCurbfaces;

        state.curbfaceTypesFilter = TagFilter.fromSavedValue(action.payload.curbfaceTypesFilter);
      }
    },
  },
});

// Actions
export const zoneObjectsLayerActions = slice.actions;

// Selectors
export const selectZoneObjectsLayer = (state: RootState) => state.zoneObjectsLayer;

// Reducer
export const zoneObjectsLayerReducer = slice.reducer;
