import { selectLoadingZonesCount, selectUserProfile } from '../features';
import { useAppSelector } from '../store/hooks';

export const useLoadingZonesEnabled = () => {
  const loadingZonesCount = useAppSelector(selectLoadingZonesCount);
  const userProfile = useAppSelector(selectUserProfile);
  if (loadingZonesCount === null || userProfile === null) {
    return null;
  }

  return loadingZonesCount > 0 && userProfile.isAdmin;
};
