import { FC } from 'react';

import styles from './PopupHeaderPlaceholder.module.scss';

export const PopupHeaderPlaceholder: FC = () => (
  <div className={`ph-item ${styles.header}`}>
    <div className={`ph-picture ${styles.picture}`}></div>

    <div>
      <div className='ph-row'>
        <div className='ph-col-4 big'></div>
        <div className='ph-col-8 empty big'></div>
        <div className='ph-col-8'></div>
        <div className='ph-col-4 empty'></div>
      </div>
    </div>
  </div>
);
