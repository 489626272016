import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WeekDay } from '../../../../model';
import { IViolationHeatmapData } from '../../../../services';
import { RootState } from '../../../../store';

export interface IViolationState {
  studyAreaViolationData: IViolationHeatmapData[];
  studyAreaViolationDataLoading: boolean;

  blockfaceViolationData: IViolationHeatmapData[];
  blockfaceViolationDataLoading: boolean;

  zoneViolationData: IViolationHeatmapData[];
  zoneViolationDataLoading: boolean;

  meterViolationData: IViolationHeatmapData[];
  meterViolationDataLoading: boolean;

  spotViolationData: IViolationHeatmapData[];
  spotViolationDataLoading: boolean;
  hasSpotViolation: boolean;
}

const initialState: IViolationState = {
  studyAreaViolationData: [],
  studyAreaViolationDataLoading: false,

  blockfaceViolationData: [],
  blockfaceViolationDataLoading: false,

  zoneViolationData: [],
  zoneViolationDataLoading: false,

  meterViolationData: [],
  meterViolationDataLoading: false,

  spotViolationData: [],
  spotViolationDataLoading: false,
  hasSpotViolation: false,
};

const slice = createSlice({
  name: 'enforcementData',
  initialState: initialState,
  reducers: {
    fetchStudyAreaViolationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
        groupId?: number | null;
      }>,
    ) {
      state.studyAreaViolationDataLoading = true;
    },
    fetchStudyAreaViolationDataSuccess(state, action: PayloadAction<IViolationHeatmapData[]>) {
      state.studyAreaViolationData = action.payload;
      state.studyAreaViolationDataLoading = false;
    },
    fetchStudyAreaViolationDataFailed(state, action: PayloadAction<string>) {
      state.studyAreaViolationDataLoading = false;
      console.error(action);
    },

    fetchBlockfaceViolationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
        groupId?: number | null;
      }>,
    ) {
      state.blockfaceViolationDataLoading = true;
    },
    fetchBlockfaceViolationDataSuccess(state, action: PayloadAction<IViolationHeatmapData[]>) {
      state.blockfaceViolationData = action.payload;
      state.blockfaceViolationDataLoading = false;
    },
    fetchBlockfaceViolationDataFailed(state, action: PayloadAction<string>) {
      state.blockfaceViolationDataLoading = false;
      console.error(action);
    },

    fetchZoneViolationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
        groupId?: number | null;
      }>,
    ) {
      state.zoneViolationDataLoading = true;
    },
    fetchZoneViolationDataSuccess(state, action: PayloadAction<IViolationHeatmapData[]>) {
      state.zoneViolationData = action.payload;
      state.zoneViolationDataLoading = false;
    },
    fetchZoneViolationDataFailed(state, action: PayloadAction<string>) {
      state.zoneViolationDataLoading = false;
      console.error(action);
    },

    fetchMeterViolationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
        groupId?: number | null;
      }>,
    ) {
      state.meterViolationDataLoading = true;
    },
    fetchMeterViolationDataSuccess(state, action: PayloadAction<IViolationHeatmapData[]>) {
      state.meterViolationData = action.payload;
      state.meterViolationDataLoading = false;
    },
    fetchMeterViolationDataFailed(state, action: PayloadAction<string>) {
      state.meterViolationDataLoading = false;
      console.error(action);
    },

    fetchSpotViolationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
        groupId?: number | null;
      }>,
    ) {
      state.spotViolationDataLoading = true;
    },
    fetchSpotViolationDataSuccess(state, action: PayloadAction<IViolationHeatmapData[]>) {
      state.spotViolationData = action.payload;
      state.spotViolationDataLoading = false;
    },
    fetchSpotViolationDataFailed(state, action: PayloadAction<string>) {
      state.spotViolationDataLoading = false;
      console.error(action);
    },

    fetchHasSpotViolation(state) {},
    fetchHasSpotViolationSuccess(state, action: PayloadAction<boolean>) {
      state.hasSpotViolation = action.payload;
    },
    fetchHasSpotViolationFailed(state, action: PayloadAction<string>) {
      console.error(action);
    },
  },
});

// Actions
export const violationDataActions = slice.actions;

// Selectors
export const selectViolationData = (state: RootState) => state.violationData;

// Reducer
export const violationDataReducer = slice.reducer;
