import { FunctionComponent } from 'react';
import { MeterType } from '../../../../model';

import styles from './MeterTypeIcon.module.scss';

interface Props {
  className?: string;
  typeId: number;
}

export const MeterTypeIcon: FunctionComponent<Props> = ({ className, typeId }) => {
  if (typeId === null || typeId === undefined) {
    return null;
  }

  return (
    <span className={`${className || ''} ${styles.icon} meter-type-${MeterType[typeId]}`}></span>
  );
};
