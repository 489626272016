import { IStudyArea, IStudyAreaName } from '../../model';
import { getApi } from './api';

const BASE_URL = '/studyArea';

const getNames = (): Promise<Array<IStudyAreaName>> =>
  getApi()
    .get<Array<IStudyAreaName>>(BASE_URL)
    .then((response) => (response.data ? response.data : []));

const get = (id: number): Promise<IStudyArea> => {
  return getApi()
    .get<IStudyArea>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getZoneStudyAreaNames = (zoneId: number): Promise<Array<IStudyAreaName>> =>
  getApi()
    .get<Array<IStudyAreaName>>(`${BASE_URL}/by-zone/?id=${zoneId}`)
    .then((response) => response.data || []);

const getBlockfaceStudyAreaNames = (blockfaceId: number): Promise<Array<IStudyAreaName>> =>
  getApi()
    .get<Array<IStudyAreaName>>(`${BASE_URL}/by-blockface/?id=${blockfaceId}`)
    .then((response) => response.data || []);

const getMeterStudyAreaNames = (meterId: number): Promise<Array<IStudyAreaName>> =>
  getApi()
    .get<Array<IStudyAreaName>>(`${BASE_URL}/by-meter/?id=${meterId}`)
    .then((response) => response.data || []);

const getSpotStudyAreaNames = (spotIds: number[]): Promise<Array<IStudyAreaName>> => {
  const ids = spotIds.map((x) => `id=${x}`).join('&');
  return getApi()
    .get<Array<IStudyAreaName>>(`${BASE_URL}/by-spots/?${ids}`)
    .then((response) => response.data || []);
};

export const studyAreas = {
  getNames,
  get,
  getZoneStudyAreaNames,
  getBlockfaceStudyAreaNames,
  getMeterStudyAreaNames,
  getSpotStudyAreaNames,
};
