import { toast } from 'react-toastify';
import { distinctUntilChanged, filter, map, Subject } from 'rxjs';

const TIMEOUT = 4000;

const errorsStream$ = new Subject<string>();
errorsStream$
    .pipe(
        filter(x => !!x),
        map(msg => ({ key: msg, time: Date.now() })),
        distinctUntilChanged((a, b) => (a.key === b.key) && (a.time > b.time - TIMEOUT)),
        map(x => x.key)
    )
    .subscribe(msg => toast.error(msg));

const success = (message: string) => {
    toast.success(message);
}

const warning = (message: string) => {
    toast.warning(message);
}

const error = (message: string) => {
    errorsStream$.next(message);
}

const info = (message: string) => {
    toast.info(message);
}

export const notifications = {
    success,
    warning,
    error,
    info
}