import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';

import { RootState } from '../../../../store';

export interface IAreaLevelPolygonsState {
    loading: boolean;
    data: FeatureCollection;
}

const initialState: IAreaLevelPolygonsState = {
    loading: false,
    data: EMPTY_FEATURE_COLLECTION
};

const slice = createSlice({
    name: 'areaGeo',
    initialState: initialState,
    reducers: {
        fetch(state, action: PayloadAction<number>) {
            state.loading = true;
            state.data = EMPTY_FEATURE_COLLECTION;
        },
        fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchFailed(state, action: PayloadAction<string>) {
            state.loading = false;
            console.log(action);
        }
    }
});

// Actions
export const areaGeoActions = slice.actions;

// Selectors
export const selectAreaGeoLoading = (state: RootState) => state.areaGeo.loading;
export const selectAreaGeo = (state: RootState) => state.areaGeo.data;

// Reducer
export const areaGeoReducer = slice.reducer;
