import { FC, useEffect, useState } from 'react';
import { TabStrip as TabStripKendo, TabStripProps } from '@progress/kendo-react-layout';
import classNames from 'classnames/bind';

import styles from './TabStrip.module.scss';

const cx = classNames.bind(styles);

interface IProps extends TabStripProps {
  initialSelectedTab?: number;
  onSelectTab?: (selectedTab: number) => void;
}

export const TabStrip: FC<IProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState<number>(props.initialSelectedTab || 0);

  useEffect(() => {
    if (props.onSelectTab) {
      props.onSelectTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <TabStripKendo
      {...props}
      selected={selectedTab}
      onSelect={(e) => setSelectedTab(e.selected)}
      animation={false}
      className={cx('tabStrip', props.className)}
    >
      {props.children}
    </TabStripKendo>
  );
};
