import { FC, useEffect, useState } from 'react';
import { images } from '../../services';

import placeholder from '../../assets/placeholder-image.jpg';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  showPlaceholder?: boolean;
  imageLoaded?: (success: boolean) => void;
}

export const SecureImg: FC<Props> = ({ showPlaceholder, imageLoaded, ...props }) => {
  const src = props.src || null;
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      const getImage = async () => {
        const blob = await images.get(src);
        setBlobUrl(blob ? URL.createObjectURL(blob) : null);
        if (imageLoaded) {
          imageLoaded(blob !== null);
        }
      };
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const onLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    URL.revokeObjectURL(blobUrl || '');
    if (props.onLoad) {
      props.onLoad(event);
    }
  };

  if (!blobUrl && !showPlaceholder) {
    return null;
  }

  return <img {...props} src={blobUrl || placeholder} alt={props.alt} onLoad={onLoad}></img>;
};
