import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { geoProcessor, traffic } from '../../../../services';
import { trafficGeoActions } from './traffic-geo-slice';

const fetchTrafficGeoEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(trafficGeoActions.fetchGeo.match),
    switchMap((action) =>
      geoProcessor.loadTraffic(action.payload, action.payload.zoom).pipe(
        map((x) => trafficGeoActions.fetchGeoSuccess(x)),
        catchError((err) => of(trafficGeoActions.fetchGeoFailed(err.message))),
      ),
    ),
  );

const fetchTrafficSpeedData = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(trafficGeoActions.fetchSpeedData.match),
    switchMap((action) =>
      from(
        traffic.getSpeedData(action.payload.period, action.payload.weekDays, action.payload.minutesStart, action.payload.minutesEnd),
      ).pipe(
        map((x) => trafficGeoActions.fetchSpeedDataSuccess(x)),
        catchError((err) => of(trafficGeoActions.fetchSpeedDataFailed(err.message))),
      ),
    ),
  );

export const trafficEpic = combineEpics(fetchTrafficGeoEpic, fetchTrafficSpeedData);
