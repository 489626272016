import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AREA_LEVEL_NONE, IAreaLevel } from '../../../../model';
import { RootState } from '../../../../store';

export interface IAreaLayerState {
  enabled: boolean;
  level: IAreaLevel;
}

const initialState: IAreaLayerState = {
  enabled: false,
  level: AREA_LEVEL_NONE,
};

const slice = createSlice({
  name: 'areaLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<{ level: IAreaLevel }>) {
      state.enabled = action.payload.level.id !== AREA_LEVEL_NONE.id;
      state.level = action.payload.level;
    },
    setDisabled(state) {
      state.enabled = false;
      state.level = AREA_LEVEL_NONE;
    },
  },
});

// Actions
export const areaLayerActions = slice.actions;

// Selectors
export const selectAreaEnabled = (state: RootState) => state.areaLayer.enabled;
export const selectAreaLevel = (state: RootState) => state.areaLayer.level;
export const selectAreaLayer = (state: RootState) => state.areaLayer;

// Reducer
export const areaLayerReducer = slice.reducer;
