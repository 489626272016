import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox } from '../../../../model';
import { RootState } from '../../../../store';

export interface IBlockfacesGeoState {
  data: FeatureCollection;
  count: number;
}

const initialState: IBlockfacesGeoState = {
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'blockfacesGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {},
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.data = action.payload;
      state.count = action.payload.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.data = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const blockfacesGeoActions = slice.actions;

// Selectors
export const selectBlockfacesGeo = (state: RootState) => state.blockfacesGeo.data;
export const selectBlockfacesGeoCount = (state: RootState) => state.blockfacesGeo.count;

// Reducer
export const blockfacesGeoReducer = slice.reducer;
