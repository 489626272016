import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISignStatusCount, SavedSignLayer, SignStatus, TagFilter } from '../../../../model';
import { RootState } from '../../../../store';
import { ISignActivityStatusChange } from '../../../../services/signaling/signalr-processor';

type StatusCount = { [key in SignStatus]: number };

const getEmptyStatusCount = () => ({ Offline: 0, Inactive: 0, Active: 0 });

export interface ISignsLayerState {
  enabled: boolean;
  statusesFilter: TagFilter;
  count: number;
  statusCount: StatusCount;
}

const initialState: ISignsLayerState = {
  enabled: false,
  statusesFilter: new TagFilter(true),
  count: 0,
  statusCount: getEmptyStatusCount(),
};

const slice = createSlice({
  name: 'signsLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    setStatusesFilter(state, action: PayloadAction<TagFilter>) {
      state.statusesFilter = action.payload;
    },

    fetchStatusCount(state) {},
    fetchStatusCountSuccess(state, action: PayloadAction<ISignStatusCount[]>) {
      let count = 0;
      const statusCount: StatusCount = getEmptyStatusCount();
      for (const x of action.payload) {
        count += x.Count;
        statusCount[x.Status] = x.Count;
      }
      state.count = count;
      state.statusCount = statusCount;
    },
    fetchStatusCountFailed(state, action: PayloadAction<string>) {
      state.count = 0;
      state.statusCount = getEmptyStatusCount();
      console.log(action);
    },
    changeStatusCount(state, action: PayloadAction<ISignActivityStatusChange>) {
      if (action.payload.previousStatus !== undefined && action.payload.previousStatus !== action.payload.status) {
        const statusCount = { ...state.statusCount };
        statusCount[action.payload.status] = statusCount[action.payload.status] + 1;
        statusCount[action.payload.previousStatus] = Math.max(statusCount[action.payload.previousStatus] - 1, 0);
        const count = Object.values(statusCount).reduce((acc, current) => acc + current, 0);
        if (count === state.count) {
          state.statusCount = statusCount;
        }
      }
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedSignLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.statusesFilter = TagFilter.fromSavedValue(action.payload.statusesFilter);
      }
    },
  },
});

// Actions
export const signsLayerActions = slice.actions;

// Selectors
export const selectSignsLayer = (state: RootState) => state.signsLayer;
export const selectSignsEnabled = (state: RootState) => state.signsLayer.enabled;

// Reducer
export const signsLayerReducer = slice.reducer;
