import { IBlockface } from './blockface';
import { IMeter } from './meter';
import { Period } from './period';
import { ISpot } from './spot';
import { IStudyArea } from './study-area';
import { IZone } from './zone';

export enum OccupancySource {
  Sensors = 'Sensors',
  Camera = 'Camera',
  Transactional = 'Transactional',
}

export const getOccupancySources = (item: ISpot | IMeter | IZone | IBlockface | IStudyArea) => {
  const result = [];
  if (item.HasCameras) {
    result.push(OccupancySource.Camera);
  }

  if (item.HasSensors) {
    result.push(OccupancySource.Sensors);
  }

  if (item.HasTransactions) {
    result.push(OccupancySource.Transactional);
  }

  return result;
};

export const getOccupancySource = (sources: Array<OccupancySource>, source?: OccupancySource) => {
  if (!sources.length) {
    return undefined;
  }

  if (!source || sources.indexOf(source) < 0) {
    return sources[0];
  }

  return source;
};

export interface IOccupancyTrafficReport {
  OccupancyPercentage: number;
  OccupancyPrevPercentage: number;
  OccupancyDiffPercentage: number;

  MaxSpeed: number;
  CurrAvgSpeed: number;
  PervAvgSpeed: number;
  DiffPercentageAvgSpeed: number;

  Count: number;
  PrevCount: number;
  DiffCount: number;

  Turnover: number;
  PrevTurnover: number;
  DiffTurnover: number;

  DwellTime: number;
  PrevDwellTime: number;
  DiffDwellTime: number;

  Occupancy: number;
  PrevOccupancy: number;
  DiffOccupancy: number;

  OccupancyHourChartData: IHourItem[];
  OccupancyDayChartData: IDayItem[];

  TrafficHourChartData: IHourItem[];
  TrafficDayChartData: IDayItem[];
}

export interface IHourItem {
  Hour: number;
  Value: number;
}

export interface IDayItem {
  Day: number;
  Value: number;
}

export interface IOccupancyReportItem {
  Period: Period;

  PrevCount: number;
  Count: number;
  DiffCount: number;

  PrevOccupancyRate: number;
  OccupancyRate: number;
  DiffOccupancyRate: number;

  PrevDwellTime: number;
  DwellTime: number;
  DiffDwellTime: number;

  PrevTurnover: number;
  Turnover: number;
  DiffTurnover: number;
}
