import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICityOverviewReport } from '../../../model';
import { RootState } from '../../../store';

export interface IKeyMetricsState {
  enabled: boolean;
  loading: boolean;
  data: ICityOverviewReport | null;
}

const initialState: IKeyMetricsState = {
  enabled: false,
  loading: false,
  data: null,
};

const slice = createSlice({
  name: 'keyMetrics',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },
    toggleEnabled(state) {
      state.enabled = !state.enabled;
    },

    loadReport(state) {
      state.loading = true;
      state.data = null;
    },
    loadReportSuccess(state, action: PayloadAction<ICityOverviewReport | null>) {
      state.loading = false;
      state.data = action.payload;
    },
    loadReportFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      console.log(action);
    },
  },
});

// Actions
export const keyMetricsActions = slice.actions;

// Selectors
export const selectKeyMetricsState = (state: RootState) => state.keyMetrics;

// Reducer
export const keyMetricsReducer = slice.reducer;
