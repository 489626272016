import { getApi } from './api';
import { IArea, IAreaPolygon, IAreaType } from '../../model';
import { Utils } from '../../utils';

const BASE_URL = '/area';

const getArea = (id: number): Promise<IArea> => {
  return getApi()
    .get<IArea>(`${BASE_URL}/${id}`)
    .then((response) => response.data)
    .then((x) => {
      x.Name = Utils.capitalize(x.Name);
      return x;
    });
};

const getAllTypes = (): Promise<Array<IAreaType>> => {
  return getApi()
    .get<Array<IAreaType>>(`${BASE_URL}/types`)
    .then((response) => response.data || []);
};

const getLevelPolygons = (LevelId: number): Promise<Array<IAreaPolygon>> => {
  return getApi()
    .get<Array<IAreaPolygon>>(`${BASE_URL}/polygons/?level=${LevelId}`)
    .then((response) => response.data || [])
    .then((items) => {
      items.forEach((v) => (v.Name = Utils.capitalize(v.Name)));
      return items;
    });
};

export const areas = {
  get: getArea,
  getAllTypes: getAllTypes,
  getLevelPolygons: getLevelPolygons,
};
