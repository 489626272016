import { FunctionComponent } from 'react';
import { useRoutes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';

import { Breadcrumbs } from '../../components/reports/breadcrumbs/Breadcrumbs';
import { ReportIFrame } from '../../components/reports/ReportIFrame';
import { selectCity } from '../../features';
import { useAppSelector } from '../../store/hooks';
import { useReportBuilder } from '../../hooks';

const Forecasting: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const city = useAppSelector(selectCity);
  const reportsBuilder = useReportBuilder();
  const forecastingReport = reportsBuilder.getForecasting();

  if (!city || !forecastingReport) {
    return null;
  }

  const routes: Array<{ path: string; element?: JSX.Element; breadcrumb?: string }> = [
    {
      path: '/',
      element: <Navigate to='revenue' />,
    },
    {
      path: 'forecast', //for breadcrumbs
      breadcrumb: 'Forecast',
    },
  ];
  if (forecastingReport) {
    routes.push({
      path: 'revenue',
      element: <ReportIFrame url={forecastingReport} />,
      breadcrumb: 'Revenue',
    });
  }
  routes.push({ path: '*', element: <Navigate to='/forecast' replace /> });

  const ReportRouter = () => useRoutes(routes);

  const onSelect = (event: PanelBarSelectEventArguments) => {
    event.target.props.route && navigate(event.target.props.route);
  };

  const mapItems = (items: Array<{ name: string; url: string }>) =>
    items.map((x, idx) => <PanelBarItem key={idx} title={x.name} route={x.url} id={x.url} />);

  const setSelectedIndex = (pathName: string) => {
    const path = pathName.replace('/forecast/', '');
    return routes.find((item) => item.path === path)?.path;
  };

  const selected = setSelectedIndex(location.pathname);
  return (
    <div className='page report-page'>
      <div className='nav-sidebar'>
        {forecastingReport && (
          <PanelBar onSelect={onSelect} selected={selected}>
            <PanelBarItem title={'Forecast'} expanded={true}>
              {mapItems([
                {
                  name: 'Revenue',
                  url: 'revenue',
                },
              ])}
            </PanelBarItem>
          </PanelBar>
        )}
      </div>
      <div className='content'>
        <div className='breadcrumbs'>
          <Breadcrumbs routes={routes} omitRoot={false} />
        </div>
        <ReportRouter />
      </div>
    </div>
  );
};

export default Forecasting;
