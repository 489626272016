import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import { RangeSlider, RangeSliderChangeEvent, RangeSliderHandle } from '@progress/kendo-react-inputs';
import { dateUtils } from '../../../utils';

import styles from './DayTimeRangeSelector.module.scss';

const STEP = 30;
const MIN = 0;
const MAX = 24 * 60;

interface IProps {
  id: string;
  start: number;
  end: number;
  onChange: (start: number, end: number) => void;
  className?: string;
}

export const DayTimeRangeSelector: FunctionComponent<IProps> = ({ id, start, end, onChange, className }) => {
  const ref = useRef<RangeSliderHandle | null>(null);
  const [state, setState] = useState<{ start: number; end: number }>({ start, end });
  const [onSChange$] = useState(() => new Subject<{ start: number; end: number }>());

  const handleChange = (event: RangeSliderChangeEvent) => {
    const start = event.value.start;
    const end = event.value.end;
    let newStart = round(start);
    let newEnd = round(end);
    if (end - start < STEP) {
      if (state.start !== newStart) {
        newStart = state.start;
      } else {
        newEnd = state.end;
      }
    }
    setState({ start: newStart, end: newEnd });

    if (newStart !== start || newEnd !== end) onSChange$.next({ start: newStart, end: newEnd });
  };

  useEffect(() => {
    const subscription = onSChange$.pipe(debounceTime(100)).subscribe({
      next: (data) => {
        onChange(data.start, data.end);
      },
    });

    return () => subscription.unsubscribe();
  }, [onChange, onSChange$]);

  return (
    <div className={`${styles.sliderContainer} ${className || ''}`}>
      <RangeSlider
        ref={ref}
        id={id}
        className={styles.slider}
        defaultValue={{ start, end }}
        value={state}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
      ></RangeSlider>
      <span className={styles.legend}>
        <span className={styles.legendItem}>{dateUtils.toHoursString(start)}</span> &nbsp;{' '}
        <span className={styles.legendItem}>{dateUtils.toHoursString(end)}</span>
      </span>
    </div>
  );
};

function round(num: number, base = 30): number {
  return Math.round(num / base) * base;
}
