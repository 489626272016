import { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';

import { appSettingsEpic, citiesEpic } from '../features/common';
import {
  areaEpic,
  camerasEpic,
  curbSpacesEpic,
  enforcementsEpic,
  zoneHeatmapEpic,
  keymetricsEpic,
  loadingZonesEpic,
  metersEpic,
  offstreetZonesEpic,
  parkingDurationsEpic,
  revenueEpic,
  savedViewsEpic,
  sidebarEpic,
  signsEpic,
  streetObjectsEpic,
  zonesEpic,
  zoneObjectsEpic,
  customLayersEpic,
  blockfacesEpic,
  trafficEpic,
  blockfacesHeatmapEpic,
  trafficLayerEpic,
  studyAreasEpic,
  occupancyDataEpic,
  durationDataEpic,
  studyAreaHeatmapEpic,
  suggestedStreetsEpic,
  rppZonesEpic,
  rppAreasEpic,
} from '../features/map';
import { heatmapEpic } from '../features/map/layers/heatmaps/heatmap-epic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootEpic = combineEpics<AnyAction, AnyAction, any>(
  appSettingsEpic,
  citiesEpic,
  sidebarEpic,
  areaEpic,
  zonesEpic,
  rppZonesEpic,
  rppAreasEpic,
  zoneObjectsEpic,
  curbSpacesEpic,
  offstreetZonesEpic,
  metersEpic,
  camerasEpic,
  signsEpic,
  streetObjectsEpic,
  revenueEpic,
  zoneHeatmapEpic,
  enforcementsEpic,
  keymetricsEpic,
  parkingDurationsEpic,
  loadingZonesEpic,
  savedViewsEpic,
  customLayersEpic,
  blockfacesEpic,
  trafficEpic,
  trafficLayerEpic,
  occupancyDataEpic,
  heatmapEpic,
  blockfacesHeatmapEpic,
  studyAreasEpic,
  durationDataEpic,
  studyAreaHeatmapEpic,
  suggestedStreetsEpic,
);
