import { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

interface Props {
  requireAuth: boolean;
  redirectPath: string;
  children?: React.ReactElement;
}

const AuthenticatedRoute: FunctionComponent<Props> = ({ requireAuth, redirectPath, children }) => {
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();

  //NXCD-1147: redirect when msal completed auth check. Otherwise isAuthenticated is false.
  if (requireAuth && !isAuthenticated && msal.inProgress === InteractionStatus.None) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!requireAuth && isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default AuthenticatedRoute;
