import { LayerName } from '../model';
import { store } from '../store';

const cache: { [index: string]: string | undefined } = {};

const init = () =>
  store.subscribe(() => {
    _handleStateChange(LayerName.Signs, store.getState().signsLayer.enabled);
    _handleStateChange(LayerName.Cameras, store.getState().camerasLayer.enabled);
    _handleStateChange(LayerName.Spots, store.getState().curbSpacesLayer.enabled);
    _handleStateChange(LayerName.Meters, store.getState().metersLayer.enabled);
    _handleStateChange(LayerName.LoadingZones, store.getState().loadingZonesLayer.enabled);
  });

const getRevision = (layer: LayerName) => {
  return cache[layer];
};

const _handleStateChange = (layer: LayerName, enabled: boolean) => {
  if (!enabled) {
    cache[layer] = undefined;
    return;
  }

  if (cache[layer] === undefined) {
    cache[layer] = _generateRevision();
  }
};

const regenerateRevision = (layer: LayerName) => {
  cache[layer] = _generateRevision();
};

const _generateRevision = () => `${new Date().getTime()}`;

export const geoRevisionProcessor = {
  init,
  regenerateRevision,
  getRevision,
};
