import { Action, AnyAction } from 'redux';
import { combineEpics, StateObservable } from 'redux-observable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { catchError, concatMap, filter, from, map, mergeMap, Observable, of, switchMap, zip } from 'rxjs';
import { geoProcessor, signs, spots, zones } from '../../../../services';
import { RootState } from '../../../../store';
import { citiesActions } from '../../../common';
import { ISelectedSign, selectedSignsActions } from './selected-signs-slice';
import { signsGeoActions } from './signs-geo-slice';
import { signsLayerActions } from './signs-layer-slice';
import { geoUtils } from '../../../../utils';
import { mapStateActions } from '../../map-state';

const fetchSignsEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(signsGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadSigns(action.payload, action.payload.zoom, state$.value.signsLayer.statusesFilter).pipe(
        map((x) => signsGeoActions.fetchSuccess(x)),
        catchError((err) => of(signsGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const signSelectedEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedSignsActions.loadSign.match),
    concatMap((action) =>
      loadSign(action.payload.id, state$, action.payload.useCache).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              signsLayerActions.setEnabled(true),
              selectedSignsActions.loadSignSuccess({ sign: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findSignFeature(action.payload.id, state$) || {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: x.Position,
              },
              properties: {},
            };

            const center = geoUtils.findCenter(feature.geometry).coordinates;
            return of(
              signsLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedSignsActions.loadSignSuccess({ sign: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedSignsActions.loadSignFailed(err))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((action) => of(signsLayerActions.fetchStatusCount(), selectedSignsActions.collapsePopups())),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedSignsActions.closePopups()),
  );

const fetchStatusCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(signsLayerActions.fetchStatusCount.match),
    concatMap(() =>
      from(signs.getStatusCount()).pipe(
        map((x) => signsLayerActions.fetchStatusCountSuccess(x)),
        catchError((err) => of(signsLayerActions.fetchStatusCountFailed(err.message))),
      ),
    ),
  );

export const signsEpic = combineEpics(fetchSignsEpic, citySelectedEpic, signSelectedEpic, closePopupsEpic, fetchStatusCount);

const loadSign = (id: number, state: StateObservable<RootState>, useCache = true): Observable<ISelectedSign> => {
  if (useCache) {
    const existing = state.value.selectedSigns.selected.find((x) => x.id === id);
    if (existing && existing.entity) {
      return of(existing.entity);
    }
  }

  return from(signs.get(id)).pipe(
    switchMap((sign) =>
      zip(
        of(sign),
        from(signs.getSignState(id)),
        sign.ZoneId ? from(zones.get(sign.ZoneId)) : of(null),
        sign.SpotIds?.length ? from(spots.getSpotsStates(sign.SpotIds)) : of([]),
        from(signs.getSignTelemetry(id)),
      ),
    ),
    map((x) => ({
      ...x[0],
      state: x[1],
      //activeScreen: x[2],
      zone: x[2],
      spotsStates: x[3],
      telemetry: x[4],
      fetchedAt: new Date(),
    })),
  );
};

function findSignFeature(zoneId: number, state: StateObservable<RootState>): Feature<Geometry, GeoJsonProperties> | null {
  return state.value.signsGeo.data.features.find((x) => x.properties?.id === zoneId) || null;
}
