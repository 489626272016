import { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './SensorIcon.module.scss';

const cx = classNames.bind(styles);

export const SensorIcon: FC<{ className?: string }> = ({ className }) => {
  return <span className={cx('icon', className)}></span>;
};
