import { ILookupValue, IRppArea } from '../../model';
import { getApi } from './api';

const BASE_URL = '/rppArea';

const getRppArea = (id: string): Promise<IRppArea> => {
  return getApi()
    .get<IRppArea>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getRppZoneAreaNames = (zoneId: string): Promise<Array<ILookupValue<string>>> => {
  return getApi()
    .get<Array<ILookupValue<string>>>(`${BASE_URL}/by-zone/?id=${zoneId}`)
    .then((response) => response.data || []);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

export const rppAreas = {
  getRppArea,
  getRppZoneAreaNames,
  getCount,
};
