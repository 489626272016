import { FunctionComponent } from 'react';

import styles from './CurbSpaceLegendIcon.module.scss';

interface Props {
  policyType: number | undefined;
  className?: string;
}

export const CurbSpaceLegendIcon: FunctionComponent<Props> = ({ policyType, className }) => {
  return (
    <span className={`${styles.icon} policy${policyType?.toString() || ''} ${className || ''}`}></span>
  );
};
