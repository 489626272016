import { Action, AnyAction } from 'redux';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concatMap, filter, map, of } from 'rxjs';
import { RootState } from '../../../../store';
import { stringUtils } from '../../../../utils/string-utils';
import { mapStateActions } from '../../map-state';
import { selectedCustomLayersActions } from './selected-custom-layers-slice';

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedCustomLayersActions.closePopups()),
  );

const customLayerLoadEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedCustomLayersActions.loadCustomLayer.match),
    concatMap((action) => {
      const [layerId, featureId] = action.payload.id.split(':');
      const map = action.payload.map;

      const mapLayer = map.getLayer(layerId);
      if (!mapLayer) {
        return of(
          selectedCustomLayersActions.loadCustomLayerFailed({ id: action.payload.id, error: `Layer with id '${layerId}' not found` }),
        );
      }
      const features = map.querySourceFeatures('composite', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sourceLayer: (mapLayer as any).sourceLayer,
        filter: ['==', ['to-string', ['id']], featureId],
      });
      const feature = features && features.length ? features[0] : null;
      if (!feature) {
        return of(
          selectedCustomLayersActions.loadCustomLayerFailed({
            id: action.payload.id,
            error: `Feature with id '${featureId}' and Layer id '${layerId}' not found`,
          }),
        );
      }

      const layer = {
        FeatureId: feature.id,
        LayerId: layerId,
        LayerName: stringUtils.extractLayerName(layerId),
        Properties: feature?.properties,
      };

      return of(
        selectedCustomLayersActions.loadCustomLayerSuccess({
          layer: layer,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          position: action.payload.position!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initPosition: action.payload.initPosition!,
        }),
      );
    }),
  );

export const customLayersEpic = combineEpics(closePopupsEpic, customLayerLoadEpic);
