import { Position } from 'geojson';

import { IEntityBase } from './entity-base';
import { Period } from './period';

export enum ZoneType {
  Parking = 'Parking',
  CommercialLoading = 'CommercialLoading',
  Loading = 'Loading',
  NoParking = 'NoParking',
  NoStopping = 'NoStopping',
  NoUnloading = 'NoUnloading',
}

export interface IZone extends IEntityBase {
  Type: ZoneType;
  Name: string;
  Code?: string;
  Address: string;
  AreaId?: number;
  Positions: Array<Array<Position>>;
  SpotsCount: number;
  OccupiedSpotsCount?: number;
  PerformanceParking: boolean | null;
  HasCameras: boolean;
  HasSensors: boolean;
  HasTransactions: boolean;
}

export interface IRevenueItem {
  Period: Period;
  Current: number | null;
  Previous: number | null;
  Change: number | null;
}

export interface IKpiReportItem {
  PeriodId: Period;
  CurRevenue: number;
  CurRevenueChange: number;
  CurCount: number;
  CurCountChange: number;
  CurAvgPrice: number;
  CurAvgPriceChange: number;
  CurAvgDailyRevenue: number;
  CurAvgDailyRevenueChange: number;
}

export interface IZoneRevenueReportItem {
  CurrentTotal?: number;
  PreviousTotal?: number;
  TotalChange?: number;
  CurrentAverage?: number;
  PreviousAverage?: number;
  CurrentAverageChange?: number;
  CurrentDailyAverage?: number;
  PreviousDailyAverage?: number;
  DailyAverageChange?: number;
  YearlyAverage?: number;
  CurrentCount?: number;
  PreviousCount?: number;
  CountChange?: number;
  CurrentWeighted?: number;
  PreviousWeighted?: number;
  WeightedChange?: number;
  PaymentMethodRevenueChartData: Array<{ Id: number; Revenue: number }>;
  VendorRevenueChartData: Array<{ Name: string; Revenue: number }>;
  PaymentMethodCountChartData: Array<{ Id: number; Count: number }>;
  VendorCountChartData: Array<{ Name: string; Count: number }>;
}
