import { WeekDay } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/Duration';

export interface IDurationHeatmapData {
  Id: number;
  AvgDuration: number;
  NormalizedAvgDuration: number;
}

const getStudyAreaDurationHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IDurationHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IDurationHeatmapData>>(
      `${BASE_URL}/study-area/heatmap-data?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
        period[1],
      )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

export const durationData = {
  getStudyAreaDurationHeatmapData,
};
