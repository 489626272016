import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedLoadingZone, loadingZone } from '../../../../model';
import { ILoadingZoneOccupancyCountChangeDto } from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedLoadingZoneState {
  selected: ISelectedEntity<ISelectedLoadingZone>[];
  current: number | null;
}

const initialState: ISelectedLoadingZoneState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedLoadingZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.LoadingZones,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedLoadingZonesActions.loadZone(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.LoadingZones, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.LoadingZones);
    },
    loadZone(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadZoneSuccess(state, action: PayloadAction<{ zone: ISelectedLoadingZone; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.zone.Id,
        action.payload.zone,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadZoneFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.LoadingZones, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.LoadingZones);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.LoadingZones, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedLoadingZone | null>) {
      state.current = action.payload?.Id || null;
    },
    changeOccupiedCount(state, action: PayloadAction<ILoadingZoneOccupancyCountChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.Id === action.payload.id) {
          item.entity.OccupiedSpotsCount = loadingZone.occupancyCalculator(item.entity.SpotsCount, action.payload.count).occupiedSpotsCount;
        }
      });
    },
  },
});

// Actions
export const selectedLoadingZonesActions = slice.actions;

// Selectors
export const selectedLoadingZones = (state: RootState) => state.selectedLoadingZones;
export const currentLoadingZone = (state: RootState) =>
  state.selectedLoadingZones.selected.find((x) => x.id === state.selectedLoadingZones.current);

// Reducer
export const selectedLoadingZonesReducer = slice.reducer;
