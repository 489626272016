import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SidebarName } from '../../../model';
import { RootState } from '../../../store';

export interface ISidebarState {
  selected: SidebarName | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

const initialState: ISidebarState = {
  selected: null,
  data: null,
};

const slice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelected(state, action: PayloadAction<{ name: SidebarName; data?: any }>) {
      if (state.selected !== action.payload.name) {
        state.selected = action.payload.name;
        state.data = action.payload.data;
      } else if (state.selected === action.payload.name && state.data !== action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.selected = null;
        state.data = null;
      }
    },
    clearSelected(state) {
      state.selected = null;
    },
  },
});

// Actions
export const sidebarActions = slice.actions;

// Selectors
export const selectSidebarState = (state: RootState) => state.sidebar.selected;

// Reducer
export const sidebarReducer = slice.reducer;
