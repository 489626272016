import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

export interface IStreetObjectsLevelGeoState {
  loading: boolean;
  signs: FeatureCollection;
  signsCount: number;
  curbs: FeatureCollection;
  curbsCount: number;
  curbCuts: FeatureCollection;
  curbCutsCount: number;
  fireHydrants: FeatureCollection;
  fireHydrantsCount: number;
  poles: FeatureCollection;
  polesCount: number;
}

const initialState: IStreetObjectsLevelGeoState = {
  loading: false,
  signs: EMPTY_FEATURE_COLLECTION,
  signsCount: 0,
  curbs: EMPTY_FEATURE_COLLECTION,
  curbsCount: 0,
  curbCuts: EMPTY_FEATURE_COLLECTION,
  curbCutsCount: 0,
  fireHydrants: EMPTY_FEATURE_COLLECTION,
  fireHydrantsCount: 0,
  poles: EMPTY_FEATURE_COLLECTION,
  polesCount: 0,
};

const slice = createSlice({
  name: 'streetObjectsGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ cityCode: string; types: TagFilter }>) {
      state.loading = true;
    },
    fetchSuccess(
      state,
      action: PayloadAction<{
        signs: FeatureCollection;
        curbs: FeatureCollection;
        curbCuts: FeatureCollection;
        fireHydrants: FeatureCollection;
        poles: FeatureCollection;
      }>,
    ) {
      state.loading = false;
      state.signs = action.payload.signs;
      state.signsCount = action.payload.signs.features.length;
      state.curbs = action.payload.curbs;
      state.curbsCount = action.payload.curbs.features.length;
      state.curbCuts = action.payload.curbCuts;
      state.curbCutsCount = action.payload.curbCuts.features.length;
      state.fireHydrants = action.payload.fireHydrants;
      state.fireHydrantsCount = action.payload.fireHydrants.features.length;
      state.poles = action.payload.poles;
      state.polesCount = action.payload.poles.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.signs = EMPTY_FEATURE_COLLECTION;
      state.signsCount = 0;
      state.curbs = EMPTY_FEATURE_COLLECTION;
      state.curbsCount = 0;
      state.curbCuts = EMPTY_FEATURE_COLLECTION;
      state.curbCutsCount = 0;
      state.fireHydrants = EMPTY_FEATURE_COLLECTION;
      state.fireHydrantsCount = 0;
      state.poles = EMPTY_FEATURE_COLLECTION;
      state.polesCount = 0;
      console.log(action);
    },
  },
});

// Actions
export const streetObjectsGeoActions = slice.actions;

// Selectors
export const selectStreetObjectsGeo = (state: RootState) => state.streetObjectsGeo;

// Reducer
export const streetObjectsGeoReducer = slice.reducer;
