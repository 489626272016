import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Point } from 'geojson';
import { LngLat } from 'mapbox-gl';

import { IMeterState } from '../../../../model';
import { RootState } from '../../../../store';

export interface IMetersEditingState {
  isEditing: boolean;
  isSidebarOpen: boolean;

  meters: { [id: number]: IMeterState };
  meter: IMeterState | null;

  saving: boolean;
}

const initialState: IMetersEditingState = {
  isEditing: false,
  isSidebarOpen: false,

  meters: {},
  meter: null,

  saving: false,
};

const slice = createSlice({
  name: 'metersEditing',
  initialState: initialState,
  reducers: {
    clear(state) {
      clear(state);
    },
    setEditing(state, action: PayloadAction<{ edit: boolean; canEdit?: boolean }>) {
      const edit = action.payload.edit;
      if (edit) {
        if (action.payload.canEdit === undefined) {
          throw new Error('canEdit parameter is not provided');
        } else if (!action.payload.canEdit) {
          return;
        }
      }

      if (edit !== state.isEditing) {
        state.isEditing = edit;
        clear(state);
      }

      if (!edit && state.isSidebarOpen) {
        state.isSidebarOpen = false;
      }
    },
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
    moveMeter(state, action: PayloadAction<{ meterFeature: Feature<Point, GeoJsonProperties>; position: LngLat | [number, number] }>) {},
    moveMeterSuccess(state, action: PayloadAction<{ meter: IMeterState }>) {
      const meter = action.payload.meter;
      state.meter = meter;
      state.meters[meter.id] = meter;
    },
    moveMeterFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    removeMeter(state, action: PayloadAction<number>) {
      const meters = state.meters;
      if (meters[action.payload]) {
        delete meters[action.payload];
        state.meters = meters;
      }
    },
    finishEditing(state) {
      state.meter = null;
    },
    setMeterSelected(state, action: PayloadAction<{ id: number; selected: boolean }>) {
      const meters = state.meters;
      const current = meters[action.payload.id];
      const selected = action.payload.selected;

      if (!!current && current.isSelected !== selected) {
        meters[action.payload.id] = { ...current, ...{ isSelected: selected } };
      }
    },
    setAllMetersSelected(state, action: PayloadAction<boolean>) {
      const meters = state.meters;

      Object.keys(state.meters).forEach((idStr, idx) => {
        const id = parseInt(idStr);
        const meter = meters[id];
        meters[id] = { ...meter, ...{ isSelected: action.payload } };
      });
    },

    saveMeters(state, action: PayloadAction<IMeterState[]>) {
      state.saving = true;
    },
    saveMetersSuccess(state) {
      state.saving = false;
      clear(state);
      state.isEditing = false;
      state.isSidebarOpen = false;
    },
    saveMetersFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },
  },
});

// Actions
export const metersEditingActions = slice.actions;

// Selectors
export const selectMetersEditing = (state: RootState) => state.metersEditing;
export const selectMetersEditing_meters = (state: RootState) => state.metersEditing.meters;
export const selectMetersEditing_meter = (state: RootState) => state.metersEditing.meter;

// Reducer
export const metersEditingReducer = slice.reducer;

export function calculateMetersCount(meters: Map<number, IMeterState>): number {
  return meters.size;
}

function clear(state: IMetersEditingState) {
  state.meters = {};
  state.meter = null;
}

export function isMeterEdited(id: number, state: RootState): boolean {
  return state.metersEditing.isEditing && state.metersEditing.meter?.id === id;
}
