import { FC } from 'react';
import classNames from 'classnames/bind';
import { SuggestedStreetType } from '../../../../model';

import styles from './SuggestedStreetIcon.module.scss';

const cx = classNames.bind(styles);

interface Props {
  type: SuggestedStreetType;
  className?: string;
}

export const SuggestedStreetIcon: FC<Props> = ({ type, className }) => {
  return <span className={cx(className, 'icon', `type-${type}`)}></span>;
};
