import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { durationData } from '../../../../services/api/duration';
import { durationDataActions } from './duration-slice';

const fetchStudyAreaDurationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(durationDataActions.fetchStudyAreaDurationData.match),
    switchMap((action) =>
      from(
        durationData.getStudyAreaDurationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => durationDataActions.fetchStudyAreaDurationDataSuccess(x)),
        catchError((err) => of(durationDataActions.fetchStudyAreaDurationDataFailed(err.message))),
      ),
    ),
  );

export const durationDataEpic = combineEpics(fetchStudyAreaDurationDataEpic);
