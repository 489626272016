import { useCallback, useContext, useEffect } from 'react';
import { MapContext } from 'react-map-gl/dist/esm/components/map';

export const useMapLayerHover = (layers: Array<string>) => {
    const { map } = useContext(MapContext);

    const setPointerCursor = useCallback(() => {
        map.getCanvas().style.cursor = 'pointer';
    }, [map]);

    const setDefaultCursor = useCallback(() => {
        map.getCanvas().style.cursor = 'default';
    }, [map]);

    useEffect(
        () => {
            layers.forEach((x) => map.on('mouseenter', x, setPointerCursor));
            layers.forEach((x) => map.on('mouseleave', x, setDefaultCursor));

            return () => {
                layers.forEach((x) => map.off('mouseenter', x, setPointerCursor));
                layers.forEach((x) => map.off('mouseleave', x, setDefaultCursor));
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [map, ...layers],
    );
};