import { ISavedView } from '../../model';
import { getApi } from './api';

const BASE_URL = '/savedView';

const get = () =>
  getApi()
    .get<ISavedView[]>(BASE_URL)
    .then((x) => x.data || []);

const save = (savedViews: ISavedView[]) => getApi().post<ISavedView[]>(BASE_URL, savedViews);

export const savedViews = {
  get,
  save,
};
