import { FunctionComponent } from 'react';

import styles from './PinButton.module.scss';
import pin from './pin.svg';
import unpin from './unpin.svg';

import { Button } from '@progress/kendo-react-buttons';

interface Props {
  className?: string;
  pinned: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}

export const PinButton: FunctionComponent<Props> = ({ className, pinned, onClick }) => (
  <Button
    className={`buttons-container-button ${styles.button} ${className || ''}`}
    fillMode='flat'
    size='small'
    imageUrl={pinned ? unpin : pin}
    onClick={onClick}
  ></Button>
);
