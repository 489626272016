import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../store';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TagFilter, TilesBoundingBox } from '../../../../model';

export interface ICamerasLevelGeoState {
  loading: boolean;
  data: FeatureCollection;
  count: number;
}

const initialState: ICamerasLevelGeoState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'camerasGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ box: TilesBoundingBox; statuses: TagFilter }>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.loading = false;
      state.data = action.payload;
      state.count = action.payload.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const camerasGeoActions = slice.actions;

// Selectors
export const selectCamerasGeoLoading = (state: RootState) => state.camerasGeo.loading;
export const selectCamerasGeo = (state: RootState) => state.camerasGeo.data;
export const selectCamerasGeoCount = (state: RootState) => state.camerasGeo.count;

// Reducer
export const camerasGeoReducer = slice.reducer;
