import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISign, ISignState, ISignTelemetry, ISpotState, IZone } from '../../../../model';
import { ISignActivityStatusChangeDto, ISpotOccupancyStatusChangeDto } from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedSign extends ISign {
  state: ISignState | null;
  zone: IZone | null;
  spotsStates: ISpotState[];
  telemetry: ISignTelemetry | null;
  fetchedAt: Date;
}

export interface ISelectedSignState {
  selected: ISelectedEntity<ISelectedSign>[];
  current: number | null;
}

const initialState: ISelectedSignState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedSigns',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Signs,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedSignsActions.loadSign(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number, position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Signs, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Signs);
    },
    loadSign(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null; useCache?: boolean }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadSignSuccess(state, action: PayloadAction<{ sign: ISelectedSign; position: Position;  initPosition?: Position; }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.sign.Id,
        action.payload.sign,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadSignFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Signs, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Signs);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Signs, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedSign | null>) {
      state.current = action.payload?.Id || null;
    },
    changeSignState(state, action: PayloadAction<ISignActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.Id === action.payload.id && item.entity.state) {
          item.entity.state.Status = action.payload.status;
          item.entity.state.LastUpdated = action.payload.lastUpdated;
        }
      });
    },
    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },
  },
});

// Actions
export const selectedSignsActions = slice.actions;

// Selectors
export const selectedSigns = (state: RootState) => state.selectedSigns;
export const currentSign = (state: RootState) => state.selectedSigns.selected.find((x) => x.id === state.selectedSigns.current);

// Reducer
export const selectedSignsReducer = slice.reducer;
