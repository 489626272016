import { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import { catchError, filter, map, Observable, of, switchMap } from 'rxjs';

import { geoProcessor } from '../../../../services';
import { parkingDurationsZoneGeoActions } from './parking-durations-zone-geo-slice';
import { parkingDurationsBlockfaceGeoActions } from './parking-durations-blockface-geo-slice';

const fetchParkingDurationsZoneGeoEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(parkingDurationsZoneGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadZoneParkingDurations(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => parkingDurationsZoneGeoActions.fetchSuccess(x)),
          catchError((err) => of(parkingDurationsZoneGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchParkingDurationsBlockfaceGeoEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(parkingDurationsBlockfaceGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadBlockfacesDuration(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => parkingDurationsBlockfaceGeoActions.fetchSuccess(x)),
          catchError((err) => of(parkingDurationsBlockfaceGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

export const parkingDurationsEpic = combineEpics(fetchParkingDurationsZoneGeoEpic, fetchParkingDurationsBlockfaceGeoEpic);
