// eslint-disable-next-line @typescript-eslint/no-explicit-any
const format = (value: string | null | undefined, ...args: any[]) => {
  return value
    ? value.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      })
    : value;
};

const extractLayerName = (id: string) => {
  const underscoreIndex = id.lastIndexOf('_');
  if (underscoreIndex <= -1 || underscoreIndex === id.length - 1) {
    return '';
  }

  return id.substring(underscoreIndex + 1);
};

export const stringUtils = {
  format,
  extractLayerName,
};
