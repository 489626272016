import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox } from '../../../../model';

import { RootState } from '../../../../store';

export interface IZonesLevelPolygonsState {
  data: FeatureCollection;
  count: number;
  limitsData: FeatureCollection;
}

const initialState: IZonesLevelPolygonsState = {
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
  limitsData: EMPTY_FEATURE_COLLECTION,
};

const slice = createSlice({
  name: 'zonesGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {
      state.count = 0;
    },
    fetchSuccess(state, action: PayloadAction<{ data: FeatureCollection; limits: FeatureCollection }>) {
      state.data = action.payload.data;
      state.limitsData = action.payload.limits;
      const uniqueZones = new Set(action.payload.data.features.map((x) => x.properties?.id));
      state.count = uniqueZones.size;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.data = EMPTY_FEATURE_COLLECTION;
      state.limitsData = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },
  },
});

// Actions
export const zonesGeoActions = slice.actions;

// Selectors
export const selectZonesGeo = (state: RootState) => state.zonesGeo.data;
export const selectZonesGeoLimits = (state: RootState) => state.zonesGeo.limitsData;
export const selectZonesGeoCount = (state: RootState) => state.zonesGeo.count;

// Reducer
export const zonesGeoReducer = slice.reducer;
