const toUiString = (value: number | null | undefined, fractionDigits = 2, defaultValue = 0) =>
  (value || defaultValue).toLocaleString(undefined, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });

const diffPercentage = (current: number, previous: number) =>
  previous !== 0 ? ((current - previous) * 100) / previous : current === 0 ? 0 : 100;

const splitIntoSegments = (data: Array<number>): Array<number | [number, number]> => {
  const sorted = [...data].sort((a, b) => a - b);
  const result = [];
  let segment: [number, number?] = [0];

  sorted.forEach((val, index, arr) => {
    if (index === 0) {
      segment[0] = val;
      return;
    }

    const diff = val - arr[index - 1];
    if (diff === 0 || diff === 1) {
      segment[1] = val;
      return;
    }

    result.push(segment[1] === undefined ? segment[0] : (segment as [number, number]));

    segment = [val];
  });

  result.push(segment[1] === undefined ? segment[0] : (segment as [number, number]));

  return result;
};

export const numberUtils = {
  toUiString,
  diffPercentage,
  splitIntoSegments,
};
