import { LngLat } from 'mapbox-gl';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Position } from 'geojson';

import styles from './GeoCoordinatesEditor.module.scss';
import { notifications } from '../../../services';

interface Props {
  className?: string;
  coordinates?: [number, number];
  lngLat?: LngLat;
  position?: Position;
  onApply: (coordinates: [number, number]) => void;
  onCancel: () => void;
}

export const GeoCoordinatesEditor: FunctionComponent<Props> = ({
  className,
  coordinates,
  lngLat,
  position,
  onApply,
  onCancel,
}) => {
  const [lng, setLng] = useState<string>('');
  const [lat, setLat] = useState<string>('');
  const [lngError, setLngError] = useState<string | null>(null);
  const [latError, setLatError] = useState<string | null>(null);

  const getCoords = useCallback((): [number, number] => {
    if (coordinates) {
      return coordinates;
    } else if (lngLat) {
      return [lngLat.lng, lngLat.lat];
    } else if (position) {
      return [position[0], position[1]];
    } else {
      throw new Error("Coordinates data isn't defined");
    }
  }, [coordinates, lngLat, position]);

  useEffect(() => {
    const coords = getCoords();
    setLng(coords[0].toString());
    setLat(coords[1].toString());
  }, [getCoords]);

  const onLngChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(e.target.value);
    if (!isNaN(val) && !(val < -180 || val > 180)) {
      setLngError(null);
    } else {
      setLngError('Invalid');
    }

    setLng(e.target.value);
  };

  const onLatChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(e.target.value);
    if (!isNaN(val) && !(val < -90 || val > 90)) {
      setLatError(null);
    } else {
      setLatError('Invalid');
    }

    setLat(e.target.value);
  };

  const save = () => {
    if (lngError || latError) {
      setTimeout(() => notifications.error('Please enter the valid value'), 1);
      return;
    }

    if (lng && lat) onApply([parseFloat(lng), parseFloat(lat)]);
  };

  return (
    <form className={`${styles.form} ${className || ''}`}>
      <input
        required
        type='text'
        className={`${styles.formControl} ${lngError ? styles.invalid : ''}`}
        value={lng}
        onChange={onLngChanged}
      />
      <input
        required
        type='text'
        className={`${styles.formControl} ${latError ? styles.invalid : ''}`}
        value={lat}
        onChange={onLatChanged}
      />

      <button className={styles.btn} onClick={save}>
        <i className={`k-icon k-i-check ${styles.ok}`}></i>
      </button>
      <button className={styles.btn} onClick={() => onCancel && onCancel()}>
        <i className={`k-icon k-i-x ${styles.cancel}`}></i>
      </button>
    </form>
  );
};
