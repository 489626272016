import { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './CurbSpacePolicyIcon.module.scss';

const cx = classNames.bind(styles);

interface Props {
  policy?: number;
  className?: string;
}

export const CurbSpacePolicyIcon: FC<Props> = ({ policy, className }) => {
  return <span className={cx('icon', `policy${policy ?? 1}`, className)}></span>;
};
