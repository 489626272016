import { FunctionComponent } from 'react';

import styles from './spinner.module.scss';

interface Props {
  backdrop?: boolean | undefined;
}

export const Spinner: FunctionComponent<Props> = (props) => (
  <div className={`spinner ${styles.wrapper} ${props.backdrop && styles.backdrop}`}>
    <div className={styles.spinner}></div>
  </div>
);
