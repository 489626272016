import { FeatureCollection } from 'geojson';

import { IArea, IOffstreetZoneName, IRevenueItem } from '../../api';
import { ILookupValue } from '../../api/search';

export interface IAreaLevel {
  id: number;
  name: string;
  level: number;
  revenueEnabled: boolean;
  order: number;
}

export interface ISelectedArea extends IArea {
  typeName: string;
  typeLevel: number;
  zoneNames: Array<ILookupValue>;
  revenue: IRevenueItem[];
  offstreetZoneNames: Array<IOffstreetZoneName>;
}

export interface AreaFeatureCollection extends FeatureCollection {
  level: number;
}

export const AREA_LEVEL_NONE = {
  id: -1,
  name: 'None',
  level: -1,
  revenueEnabled: false,
  order: -1,
};
