import { FunctionComponent } from 'react';
import { StreetObjectType } from '../../../../model';

import styles from './StreetObjectIcon.module.scss';

interface Props {
  className?: string;
  type: StreetObjectType | undefined | null;
}

export const StreetObjectIcon: FunctionComponent<Props> = ({ className, type }) => {
  return <span className={`${className || ''} ${styles.icon} type-${type || ''}`}></span>;
};
