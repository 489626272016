export enum AlertType {
  LoadingZonePayment = 'loadingZonePayment',
}

export interface IAlert {
  id: number;
  type: AlertType;
  timestamp: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}
