import { getApi } from './api';
import { IOffstreetCount, IOffstreetVendor, IOffstreetZone, IOffstreetZoneName } from '../../model';

const BASE_URL = '/offstreetzone';

const getZone = async (id: number): Promise<IOffstreetZone> => {
  return await getApi()
    .get<IOffstreetZone>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getVendors = async (): Promise<Array<IOffstreetVendor>> => {
  return await getApi()
    .get<Array<IOffstreetVendor>>(`${BASE_URL}/vendors`)
    .then((response) => response.data || []);
};

const getNamesByAreaId = async (areaId: number): Promise<Array<IOffstreetZoneName>> => {
  return await getApi()
    .get<Array<IOffstreetZoneName>>(`${BASE_URL}/by-area/?id=${areaId}`)
    .then((response) => response.data || []);
};

const getCount = async (): Promise<IOffstreetCount[]> => {
  return getApi()
    .get<IOffstreetCount[]>(`${BASE_URL}/count`)
    .then((response) => response.data);
};

export const offstreetZones = {
  get: getZone,
  getVendors,
  getNamesByAreaId,
  getCount,
};
