import { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { createRoot } from 'react-dom/client';

let currentPopup: Popup | undefined = undefined;

export const useMapToolTip = (
  getToolTip: (event: MapLayerMouseEvent) => string | ReactElement | undefined,
  layerEnabled: () => boolean,
  ...layers: string[]
) => {
  const { map } = useContext(MapContext);

  const popup = useMemo(
    () =>
      new Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    [],
  );

  if (!layerEnabled()) {
    popup.remove();
    if (currentPopup === popup) {
      currentPopup = undefined;
    }
  }

  const showTooltip = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!layerEnabled()) {
        return;
      }

      let contentStr: string | null = null;
      let contentNode: Element | null = null;

      const content = getToolTip(event);
      if (!content) {
        return;
      }

      if ((content as ReactElement)?.type) {
        contentNode = document.createElement('div');
        const root = createRoot(contentNode);
        root.render(content);
      } else {
        contentStr = `<span style='margin-left: 5px; margin-right:5px;'>${content}</span>`;
      }

      if (!contentStr && !contentNode) {
        return;
      }

      if (currentPopup) {
        currentPopup.remove();
        currentPopup = undefined;
      }

      popup.setLngLat(event.lngLat);

      if (contentStr) {
        popup.setHTML(contentStr);
      } else if (contentNode) {
        popup.setDOMContent(contentNode);
      }

      popup.addTo(map.getMap());
      currentPopup = popup;
    },
    [map, popup, getToolTip, layerEnabled],
  );

  const hideTooltip = useCallback(() => {
    popup.remove();
    if (currentPopup === popup) {
      currentPopup = undefined;
    }
  }, [popup]);

  useEffect(() => {
    layers.forEach((x) => {
      map.on('mouseenter', x, showTooltip);
      map.on('mouseleave', x, hideTooltip);
      map.on('zoom', hideTooltip);
    });

    return () => {
      layers.forEach((x) => {
        map.off('mouseenter', x, showTooltip);
        map.off('mouseleave', x, hideTooltip);
        map.on('zoom', hideTooltip);
      });
    };
  }, [map, layers, showTooltip, hideTooltip]);
};
