import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../constants';

export type GeoJSON = GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry>;

const getStreetObjects = (cityCode: string, fileName: string): Promise<FeatureCollection> => {
  return fetch(`/street-objects/${cityCode.toLowerCase()}/${fileName}`)
    .then((res) => res.json())
    .catch((_) => EMPTY_FEATURE_COLLECTION);
};

const listHydrants = (cityCode: string) => getStreetObjects(cityCode, 'fire-hydrants.geojson');

const listSigns = (cityCode: string) => getStreetObjects(cityCode, 'signs.geojson');

const listCurbs = (cityCode: string) => getStreetObjects(cityCode, 'curbs.geojson');

const listCurbCuts = (cityCode: string) => getStreetObjects(cityCode, 'curb-cuts.geojson');

const listPoles = (cityCode: string) => getStreetObjects(cityCode, 'poles.geojson');

export const streetObjects = {
  listHydrants,
  listSigns,
  listCurbs,
  listCurbCuts,
  listPoles,
};
