import { FunctionComponent } from 'react';

import styles from './OffstreetZoneIcon.module.scss';

interface Props {
  className?: string;
}

export const OffstreetZoneIcon: FunctionComponent<Props> = ({ className }) => {
  return <span className={`${className || ''} ${styles.icon}`}></span>;
};
