import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import { IOffstreetZoneRevenueReportItem, ISelectedOffstreetZoneRevenue } from '../../../../model';
import { RootState, store } from '../../../../store';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedRevenueOffstreetZoneState {
  selected: ISelectedEntity<ISelectedOffstreetZoneRevenue>[];
  current: number | null;
}

const initialState: ISelectedRevenueOffstreetZoneState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRevenueOffstreetZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.RevenueOffstreetZones,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedRevenueOffstreetZonesActions.loadRevenueZone(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.RevenueOffstreetZones, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.RevenueOffstreetZones);
    },
    loadRevenueZone(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadRevenueZoneSuccess(
      state,
      action: PayloadAction<{ zone: ISelectedOffstreetZoneRevenue; position: Position; initPosition?: Position }>,
    ) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.zone.Id,
        action.payload.zone,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadRevenueZoneFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    fetchRevenueReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IOffstreetZoneRevenueReportItem | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.revenueReport = action.payload.report;
    },
    fetchRevenueReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.RevenueOffstreetZones, action.payload);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.RevenueOffstreetZones, action.payload);
    },
  },
});

// Actions
export const selectedRevenueOffstreetZonesActions = slice.actions;

// Selectors
export const selectedRevenueOffstreetZones = (state: RootState) => state.selectedRevenueOffstreetZones;
export const currentRevenueOffstreetZone = (state: RootState) =>
  state.selectedRevenueOffstreetZones.selected.find((x) => x.id === state.selectedRevenueOffstreetZones.current);

// Reducer
export const selectedRevenueOffstreetZonesReducer = slice.reducer;
