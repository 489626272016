import { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { selectCities } from '../../features';

interface Props {
  requireAuth: boolean;
  redirectPath: string;
  children?: React.ReactElement;
}

const AuthorizedRoute: FunctionComponent<Props> = ({ requireAuth, redirectPath, children }) => {
  const cities = useAppSelector(selectCities);

  if (requireAuth && cities.cities.length === 0 && cities.initialized) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!requireAuth && cities.cities.length > 0) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default AuthorizedRoute;
