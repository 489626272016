import { ILookupValue, IPolicy, IRppZone } from '../../model';
import { getApi } from './api';

const BASE_URL = '/rppZone';

const getRppZone = (id: string): Promise<IRppZone> => {
  return getApi()
    .get<IRppZone>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getRppAreaZoneNames = (areaId: string): Promise<Array<ILookupValue<string>>> => {
  return getApi()
    .get<Array<ILookupValue<string>>>(`${BASE_URL}/by-area/?id=${areaId}`)
    .then((response) => response.data || []);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

const getPolicies = (zoneId: string): Promise<IPolicy[]> => {
  return getApi()
    .get<IPolicy[]>(`${BASE_URL}/${zoneId}/policy`)
    .then((response) => response.data || []);
};

export const rppZones = {
  getRppZone,
  getRppAreaZoneNames,
  getCount,
  getPolicies,
};
