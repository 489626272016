import { Action } from 'redux';
import { combineEpics } from 'redux-observable';

import { catchError, concatMap, filter, from, map, mergeMap, Observable, of, switchMap } from 'rxjs';

import { IViolationGroup, VIOLATION_GROUP_ALL } from '../../../../model';
import { enforcements } from '../../../../services';
import { citiesActions } from '../../../common';
import { heatmapsFilterActions } from '../heatmaps';
import { violationDataActions } from './enforcements-slice';

const violationGroupsAddAll = (groups: IViolationGroup[]) => {
  return groups.length > 0 ? [{ Id: VIOLATION_GROUP_ALL, Name: 'All' }, ...groups] : [];
};

const citySelectedEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(heatmapsFilterActions.fetchEnforcementGroups(), violationDataActions.fetchHasSpotViolation())),
  );

const fetchEnforcementGroupsEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(heatmapsFilterActions.fetchEnforcementGroups.match),
    concatMap((_) =>
      from(enforcements.getViolationGroups()).pipe(
        map((x) => violationGroupsAddAll(x)),
        map((x) => heatmapsFilterActions.fetchEnforcementGroupsSuccess(x)),
        catchError((err) => of(heatmapsFilterActions.fetchEnforcementGroupsFailed(err.message))),
      ),
    ),
  );

const fetchHasSpotViolationEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchHasSpotViolation.match),
    switchMap((action) =>
      from(enforcements.spotViolationHeatmapExists()).pipe(
        map((x) => violationDataActions.fetchHasSpotViolationSuccess(x)),
        catchError((err) => of(violationDataActions.fetchHasSpotViolationFailed(err.message))),
      ),
    ),
  );

const fetchStudyAreaViolationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchStudyAreaViolationData.match),
    switchMap((action) =>
      from(
        enforcements.getStudyAreaViolationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
          action.payload.groupId,
        ),
      ).pipe(
        map((x) => violationDataActions.fetchStudyAreaViolationDataSuccess(x)),
        catchError((err) => of(violationDataActions.fetchStudyAreaViolationDataFailed(err.message))),
      ),
    ),
  );

const fetchBlockfaceViolationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchBlockfaceViolationData.match),
    switchMap((action) =>
      from(
        enforcements.getBlockfaceViolationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
          action.payload.groupId,
        ),
      ).pipe(
        map((x) => violationDataActions.fetchBlockfaceViolationDataSuccess(x)),
        catchError((err) => of(violationDataActions.fetchBlockfaceViolationDataFailed(err.message))),
      ),
    ),
  );

const fetchZoneViolationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchZoneViolationData.match),
    switchMap((action) =>
      from(
        enforcements.getZoneViolationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
          action.payload.groupId,
        ),
      ).pipe(
        map((x) => violationDataActions.fetchZoneViolationDataSuccess(x)),
        catchError((err) => of(violationDataActions.fetchZoneViolationDataFailed(err.message))),
      ),
    ),
  );

const fetchMeterViolationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchMeterViolationData.match),
    switchMap((action) =>
      from(
        enforcements.getMeterViolationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
          action.payload.groupId,
        ),
      ).pipe(
        map((x) => violationDataActions.fetchMeterViolationDataSuccess(x)),
        catchError((err) => of(violationDataActions.fetchMeterViolationDataFailed(err.message))),
      ),
    ),
  );

const fetchSpotViolationDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(violationDataActions.fetchSpotViolationData.match),
    switchMap((action) =>
      from(
        enforcements.getSpotViolationHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
          action.payload.groupId,
        ),
      ).pipe(
        map((x) => violationDataActions.fetchSpotViolationDataSuccess(x)),
        catchError((err) => of(violationDataActions.fetchSpotViolationDataFailed(err.message))),
      ),
    ),
  );

export const enforcementsEpic = combineEpics(
  citySelectedEpic,
  fetchStudyAreaViolationDataEpic,
  fetchBlockfaceViolationDataEpic,
  fetchZoneViolationDataEpic,
  fetchMeterViolationDataEpic,
  fetchSpotViolationDataEpic,
  fetchEnforcementGroupsEpic,
  fetchHasSpotViolationEpic,
);
