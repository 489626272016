import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';
import { SavedRppZoneLayer } from '../../../../../model';

export interface IRppZonesLayerState {
  enabled: boolean;
  display: boolean;
  count: number;
}

const initialState: IRppZonesLayerState = {
  enabled: false,
  display: false,
  count: 0,
};

const slice = createSlice({
  name: 'rppZonesLayer',
  initialState: initialState,
  reducers: {
    fetchCount(state, action: PayloadAction) {
      state.enabled = false;
    },
    fetchCountSuccess(state, action: PayloadAction<{ count: number }>) {
      state.enabled = action.payload.count > 0;
      state.count = action.payload.count;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      console.log(action);
      state.enabled = false;
      state.count = 0;
    },

    setDisplayRppZones(state, action: PayloadAction<boolean>) {
      state.display = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedRppZoneLayer | undefined>) {
      if (action.payload) {
        state.display = action.payload.display;
      }
    },
  },
});

// Actions
export const rppZonesLayerActions = slice.actions;

// Selectors
export const selectRppZonesLayer = (state: RootState) => state.rppZonesLayer;

// Reducer
export const rppZonesLayerReducer = slice.reducer;
