import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import {
  IKpiReportItem,
  IOccupancyReportItem,
  IOccupancySourceReport,
  IOccupancyTrafficReport,
  IRevenueItem,
  ISelectedStudyArea,
  ITrafficReportItem,
  IViolationInfo,
  IZoneRevenueReportItem,
  OccupancySource,
} from '../../../../model';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';
import { ISpotOccupancyStatusChangeDto } from '../../../../services/signaling';

export interface ISelectedStudyAreaState {
  selected: ISelectedEntity<ISelectedStudyArea>[];
  current: number | null;
}

const initialState: ISelectedStudyAreaState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedStudyAreas',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.StudyAreas,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedStudyAreasActions.loadStudyArea(x)));
        },
      ).selected;
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.StudyAreas);
    },
    loadStudyArea(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadStudyAreaSuccess(state, action: PayloadAction<{ studyArea: ISelectedStudyArea; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.studyArea.Id,
        action.payload.studyArea,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadStudyAreaFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.StudyAreas, action.payload.position);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.StudyAreas, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.StudyAreas);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.StudyAreas, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedStudyArea | null>) {
      state.current = action.payload?.Id || null;
    },

    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },

    fetchViolationReport(state, action: PayloadAction<{ studyAreaId: number; filter: IReportFilter }>) {
      const studyArea = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IViolationInfo | null }>) {
      const studyArea = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = false;
      if (!studyArea.entity) {
        return;
      }
      studyArea.entity.violationReport = action.payload.report;
    },
    fetchViolationReportFailed(state, action: PayloadAction<{ studyAreaId: number; error: string }>) {
      const studyArea = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!studyArea) {
        return;
      }
      studyArea.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenueReport(state, action: PayloadAction<{ studyAreaId: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IZoneRevenueReportItem | null }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = false;

      if (!item.entity) {
        return;
      }
      item.entity.revenueReport = action.payload.report;
    },
    fetchRevenueReportFailed(state, action: PayloadAction<{ studyAreaId: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = false;
      console.error(action.payload.error);
    },

    fetchOccupancyTrafficReport(
      state,
      action: PayloadAction<{ studyAreaId: number; filter: IReportFilter; occupancySource: OccupancySource }>,
    ) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancyTrafficReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IOccupancyTrafficReport | null }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = false;
      if (!item.entity) {
        return;
      }
      item.entity.occupancyTrafficReport = action.payload.report;
    },
    fetchOccupancyTrafficReportFailed(state, action: PayloadAction<{ studyAreaId: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenue(state, action: PayloadAction<number>) {},
    fetchRevenueSuccess(state, action: PayloadAction<{ studyAreaId: number; revenue: IRevenueItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item?.entity) {
        return;
      }
      item.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchKpiReport(state, action: PayloadAction<number>) {},
    fetchKpiReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IKpiReportItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item?.entity) {
        return;
      }
      item.entity.kpiReport = action.payload.report;
    },
    fetchKpiReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchTrafficReport(state, action: PayloadAction<number>) {},
    fetchTrafficReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: ITrafficReportItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item?.entity) {
        return;
      }
      item.entity.trafficReport = action.payload.report;
    },
    fetchTrafficReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancyReport(state, action: PayloadAction<{ studyAreaId: number; occupancySource: OccupancySource }>) {},
    fetchOccupancyReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IOccupancyReportItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item?.entity) {
        return;
      }
      item.entity.occupancyReport = action.payload.report;
    },
    fetchOccupancyReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancySourceReport(
      state,
      action: PayloadAction<{ studyAreaId: number; period: [Date, Date]; occupancySource: OccupancySource }>,
    ) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancySourceReportSuccess(state, action: PayloadAction<{ studyAreaId: number; report: IOccupancySourceReport | null }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item?.entity) {
        return;
      }
      item.loading = false;
      item.entity.occupancySourceReport = action.payload.report;
    },
    fetchOccupancySourceReportFailed(state, action: PayloadAction<{ studyAreaId: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.studyAreaId);
      if (!item) {
        return;
      }
      item.loading = false;
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedStudyAreasActions = slice.actions;

// Selectors
export const selectedStudyAreas = (state: RootState) => state.selectedStudyAreas;
export const currentStudyArea = (state: RootState) =>
  state.selectedStudyAreas.selected.find((x) => x.id === state.selectedStudyAreas.current);

// Reducer
export const selectedStudyAreasReducer = slice.reducer;
