import React, { useState, useRef, useContext, createContext, FunctionComponent } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

import styles from './Confirmation.module.scss';

// Create Context
const ConfirmationContext = createContext<(options: IConfirmationOptions) => Promise<void>>((x) =>
  Promise.resolve(),
);

export const useConfirmation = () => useContext(ConfirmationContext);

export interface IConfirmationOptions {
  title?: string;
  message: string;
  okButton?: string;
  cancelButton?: string;
}

type Props = {
  children: React.ReactNode;
};

export const ConfirmationServiceProvider: FunctionComponent<Props> = ({ children }) => {
  const [options, setOptions] = useState<IConfirmationOptions | null>(null);
  const promiseRef = useRef<{ resolve: () => void; reject: () => void } | null>(null);
  const [open, setOpen] = useState(false);

  const openModal = (options: IConfirmationOptions) => {
    setOptions(options);
    setOpen(true);
    return new Promise<void>((resolve, reject) => {
      promiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (promiseRef.current) {
      promiseRef.current.reject();
    }
    setOptions(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve();
    }
    setOptions(null);
    setOpen(false);
  };

  return (
    <ConfirmationContext.Provider value={openModal}>
      {children}

      {open ? (
        <Dialog onClose={handleClose} title={options?.title}>
          <p className={styles.message}>{options?.message}</p>

          <DialogActionsBar>
            <Button themeColor={'primary'} onClick={handleConfirm}>
              {options?.okButton ? options.okButton : 'OK'}
            </Button>
            <Button onClick={handleClose}>
              {options?.cancelButton ? options.cancelButton : 'Cancel'}
            </Button>
          </DialogActionsBar>
        </Dialog>
      ) : null}
    </ConfirmationContext.Provider>
  );
};
