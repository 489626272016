import { FC } from 'react';
import classNames from 'classnames/bind';
import { OffstreetZoneType } from '../../../../model';

import styles from './OffstreetZoneLayerTypeIcon.module.scss';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  layerType: OffstreetZoneType;
}

export const OffstreetZoneLayerTypeIcon: FC<Props> = ({ className, layerType }) => {
  return <span className={cx(className, 'icon', `layer-${layerType}`)}></span>;
};
