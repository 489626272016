import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../../constants';
import { TagFilter } from '../../../../../model';

import { RootState } from '../../../../../store';

export interface IZoneObjectsGeoState {
  loading: boolean;
  curbfaces: FeatureCollection;
  curbfacesCount: number;
  curbfacesFilter: TagFilter;
}

const initialState: IZoneObjectsGeoState = {
  loading: false,
  curbfaces: EMPTY_FEATURE_COLLECTION,
  curbfacesCount: 0,
  curbfacesFilter: new TagFilter(true),
};

const slice = createSlice({
  name: 'zoneObjectsGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ cityCode: string; curbfacesFilter: TagFilter }>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<{ curbfaces: FeatureCollection }>) {
      state.loading = false;

      state.curbfaces = action.payload.curbfaces;
      state.curbfacesCount = action.payload.curbfaces.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.curbfaces = EMPTY_FEATURE_COLLECTION;
      state.curbfacesCount = 0;
    },
  },
});

// Actions
export const zoneObjectsGeoActions = slice.actions;

// Selectors
export const selectZoneObjectsGeo = (state: RootState) => state.zoneObjectsGeo;

// Reducer
export const zoneObjectsGeoReducer = slice.reducer;
