import { Dialog, DialogCloseEvent, DialogProps } from '@progress/kendo-react-dialogs';
import { FunctionComponent, useEffect } from 'react';

export type ModalDialogProps = DialogProps & { modal?: boolean };

export const ModalDialog: FunctionComponent<ModalDialogProps> = ({ ...props }) => {
  useEffect(() => {
    if (!props.modal) {
      const closeDialog = (args: any) => {
        if (args.target.classList.contains('k-overlay')) {
          if (props.onClose) {
            props.onClose({} as DialogCloseEvent);
          }
        }
      };

      document.body.addEventListener('click', closeDialog);

      return function cleanup() {
        window.removeEventListener('click', closeDialog);
      };
    }
  }, [props]);

  return <Dialog {...props}>{props.children}</Dialog>;
};
