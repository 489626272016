import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import { RootState } from '../../store';
import { ILayersSettings, TilesBoundingBox } from '../../model';

export interface IMapState {
  center: Position | null;
  layersSettings: ILayersSettings | null;
  tilesBoundingBox: TilesBoundingBox | null;
}

const initialState: IMapState = {
  center: null,
  layersSettings: null,
  tilesBoundingBox: null,
};

const slice = createSlice({
  name: 'mapState',
  initialState: initialState,
  reducers: {
    setBoundingBox(state, action: PayloadAction<TilesBoundingBox>) {
      state.tilesBoundingBox = action.payload;
    },
    setLayersSettings(state, action: PayloadAction<ILayersSettings>) {
      state.layersSettings = action.payload;
    },
    setMapCenter(state, action: PayloadAction<Position>) {
      state.center = [...action.payload];
    },
    closePopups(state) {},
  },
});

// Actions
export const mapStateActions = slice.actions;

// Selectors
export const selectMapState = (state: RootState) => state.mapState;

// Reducer
export const mapStateReducer = slice.reducer;
