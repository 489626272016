import { AxiosError, AxiosResponse } from 'axios';
import { getApi } from './api';

const allowedErrors = [404];

const get = (path: string): Promise<Blob | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get(path, { responseType: 'blob' })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => {
      if (allowedErrors.includes(error.response?.status || 0)) {
        return null;
      }
      throw error;
    });

export const images = {
  get,
};
