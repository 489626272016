import { Action } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { rppAreasLayerActions, selectedRppAreasActions } from '../..';
import { ISelectedRppArea } from '../../../../../model';
import { rppZones } from '../../../../../services';
import { rppAreas } from '../../../../../services/api/rpp-areas';
import { RootState } from '../../../../../store';
import { citiesActions } from '../../../../common';
import { mapStateActions } from '../../../map-state';

const fetchCountEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(rppAreasLayerActions.fetchCount.match),
    concatMap((action) => from(rppAreas.getCount()).pipe(map((x) => rppAreasLayerActions.fetchCountSuccess({ count: x })))),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((action) => of(rppAreasLayerActions.fetchCount())),
  );

const loadRppArea = (id: string, state: RootState): Observable<ISelectedRppArea> => {
  const existing = state.selectedRppAreas.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(from(rppAreas.getRppArea(id)), from(rppZones.getRppAreaZoneNames(id))).pipe(
    mergeMap(([area, zones]) =>
      of({
        ...area,
        rppZones: zones,
      }),
    ),
  );
};

const rppAreaSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedRppAreasActions.loadRppArea.match),
    concatMap((action) =>
      loadRppArea(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              rppAreasLayerActions.setDisplayRppAreas(true),
              selectedRppAreasActions.loadRppAreaSuccess({ area: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const center = x.Position;
            return of(
              rppAreasLayerActions.setDisplayRppAreas(true),
              mapStateActions.setMapCenter(center),
              selectedRppAreasActions.loadRppAreaSuccess({ area: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedRppAreasActions.loadRppAreaFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedRppAreasActions.closePopups()),
  );

export const rppAreasEpic = combineEpics(fetchCountEpic, citySelectedEpic, rppAreaSelectedEpic, closePopupsEpic);
