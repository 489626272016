import moment from 'moment';
import { Interval } from './interval';
import { IReportFilter } from '../../services';

export class HeatmapPeriod {
  public static Today = new HeatmapPeriod('Today');
  public static LastDay = new HeatmapPeriod('LastDay');

  public static LastWeek = new HeatmapPeriod('LastWeek');
  public static LastWeekToDate = new HeatmapPeriod('LastWeekToDate');
  public static ThisWeek = new HeatmapPeriod('ThisWeek');

  public static LastMonth = new HeatmapPeriod('LastMonth');
  public static LastMonthToDate = new HeatmapPeriod('LastMonthToDate');
  public static ThisMonth = new HeatmapPeriod('ThisMonth');

  public static LastQuarter = new HeatmapPeriod('LastQuarter');
  public static LastQuarterToDate = new HeatmapPeriod('LastQuarterToDate');
  public static ThisQuarter = new HeatmapPeriod('ThisQuarter');

  public static LastYear = new HeatmapPeriod('LastYear');
  public static LastYearToDate = new HeatmapPeriod('LastYearToDate');
  public static ThisYear = new HeatmapPeriod('ThisYear');

  public static Custom = new HeatmapPeriod('Custom');

  public static fromStr(name: string): HeatmapPeriod {
    switch (name) {
      case 'Today': {
        return HeatmapPeriod.Today;
      }
      case 'LastDay': {
        return HeatmapPeriod.LastDay;
      }
      case 'LastWeek': {
        return HeatmapPeriod.LastWeek;
      }
      case 'LastWeekToDate': {
        return HeatmapPeriod.LastWeekToDate;
      }
      case 'ThisWeek': {
        return HeatmapPeriod.ThisWeek;
      }
      case 'LastMonth': {
        return HeatmapPeriod.LastMonth;
      }
      case 'LastMonthToDate': {
        return HeatmapPeriod.LastMonthToDate;
      }
      case 'ThisMonth': {
        return HeatmapPeriod.ThisMonth;
      }
      case 'LastQuarter': {
        return HeatmapPeriod.LastQuarter;
      }
      case 'LastQuarterToDate': {
        return HeatmapPeriod.LastQuarterToDate;
      }
      case 'ThisQuarter': {
        return HeatmapPeriod.ThisQuarter;
      }
      case 'LastYear': {
        return HeatmapPeriod.LastYear;
      }
      case 'LastYearToDate': {
        return HeatmapPeriod.LastYearToDate;
      }
      case 'ThisYear': {
        return HeatmapPeriod.ThisYear;
      }
      case 'Custom': {
        return HeatmapPeriod.Custom;
      }
      default: {
        throw new Error(`Unexpected value ${name} of HeatmapPeriod`);
      }
    }
  }

  readonly name: string;

  private constructor(name: string) {
    this.name = name;
  }

  public calcReportFilter(customPeriod: [Date, Date]): IReportFilter {
    if (this === HeatmapPeriod.Today) {
      return {
        period: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate()],
        interval: Interval.Day,
      };
    } else if (this === HeatmapPeriod.LastDay) {
      return {
        period: [moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate()],
        prevPeriod: [moment().subtract(2, 'day').startOf('day').toDate(), moment().subtract(2, 'day').endOf('day').toDate()],
        interval: Interval.Day,
      };
    } else if (this === HeatmapPeriod.LastWeek) {
      return {
        period: [moment().subtract(1, 'week').startOf('isoWeek').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
        prevPeriod: [moment().subtract(2, 'week').startOf('isoWeek').toDate(), moment().subtract(2, 'week').endOf('week').toDate()],
        interval: Interval.Week,
      };
    } else if (this === HeatmapPeriod.LastWeekToDate) {
      return {
        period: [moment().subtract(1, 'week').startOf('day').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(2, 'week').startOf('day').toDate(), moment().subtract(1, 'week').startOf('day').toDate()],
        interval: Interval.Week,
      };
    } else if (this === HeatmapPeriod.ThisWeek) {
      return {
        period: [moment().startOf('isoWeek').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(1, 'week').startOf('isoWeek').toDate(), moment().subtract(1, 'week').endOf('day').toDate()],
        interval: Interval.Week,
      };
    } else if (this === HeatmapPeriod.LastMonth) {
      return {
        period: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
        prevPeriod: [moment().subtract(2, 'month').startOf('month').toDate(), moment().subtract(2, 'month').endOf('month').toDate()],
        interval: Interval.Month,
      };
    } else if (this === HeatmapPeriod.LastMonthToDate) {
      return {
        period: [moment().subtract(1, 'month').startOf('day').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(2, 'month').startOf('day').toDate(), moment().subtract(1, 'month').startOf('day').toDate()],
        interval: Interval.Month,
      };
    } else if (this === HeatmapPeriod.ThisMonth) {
      return {
        period: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('day').toDate()],
        interval: Interval.Month,
      };
    } else if (this === HeatmapPeriod.LastQuarter) {
      return {
        period: [moment().subtract(1, 'quarter').startOf('quarter').toDate(), moment().subtract(1, 'quarter').endOf('quarter').toDate()],
        prevPeriod: [
          moment().subtract(2, 'quarter').startOf('quarter').toDate(),
          moment().subtract(2, 'quarter').endOf('quarter').toDate(),
        ],
        interval: Interval.Quarter,
      };
    } else if (this === HeatmapPeriod.LastQuarterToDate) {
      return {
        period: [moment().subtract(1, 'quarter').startOf('day').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(2, 'quarter').startOf('day').toDate(), moment().subtract(1, 'quarter').startOf('day').toDate()],
        interval: Interval.Quarter,
      };
    } else if (this === HeatmapPeriod.ThisQuarter) {
      return {
        period: [moment().startOf('quarter').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(1, 'quarter').startOf('quarter').toDate(), moment().subtract(1, 'quarter').endOf('day').toDate()],
        interval: Interval.Quarter,
      };
    } else if (this === HeatmapPeriod.LastYear) {
      return {
        period: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()],
        prevPeriod: [moment().subtract(2, 'year').startOf('year').toDate(), moment().subtract(2, 'year').endOf('year').toDate()],
      };
    } else if (this === HeatmapPeriod.LastYearToDate) {
      return {
        period: [moment().subtract(1, 'year').startOf('day').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(2, 'year').startOf('day').toDate(), moment().subtract(1, 'year').startOf('day').toDate()],
      };
    } else if (this === HeatmapPeriod.ThisYear) {
      return {
        period: [moment().startOf('year').toDate(), moment().endOf('day').toDate()],
        prevPeriod: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('day').toDate()],
      };
    } else if (this === HeatmapPeriod.Custom) {
      return {
        period: [moment(customPeriod[0]).startOf('day').toDate(), moment(customPeriod[1]).endOf('day').toDate()],
        prevPeriod: [
          moment(customPeriod[0])
            .subtract(moment(customPeriod[1]).diff(customPeriod[0], 'days') + 1, 'days')
            .startOf('day')
            .toDate(),
          moment(customPeriod[0]).subtract(1, 'day').endOf('day').toDate(),
        ],
      };
    } else {
      throw new Error(`Unexpected value ${this.name} of HeatmapPeriod`);
    }
  }

  public calcPeriod(customPeriod?: [Date, Date]): [Date, Date] {
    if (this === HeatmapPeriod.Today) {
      return [moment().startOf('day').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.LastDay) {
      return [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()];
    } else if (this === HeatmapPeriod.LastWeek) {
      return [moment().subtract(1, 'week').startOf('isoWeek').toDate(), moment().subtract(1, 'week').endOf('week').toDate()];
    } else if (this === HeatmapPeriod.LastWeekToDate) {
      return [moment().subtract(1, 'week').startOf('day').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.ThisWeek) {
      return [moment().startOf('isoWeek').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.LastMonth) {
      return [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()];
    } else if (this === HeatmapPeriod.LastMonthToDate) {
      return [moment().subtract(1, 'month').startOf('day').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.ThisMonth) {
      return [moment().startOf('month').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.LastQuarter) {
      return [moment().subtract(1, 'quarter').startOf('quarter').toDate(), moment().subtract(1, 'quarter').endOf('quarter').toDate()];
    } else if (this === HeatmapPeriod.LastQuarterToDate) {
      return [moment().subtract(1, 'quarter').startOf('day').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.ThisQuarter) {
      return [moment().startOf('quarter').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.LastYear) {
      return [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()];
    } else if (this === HeatmapPeriod.LastYearToDate) {
      return [moment().subtract(1, 'year').startOf('day').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.ThisYear) {
      return [moment().startOf('year').toDate(), moment().endOf('day').toDate()];
    } else if (this === HeatmapPeriod.Custom && customPeriod) {
      return [moment(customPeriod[0]).startOf('day').toDate(), moment(customPeriod[1]).endOf('day').toDate()];
    } else {
      throw new Error(`Unexpected value ${this.name} of HeatmapPeriod`);
    }
  }
}
