const occupancyCalculator = (spotsCount: number, occupiedSpotsCount: number) => {
  return {
    occupiedSpotsCount: Math.min(spotsCount, occupiedSpotsCount),
    vacantSpotsCount: Math.max(spotsCount - occupiedSpotsCount, 0),
  };
};

export const loadingZone = {
  occupancyCalculator,
};
