import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WeekDay } from '../../../../model';
import { IDurationHeatmapData } from '../../../../services/api/duration';
import { RootState } from '../../../../store';

export interface IDurationState {
  studyAreaDurationData: IDurationHeatmapData[];
  studyAreaDurationDataLoading: boolean;
}

const initialState: IDurationState = {
  studyAreaDurationData: [],
  studyAreaDurationDataLoading: false,
};

const slice = createSlice({
  name: 'durationData',
  initialState: initialState,
  reducers: {
    fetchStudyAreaDurationData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.studyAreaDurationDataLoading = true;
    },
    fetchStudyAreaDurationDataSuccess(state, action: PayloadAction<IDurationHeatmapData[]>) {
      state.studyAreaDurationData = action.payload;
      state.studyAreaDurationDataLoading = false;
    },
    fetchStudyAreaDurationDataFailed(state, action: PayloadAction<string>) {
      state.studyAreaDurationDataLoading = false;
      console.error(action);
    },
  },
});

// Actions
export const durationDataActions = slice.actions;

// Selectors
export const selectDurationData = (state: RootState) => state.durationData;

// Reducer
export const durationDataReducer = slice.reducer;
