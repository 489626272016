import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox } from '../../../../model';
import { RootState } from '../../../../store';

export interface IMetersLevelPolygonsState {
  loading: boolean;
  data: FeatureCollection;
  count: number;
}

const initialState: IMetersLevelPolygonsState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'metersGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.loading = false;
      state.data = action.payload;
      state.count = action.payload.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const metersGeoActions = slice.actions;

// Selectors
export const selectMetersGeoLoading = (state: RootState) => state.metersGeo.loading;
export const selectMetersGeo = (state: RootState) => state.metersGeo.data;
export const selectMetersGeoCount = (state: RootState) => state.metersGeo.count;

// Reducer
export const metersGeoReducer = slice.reducer;
