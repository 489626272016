import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../store';
import { SavedLoadingZoneLayer } from '../../../../model';

export interface ILoadingZonesLayerState {
  enabled: boolean;
  count: number | null;
  displayZoneId: boolean;
}

const initialState: ILoadingZonesLayerState = {
  enabled: false,
  count: null,
  displayZoneId: true,
};

const slice = createSlice({
  name: 'loadingZonesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.count = 0;
      console.log(action);
    },

    setDisplayZoneId(state, action: PayloadAction<boolean>) {
      state.displayZoneId = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedLoadingZoneLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.displayZoneId = action.payload.displayZoneId;
      }
    },
  },
});

// Actions
export const loadingZonesLayerActions = slice.actions;

// Selectors
export const selectLoadingZonesEnabled = (state: RootState) => state.loadingZonesLayer.enabled;
export const selectLoadingZonesLayer = (state: RootState) => state.loadingZonesLayer;
export const selectLoadingZonesCount = (state: RootState) => state.loadingZonesLayer.count;

// Reducer
export const loadingZonesLayerReducer = slice.reducer;
