import { useMap } from 'react-map-gl';

export const useMapBouncer = () => {
  const map = useMap();

  function bounce() {
    const center = map.current?.getCenter();
    if (map.current && center) {
      center.lng += 0.00000000001;
      map.current.setCenter(center);
    }
  }
  return {
    bounce,
  };
};
