import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { loadingZone, TilesBoundingBox } from '../../../../model';

import { RootState } from '../../../../store';

export interface ILoadingZonesLevelPolygonsState {
  data: FeatureCollection;
  count: number;
  spots: FeatureCollection;
  occupiedCount: FeatureCollection;
}

const enrichVacantSpotsCount = (occupiedCount: FeatureCollection) => ({
  ...occupiedCount,
  features: [
    ...occupiedCount.features.map((x) => ({
      ...x,
      properties: {
        ...x.properties,
        vacantCount: loadingZone.occupancyCalculator(x.properties?.spotsCount || 0, x.properties?.occupiedSpotsCount || 0).vacantSpotsCount,
      },
    })),
  ],
});

const initialState: ILoadingZonesLevelPolygonsState = {
  data: EMPTY_FEATURE_COLLECTION,
  count: 0,
  spots: EMPTY_FEATURE_COLLECTION,
  occupiedCount: EMPTY_FEATURE_COLLECTION,
};

const slice = createSlice({
  name: 'loadingZonesGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {
      state.count = 0;
    },
    fetchSuccess(state, action: PayloadAction<{ data: FeatureCollection; spots: FeatureCollection; occupiedCount: FeatureCollection }>) {
      state.data = action.payload.data;
      const uniqueZones = new Set(action.payload.data.features.map((x) => x.properties?.id));
      state.count = uniqueZones.size;
      state.spots = action.payload.spots;
      state.occupiedCount = enrichVacantSpotsCount(action.payload.occupiedCount);
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.data = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },

    updateSpots(state, action: PayloadAction<{ spots: FeatureCollection }>) {
      state.spots = action.payload.spots;
    },

    updateOccupiedCount(state, action: PayloadAction<{ occupiedCount: FeatureCollection }>) {
      state.occupiedCount = enrichVacantSpotsCount(action.payload.occupiedCount);
    },
  },
});

// Actions
export const loadingZonesGeoActions = slice.actions;

// Selectors
export const selectLoadingZonesGeo = (state: RootState) => state.loadingZonesGeo.data;
export const selectLoadingZonesSpotsGeo = (state: RootState) => state.loadingZonesGeo.spots;
export const selectLoadingZonesGeoCount = (state: RootState) => state.loadingZonesGeo.count;
export const selectLoadingZonesGeoOccupiedCount = (state: RootState) => state.loadingZonesGeo.occupiedCount;

// Reducer
export const loadingZonesGeoReducer = slice.reducer;
