import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { LayerName } from '../../../model/map';

export interface ILayerState {
  layerIds: { [key in LayerName]?: string[] };
}

const initialState: ILayerState = {
  layerIds: {},
};

const slice = createSlice({
  name: 'layer-ids',
  initialState: initialState,
  reducers: {
    registerLayers(state, action: PayloadAction<{ layerName: LayerName; layerIds: string[] }>) {
      state.layerIds[action.payload.layerName] = action.payload.layerIds;
    },
    unregisterLayers(state, action: PayloadAction<{ layerName: LayerName }>) {
      delete state.layerIds[action.payload.layerName];
    },
  },
});

// Actions
export const layerIdsActions = slice.actions;

// Selectors
export const selectLayerIds = (state: RootState) => state.layerIds;

// Reducer
export const layerIdsReducer = slice.reducer;
