import { finalize, Observable, of } from 'rxjs';
import { LayerName, WeekDay } from '../../model';
import { fromAxiosPromise, getApi } from './api';

import {
  IBlockfaceGeo,
  IBlockfaceParkingDurationGeo,
  IBlockfaceRevenueGeo,
  ICameraGeo,
  IHeatmapStudyAreaGeo,
  ILoadingZoneGeo,
  IMeterGeo,
  IMeterRevenueGeo,
  IOffstreetZoneGeo,
  IOffstreetZoneRevenueGeo,
  ISignGeo,
  ISpotGeo,
  IStudyAreaGeo,
  ITrafficGeo,
  IZoneGeo,
  IZoneParkingDurationGeo,
  IZoneRevenueGeo,
} from '../../model/api/geo-models';
import { store } from '../../store';
import { dateUtils } from '../../utils';
import { geoRevisionProcessor } from '../geo-revision-processor';

const BASE_URL = '/geoindex';

const TIMEOUT = 10000;
let requestsCounter = 0;

const getMaxRequestsNumber = (): number | undefined => {
  return store.getState().appSettings.data?.max_api_requests;
};
const maxRequestsExceeded = (): boolean => {
  const maxRequests = getMaxRequestsNumber();
  return !!maxRequests && requestsCounter >= maxRequests;
};

const getCameras = (x: number, y: number, z: number): Observable<Array<ICameraGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<ICameraGeo>>(`${BASE_URL}/camera/${z}/${x}/${y}?${geoRevisionProcessor.getRevision(LayerName.Cameras)}`, {
        cancelToken: token,
        timeout: TIMEOUT,
      })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getMeters = (x: number, y: number, z: number): Observable<Array<IMeterGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IMeterGeo>>(`${BASE_URL}/meter/${z}/${x}/${y}?${geoRevisionProcessor.getRevision(LayerName.Meters)}`, {
        cancelToken: token,
        timeout: TIMEOUT,
      })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getSigns = (x: number, y: number, z: number): Observable<Array<ISignGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<ISignGeo>>(`${BASE_URL}/sign/${z}/${x}/${y}?${geoRevisionProcessor.getRevision(LayerName.Signs)}`, {
        cancelToken: token,
        timeout: TIMEOUT,
      })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getZones = (x: number, y: number, z: number): Observable<Array<IZoneGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IZoneGeo>>(`${BASE_URL}/zone/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getLoadingZones = (x: number, y: number, z: number): Observable<Array<ILoadingZoneGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<ILoadingZoneGeo>>(`${BASE_URL}/loading-zone/${z}/${x}/${y}?${geoRevisionProcessor.getRevision(LayerName.LoadingZones)}`, {
        cancelToken: token,
        timeout: TIMEOUT,
      })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getSpots = (x: number, y: number, z: number): Observable<Array<ISpotGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<ISpotGeo>>(`${BASE_URL}/spot/${z}/${x}/${y}?${geoRevisionProcessor.getRevision(LayerName.Spots)}`, {
        cancelToken: token,
        timeout: TIMEOUT,
      })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getOffstreetZones = (x: number, y: number, z: number): Observable<Array<IOffstreetZoneGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IOffstreetZoneGeo>>(`${BASE_URL}/offstreetzone/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getMetersRevenue = (
  x: number,
  y: number,
  z: number,
  revenuePeriod: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IMeterRevenueGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  if (!weekDays?.length) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IMeterRevenueGeo>>(
        `${BASE_URL}/meter-revenue/${z}/${x}/${y}?period.from=${dateUtils.toDateString(
          revenuePeriod[0],
        )}&period.to=${dateUtils.toDateString(revenuePeriod[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        {
          cancelToken: token,
          timeout: TIMEOUT,
        },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getZonesRevenue = (
  x: number,
  y: number,
  z: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IZoneRevenueGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  if (!weekDays?.length) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IZoneRevenueGeo>>(
        `${BASE_URL}/zone-revenue/${z}/${x}/${y}?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
          period[1],
        )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        {
          cancelToken: token,
          timeout: TIMEOUT,
        },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getOffstreetZonesRevenue = (
  x: number,
  y: number,
  z: number,
  revenuePeriod: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IOffstreetZoneRevenueGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  if (!weekDays?.length) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IOffstreetZoneRevenueGeo>>(
        `${BASE_URL}/offstreetzone-revenue/${z}/${x}/${y}?period.from=${dateUtils.toDateString(
          revenuePeriod[0],
        )}&period.to=${dateUtils.toDateString(revenuePeriod[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        {
          cancelToken: token,
          timeout: TIMEOUT,
        },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getZoneParkingDurations = (
  x: number,
  y: number,
  z: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IZoneParkingDurationGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IZoneParkingDurationGeo>>(
        `${BASE_URL}/zone-duration/${z}/${x}/${y}?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
          period[1],
        )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        { cancelToken: token, timeout: TIMEOUT },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getBlockfaces = (x: number, y: number, z: number): Observable<Array<IBlockfaceGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IBlockfaceGeo>>(`${BASE_URL}/blockface/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getTraffic = (x: number, y: number, z: number): Observable<Array<ITrafficGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<ITrafficGeo>>(`${BASE_URL}/traffic/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getBlockfacesRevenue = (
  x: number,
  y: number,
  z: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IBlockfaceRevenueGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  if (!weekDays?.length) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IBlockfaceRevenueGeo>>(
        `${BASE_URL}/blockface-revenue/${z}/${x}/${y}?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
          period[1],
        )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        {
          cancelToken: token,
          timeout: TIMEOUT,
        },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getBlockfaceParkingDurations = (
  x: number,
  y: number,
  z: number,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Observable<Array<IBlockfaceParkingDurationGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IBlockfaceParkingDurationGeo>>(
        `${BASE_URL}/blockface-duration/${z}/${x}/${y}?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
          period[1],
        )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
        { cancelToken: token, timeout: TIMEOUT },
      )
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getStudyAreas = (x: number, y: number, z: number): Observable<Array<IStudyAreaGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IStudyAreaGeo>>(`${BASE_URL}/study-area/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

const getHeatmapStudyAreas = (x: number, y: number, z: number): Observable<Array<IHeatmapStudyAreaGeo>> => {
  if (maxRequestsExceeded()) {
    return of([]);
  }

  requestsCounter += 1;
  return fromAxiosPromise((token) =>
    getApi()
      .get<Array<IHeatmapStudyAreaGeo>>(`${BASE_URL}/heatmap-study-area/${z}/${x}/${y}`, { cancelToken: token, timeout: TIMEOUT })
      .then((response) => response.data)
      .then((x) => x || []),
  ).pipe(finalize(() => (requestsCounter -= 1)));
};

export const geoIndexes = {
  getCameras,
  getMeters,
  getSigns,
  getZones,
  getSpots,
  getOffstreetZones,
  getMetersRevenue,
  getZonesRevenue,
  getOffstreetZonesRevenue,
  getZoneParkingDurations,
  getLoadingZones,
  getBlockfaces,
  getTraffic,
  getBlockfacesRevenue,
  getBlockfaceParkingDurations,
  getStudyAreas,
  getHeatmapStudyAreas,
};
