import { getApi } from './api';
import { ICamera, ICameraState, ICameraStatusCount, ICameraWithSpots } from '../../model';
import { Utils } from '../../utils';
import { AxiosError } from 'axios';

const BASE_URL = '/camera';
const setStateAllowedErrors = [404];

const get = (id: string): Promise<ICamera> => {
  return getApi()
    .get<ICamera>(`${BASE_URL}/${id}`)
    .then((response) => response.data)
    .then((x) => ({
      ...x,
      CreatedAt: new Date(x.CreatedAt),
      UpdatedAd: new Date(x.UpdatedAt),
      Address: Utils.capitalize(x.Address),
      Configuration: x.Configuration
        ? {
            ...x.Configuration,
            Mode: Utils.capitalize(x.Configuration.Mode),
            BootTime: x.Configuration.BootTime?.map((bt) => {
              const from = bt.From.split(':');
              const to = bt.To.split(':');
              return {
                From: `${from[0]}:${from[1]}`,
                To: `${to[0]}:${to[1]}`,
              };
            }),
          }
        : undefined,
    }));
};

const getState = (id: string): Promise<ICameraState | null> => {
  return getApi({ ignoreErrorStatuses: setStateAllowedErrors })
    .get<ICameraState>(`${BASE_URL}/${id}/state`)
    .then((response) => response.data)
    .then((x) => ({
      ...x,
      LastUpdated: x.LastUpdated ? new Date(x.LastUpdated) : null,
    }))
    .catch((error: AxiosError) => {
      if (setStateAllowedErrors.includes(error.response?.status || 0)) {
        return null;
      }
      throw error;
    });
};

const getCameraBySpotsIds = (spotsIds: number[]) => {
  const ids = spotsIds.map((x) => `id=${x}`).join('&');
  return getApi()
    .get<ICameraWithSpots[]>(`${BASE_URL}/by-spots/?${ids}`)
    .then((x) => x.data || []);
};

const getStatusCount = (): Promise<ICameraStatusCount[]> =>
  getApi()
    .get<ICameraStatusCount[]>(`${BASE_URL}/status-count`)
    .then((x) => x.data || []);

export const cameras = {
  get,
  getState,
  getCameraBySpotsIds,
  getStatusCount,
};
