import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedStreetObjectsLayer, StreetObjectType, TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

export interface IStreetObjectsLayerState {
  available: boolean;
  enabled: boolean;
  cityCode: string | null;
  types: Array<StreetObjectType>;
  typesFilter: TagFilter;
}

const initialState: IStreetObjectsLayerState = {
  available: true,
  enabled: false,
  cityCode: null,
  types: [],
  typesFilter: new TagFilter(true),
};

const slice = createSlice({
  name: 'streetObjectsLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchTypes(state, action: PayloadAction<{ cityCode: string }>) {},
    fetchTypesSuccess(state, action: PayloadAction<{ cityCode: string; types: Array<StreetObjectType> }>) {
      state.cityCode = action.payload.cityCode;
      state.types = action.payload.types;
      state.available = action.payload.types.length > 0;
      state.typesFilter = action.payload.types.length > 0 ? state.typesFilter.enable(true) : new TagFilter(false);
    },
    fetchTypesFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedStreetObjectsLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.typesFilter = TagFilter.fromSavedValue(action.payload.typesFilter);
      }
    },
  },
});

// Actions
export const streetObjectsLayerActions = slice.actions;

// Selectors
export const selectStreetObjectsLayer = (state: RootState) => state.streetObjectsLayer;

// Reducer
export const streetObjectsLayerReducer = slice.reducer;
