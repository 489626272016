import { IEntityBase } from './entity-base';

export enum CameraStatus {
  Offline = 'Offline',
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface ICameraConfiguration {
  Mode: string;
  UploadIntervalMs: number;
  TelemetryTimeoutMs: number;
  Version: string;
  BootTime: Array<{ From: string; To: string }>;
}

export interface ICameraWithSpots {
  CameraId: string;
  SpotIds: Array<number>;
}

export interface ICamera extends IEntityBase<string> {
  Name: string;
  Position: [number, number];
  Address: string;
  SpotIds: Array<number>;
  CreatedAt: Date;
  UpdatedAt: Date;
  VideoUrl: string;
  Configuration?: ICameraConfiguration;
}

export interface ICameraState {
  CameraId: string;
  LastUpdated: Date | null;
  Status: CameraStatus;
}

export interface ICameraStatusCount {
  Status: CameraStatus;
  Count: number;
}

export function createInactiveCameraState(cameraId: string): ICameraState {
  return {
    CameraId: cameraId,
    LastUpdated: null,
    Status: CameraStatus.Inactive,
  };
}
