import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, GeoJsonProperties, Position } from 'geojson';
import { IBlockface, IRevenueItem, ISpotState, IStudyAreaName, IZone } from '../../../../model';
import { IMeter } from '../../../../model/api/meter';
import { ITransaction } from '../../../../model/api/meter-transactions';
import { ISpotOccupancyStatusChangeDto } from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedMeter extends IMeter {
  revenue?: IRevenueItem[];
  zone: IZone | null;
  transactions?: ITransaction[];
  group: IMeter[];
  spotsStates: ISpotState[];
  blockface: IBlockface | null;
  studyAreas: IStudyAreaName[];
}

export interface ISelectedMeterState {
  selected: ISelectedEntity<ISelectedMeter>[];
  current: number | null;
}

const initialState: ISelectedMeterState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedMeters',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Meters,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedMetersActions.loadMeter(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Meters, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Meters);
    },
    loadMeter(
      state,
      action: PayloadAction<{
        id: number;
        feature?: Feature<Point, GeoJsonProperties>;
        position: Position | null;
        initPosition?: Position | null;
      }>,
    ) {
      selectedSliceLogic.handleLoad(
        state,
        action.payload.id,
        action.payload.position,
        null,
        action.payload.feature,
        action.payload.initPosition,
      );
    },
    loadMeterSuccess(state, action: PayloadAction<{ meter: ISelectedMeter; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.meter.Id,
        action.payload.meter,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadMeterFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    updateMeterPosition(state, action: PayloadAction<{ id: number; position: [number, number] }>) {
      state.selected.forEach((item) => {
        if (item.entity?.Id === action.payload.id) {
          item.position = action.payload.position;
        }
      });
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Meters, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Meters);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Meters, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedMeter | null>) {
      state.current = action.payload?.Id || null;
    },
    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },

    fetchTransactions(state, action: PayloadAction<number>) {},
    fetchTransactionsSuccess(state, action: PayloadAction<{ id: number; transactions: ITransaction[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.entity.transactions = action.payload.transactions;
    },
    fetchTransactionsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchRevenue(state, action: PayloadAction<number>) {},
    fetchRevenueSuccess(state, action: PayloadAction<{ id: number; revenue: IRevenueItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
  },
});
// Actions
export const selectedMetersActions = slice.actions;

// Selectors
export const selectedMeters = (state: RootState) => state.selectedMeters;
export const currentMeter = (state: RootState) => state.selectedMeters.selected.find((x) => x.id === state.selectedMeters.current);

// Reducer
export const selectedMetersReducer = slice.reducer;
