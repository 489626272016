import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox, WeekDay } from '../../../../model';
import { ISpeedData } from '../../../../services';
import { RootState } from '../../../../store';

export interface ITrafficGeoState {
  geoLoading: boolean;
  data: FeatureCollection;
  speedData: Array<ISpeedData>;
  speedLoadingData: boolean;
}

const initialState: ITrafficGeoState = {
  geoLoading: false,
  data: EMPTY_FEATURE_COLLECTION,
  speedData: [],
  speedLoadingData: false,
};

const slice = createSlice({
  name: 'trafficGeo',
  initialState: initialState,
  reducers: {
    fetchGeo(state, action: PayloadAction<TilesBoundingBox>) {
      state.geoLoading = true;
    },
    fetchGeoSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.geoLoading = false;
      state.data = action.payload;
    },
    fetchGeoFailed(state, action: PayloadAction<string>) {
      state.geoLoading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },

    fetchSpeedData(
      state,
      action: PayloadAction<{
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.speedLoadingData = true;
    },
    fetchSpeedDataSuccess(state, action: PayloadAction<Array<ISpeedData>>) {
      state.speedLoadingData = false;
      state.speedData = action.payload;
    },
    fetchSpeedDataFailed(state, action: PayloadAction<string>) {
      state.speedLoadingData = false;
      state.speedData = [];
      console.log(action);
    },
  },
});

// Actions
export const trafficGeoActions = slice.actions;

// Selectors
export const selectTrafficGeoLoading = (state: RootState) => state.trafficGeo.geoLoading;
export const selectTrafficGeo = (state: RootState) => state.trafficGeo.data;
export const selectTrafficGeoSpeedLoadingData = (state: RootState) => state.trafficGeo.speedLoadingData;
export const selectTrafficGeoSpeedData = (state: RootState) => state.trafficGeo.speedData;

// Reducer
export const trafficGeoReducer = slice.reducer;
