import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  BrowserCacheLocation,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { Configuration } from '@azure/msal-browser';
import { IMsalContext } from '@azure/msal-react';

import { IAppSettings } from '../../../model';
import { loginRequest } from './msal-config';
import { amplitudeService } from '../../../services';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig: Configuration = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
  },
};

let instance: IPublicClientApplication | null = null;
let msalContext: IMsalContext | null;

export default function createMsalInstance(
  appSettings: IAppSettings,
  onAuthenticated: (res: AccountInfo | null) => void,
): IPublicClientApplication {
  if (instance) return instance;

  const config = {
    ...msalConfig,
    ...{
      auth: {
        clientId: appSettings.auth.aadClientId,
        authority: appSettings.auth.aadAuthority,
      },
    },
  };

  const msalInstance = new PublicClientApplication(config);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  setTimeout(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
      onAuthenticated(accounts[0]);
    }
  }, 1);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      onAuthenticated(account);
      if (account?.username) {
        amplitudeService.login(account.username);
      }
    } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
      amplitudeService.logout();
    }
  });

  msalInstance.handleRedirectPromise().then((res) => {
    if (res !== null) {
      msalInstance.setActiveAccount(res.account);
      onAuthenticated(res.account);
    }
  });

  instance = msalInstance;
  return msalInstance;
}

export function getInstance(): IPublicClientApplication {
  if (!instance) {
    throw new Error('MSAL instance is not initialized.');
  }
  return instance;
}

export async function acquireToken(scopes: string[]): Promise<string> {
  const msalInstance = getInstance();

  const account = msalInstance?.getAllAccounts()[0];

  let accessTokenResponse: AuthenticationResult;
  try {
    accessTokenResponse = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      redirectUri: '/',
      account: account,
    });

    return accessTokenResponse.accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      return await acquireTokenPopup(scopes, true);
    } else if (err instanceof BrowserAuthError) {
      await acquireTokenRedirect(scopes, false);
    }
    throw err;
  }
}

async function acquireTokenPopup(scopes: string[], interactive: boolean): Promise<string> {
  const msalInstance = getInstance();

  const accessTokenResponse = await msalInstance.acquireTokenPopup({
    scopes: scopes,
    redirectUri: '/',
    prompt: interactive ? 'select_account' : 'none',
  });

  return accessTokenResponse.accessToken;
}

async function acquireTokenRedirect(scopes: string[], interactive: boolean): Promise<void> {
  if (msalContext?.inProgress) {
    return;
  }

  const msalInstance = getInstance();

  await msalInstance.acquireTokenRedirect({
    scopes: scopes,
    redirectUri: '/',
    prompt: interactive ? 'select_account' : 'none',
  });
}

export async function logout(): Promise<void> {
  const msalInstance = getInstance();
  // await msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
  try {
    await msalInstance.logoutPopup();
  } catch (err) {
    await msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }
}

export async function login(): Promise<void> {
  const msalInstance = getInstance();
  // await msalInstance.loginRedirect(loginRequest);
  try {
    await msalInstance.loginPopup(loginRequest);
  } catch (err) {
    await msalInstance.loginRedirect(loginRequest);
  }
}

export function setMsalContext(context: IMsalContext) {
  msalContext = context;
}
