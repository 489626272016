import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../store';
import { ILayersSettings, ISavedView } from '../../../model';

export interface ISavedViewsState {
  views: ISavedView[];
}

const initialState: ISavedViewsState = {
  views: [],
};

const slice = createSlice({
  name: 'savedView',
  initialState: initialState,
  reducers: {
    fetch(state) {},
    fetchSuccess(state, action: PayloadAction<ISavedView[]>) {
      state.views = action.payload;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.views = [];
      console.log(action);
    },

    add(state, action: PayloadAction<{ view: ISavedView }>) {},
    update(state, action: PayloadAction<{ view: ISavedView; index: number }>) {},
    delete(state, action: PayloadAction<{ index: number }>) {},
    saveSuccess(state, action: PayloadAction<ISavedView[]>) {
      state.views = action.payload;
    },
    saveFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    applySavedLayersSettings(state, action: PayloadAction<ILayersSettings>) {},
  },
});

// Actions
export const savedViewsActions = slice.actions;

// Selectors
export const selectSavedViews = (state: RootState) => state.savedViews;

// Reducer
export const savedViewsReducer = slice.reducer;
