import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSuggestedStreetLayer, SuggestedStreetType, TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

export interface ISuggestedStreetsLayerState {
  available: boolean;
  enabled: boolean;
  cityCode: string | null;
  types: SuggestedStreetType[];
  typesFilter: TagFilter;
  showStreets: boolean;
}

const initialState: ISuggestedStreetsLayerState = {
  available: true,
  enabled: false,
  cityCode: null,
  types: [],
  typesFilter: new TagFilter(true),
  showStreets: false,
};

const slice = createSlice({
  name: 'suggestedStreetsLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },

    fetchTypes(state, action: PayloadAction<{ cityCode: string }>) {},
    fetchTypesSuccess(state, action: PayloadAction<{ cityCode: string; types: SuggestedStreetType[] }>) {
      state.cityCode = action.payload.cityCode;
      state.types = action.payload.types;
      state.available = action.payload.types.length > 0;
      state.typesFilter = action.payload.types.length > 0 ? state.typesFilter.enable(true) : new TagFilter(false);
    },
    fetchTypesFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    setShowStreets(state, action: PayloadAction<boolean>) {
      state.showStreets = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedSuggestedStreetLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.showStreets = action.payload.showStreets;
        state.typesFilter = TagFilter.fromSavedValue(action.payload.typesFilter);
      }
    },
  },
});

// Actions
export const suggestedStreetsLayerActions = slice.actions;

// Selectors
export const selectSuggestedStreetsLayer = (state: RootState) => state.suggestedStreetsLayer;

// Reducer
export const suggestedStreetsLayerReducer = slice.reducer;
