import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TilesBoundingBox } from '../../../../model';
import { RootState } from '../../../../store';

export interface IStudyAreasGeoState {
  data: FeatureCollection;
  heatmapData: FeatureCollection;
  count: number;
}

const initialState: IStudyAreasGeoState = {
  data: EMPTY_FEATURE_COLLECTION,
  heatmapData: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'studyAreasGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<TilesBoundingBox>) {},
    fetchSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.data = action.payload;
      state.count = action.payload.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.heatmapData = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },

    fetchHeatmap(state, action: PayloadAction<TilesBoundingBox>) {},
    fetchHeatmapSuccess(state, action: PayloadAction<FeatureCollection>) {
      state.heatmapData = action.payload;
      state.count = action.payload.features.length;
    },
    fetchHeatmapFailed(state, action: PayloadAction<string>) {
      state.heatmapData = EMPTY_FEATURE_COLLECTION;
      console.log(action);
    },
  },
});

// Actions
export const studyAreasGeoActions = slice.actions;

// Selectors
export const selectStudyAreasGeo = (state: RootState) => state.studyAreasGeo.data;
export const selectHeatmapStudyAreasGeo = (state: RootState) => state.studyAreasGeo.heatmapData;
export const selectStudyAreasGeoCount = (state: RootState) => state.studyAreasGeo.count;

// Reducer
export const studyAreasGeoReducer = slice.reducer;
