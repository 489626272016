import { ICitySetting } from '../model';
import { stringUtils } from './string-utils';

const ReportUrl = 'https://app.powerbi.com/reportEmbed?autoAuth=true&ctid=2b42b343-b349-421e-83fc-7f12b246bdbb&navContentPaneEnabled=false';

export class ReportsBuilder {
  private readonly setings: ICitySetting[];

  constructor(settings: ICitySetting[]) {
    this.setings = settings;
  }

  private getReportUrl(report: string) {
    report = 'Reports.' + report;
    const reportUrl = this.setings.find((x) => x.Name === report)?.Value;
    return reportUrl ? ReportUrl + reportUrl : null;
  }

  public getOverviewDashboard() {
    return this.getReportUrl('Overview.Dashboard');
  }

  public getOverviewLocationKpi() {
    return this.getReportUrl('Overview.LocationKpi');
  }

  public getOverviewRevenue() {
    return this.getReportUrl('Overview.Revenue');
  }

  public getOverviewTransactions() {
    return this.getReportUrl('Overview.Transactions');
  }

  public getOverviewOccupancy() {
    return this.getReportUrl('Overview.Occupancy');
  }

  public getPeakOccupancy() {
    return this.getReportUrl('Occupancy.PeakOccupancy');
  }

  public getOverviewDuration() {
    return this.getReportUrl('Overview.Duration');
  }

  public getOverviewEnforcement() {
    return this.getReportUrl('Overview.Enforcement');
  }

  public getTrafficDataAnalysis() {
    return this.getReportUrl('Overview.TrafficDataAnalysis');
  }

  public getOverviewMasterReport() {
    return this.getReportUrl('Overview.MasterReport');
  }

  public getOverviewQandA() {
    return this.getReportUrl('Overview.QA');
  }

  public getOverviewFinancialComparison() {
    return this.getReportUrl('Overview.FinancialComparison');
  }

  public getOverviewStudyAreaAnalysis() {
    return this.getReportUrl('Overview.StudyAreaAnalysis');
  }

  public getOverviewStudyAreaOccupancy() {
    return this.getReportUrl('Overview.StudyAreaOccupancy');
  }

  public getOverviewStudyAreaPostAnalysis() {
    return this.getReportUrl('Overview.StudyAreaPostAnalysis');
  }

  public getOverviewCameraEvents() {
    return this.getReportUrl('Overview.CameraEvents');
  }

  public getOverviewDoubleParkingEvents() {
    return this.getReportUrl('Overview.DoubleParkingEvents');
  }

  public getCommandCenter() {
    return this.getReportUrl('Incident.Dashboard');
  }

  public getIncidentsByLocation() {
    return this.getReportUrl('Incident.ByLocation');
  }

  public getIncidentsByMonth() {
    return this.getReportUrl('Incident.ByMonth');
  }

  public getIncidentsByValue() {
    return this.getReportUrl('Incident.ByValue');
  }

  public getIncidentsMetrics() {
    return this.getReportUrl('Incident.Metrics');
  }

  public getIncidentsCallStats() {
    return this.getReportUrl('Incident.CallStats');
  }

  public getVoiceBotEvents() {
    return this.getReportUrl('Incident.VoiceBotEvents');
  }

  public getGarageRevenue(garageId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.OffstreetZone'), garageId);
  }

  public getZoneRevenue(zoneId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.Zone'), zoneId);
  }

  public getMeterRevenue(meterId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.Meter'), meterId);
  }

  public getAreaRevenue(areaId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.Area'), areaId);
  }

  public getBlockfaceRevenue(blockfaceId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.Blockface'), blockfaceId);
  }

  public getStudyAreaRevenue(studyAreaId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.StudyArea'), studyAreaId);
  }

  public getSpotRevenue(spotId: number) {
    return stringUtils.format(this.getReportUrl('Revenue.Spot'), spotId);
  }

  public getForecasting() {
    return this.getReportUrl('Forecasting');
  }

  public getParkingDuration(zoneId: number) {
    return stringUtils.format(this.getReportUrl('Duration.Zone'), zoneId);
  }

  public getLoadingZonesDashboard() {
    return this.getReportUrl('LoadingZones.Dashboard');
  }

  public getLoadingZonesRevenue() {
    return this.getReportUrl('LoadingZones.Revenue');
  }

  public getLoadingZonesTransactions() {
    return this.getReportUrl('LoadingZones.Transactions');
  }

  public getLoadingZonesOccupancy() {
    return this.getReportUrl('LoadingZones.Occupancy');
  }

  public getLoadingZonesDuration() {
    return this.getReportUrl('LoadingZones.Duration');
  }

  public getLoadingZonesViolations() {
    return this.getReportUrl('LoadingZones.Violations');
  }

  public getLoadingZonesCustomerAnalysis() {
    return this.getReportUrl('LoadingZones.CustomerAnalysis');
  }

  public getParkonectSessions() {
    return this.getReportUrl('Parkonect.Sessions');
  }

  public getParkonectOccupancy() {
    return this.getReportUrl('Parkonect.Occupancy');
  }

  public getParkonectMonthly() {
    return this.getReportUrl('Parkonect.Monthly');
  }

  public getParkonectHotelPass() {
    return this.getReportUrl('Parkonect.HotelPass');
  }

  public getParkonectGarageCollection() {
    return this.getReportUrl('Parkonect.GarageCollection');
  }

  public hasReports() {
    return !!this.getOverviewDashboard();
  }
}
