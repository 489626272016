import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import {
  ISelectedZone,
  IViolationInfo,
  IOccupancyTrafficReport,
  IZoneRevenueReportItem,
  IRevenueItem,
  IViolationMetric,
  IKpiReportItem,
  ITrafficReportItem,
  IOccupancyReportItem,
  IOccupancySourceReport,
  OccupancySource,
} from '../../../../model';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import {
  ICameraActivityStatusChangeDto,
  ISignActivityStatusChangeDto,
  ISpotOccupancyStatusChangeDto,
} from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedZoneState {
  selected: ISelectedEntity<ISelectedZone>[];
  current: number | null;
}

const initialState: ISelectedZoneState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Zones,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedZonesActions.loadZone(x)));
        },
      ).selected;
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Zones);
    },
    loadZone(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadZoneSuccess(state, action: PayloadAction<{ zone: ISelectedZone; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.zone.Id,
        action.payload.zone,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Zones, action.payload.position);
    },
    loadZoneFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Zones, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Zones);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Zones, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedZone | null>) {
      state.current = action.payload?.Id || null;
    },
    changeCameraState(state, action: PayloadAction<ICameraActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.camerasStates) {
          const camera = item.entity?.camerasStates.find((x) => x.CameraId === action.payload.id);
          if (camera) {
            camera.Status = action.payload.status;
            camera.LastUpdated = action.payload.lastUpdated;
          }
        }
      });
    },
    changeSignState(state, action: PayloadAction<ISignActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.signsStates) {
          const sign = item.entity.signsStates.find((x) => x.SignId === action.payload.id);
          if (sign) {
            sign.Status = action.payload.status;
            sign.LastUpdated = action.payload.lastUpdated;
          }
        }
      });
    },
    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },

    fetchRevenueReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IZoneRevenueReportItem | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.revenueReport = action.payload.report;
    },
    fetchRevenueReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    fetchViolationReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IViolationInfo | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      if (!zone.entity) {
        return;
      }
      zone.entity.violationReport = action.payload.report;
    },
    fetchViolationReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    fetchOccupancyTrafficReportData(
      state,
      action: PayloadAction<{ zoneId: number; filter: IReportFilter; occupancySource: OccupancySource }>,
    ) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchOccupancyTrafficReportDataSuccess(state, action: PayloadAction<{ zoneId: number; report: IOccupancyTrafficReport | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      if (!zone.entity) {
        return;
      }
      zone.entity.occupancyTrafficReportData = action.payload.report;
    },
    fetchOccupancyTrafficReportDataFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenue(state, action: PayloadAction<number>) {},
    fetchRevenueSuccess(state, action: PayloadAction<{ zoneId: number; revenue: IRevenueItem[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchKpiReport(state, action: PayloadAction<number>) {},
    fetchKpiReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IKpiReportItem[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.kpiReport = action.payload.report;
    },
    fetchKpiReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchViolationMetricReport(state, action: PayloadAction<number>) {},
    fetchViolationMetricReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IViolationMetric[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.violationMetricReport = action.payload.report;
    },
    fetchViolationMetricReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchTrafficReport(state, action: PayloadAction<number>) {},
    fetchTrafficReportSuccess(state, action: PayloadAction<{ zoneId: number; report: ITrafficReportItem[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.trafficReport = action.payload.report;
    },
    fetchTrafficReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancyReport(state, action: PayloadAction<{ zoneId: number; occupancySource: OccupancySource }>) {},
    fetchOccupancyReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IOccupancyReportItem[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.occupancyReport = action.payload.report;
    },
    fetchOccupancyReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancySourceReport(state, action: PayloadAction<{ zoneId: number; period: [Date, Date]; occupancySource: OccupancySource }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchOccupancySourceReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IOccupancySourceReport | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.loading = false;
      zone.entity.occupancySourceReport = action.payload.report;
    },
    fetchOccupancySourceReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedZonesActions = slice.actions;

// Selectors
export const selectedZones = (state: RootState) => state.selectedZones;
export const currentZone = (state: RootState) => state.selectedZones.selected.find((x) => x.id === state.selectedZones.current);

// Reducer
export const selectedZonesReducer = slice.reducer;
