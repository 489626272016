import { FunctionComponent } from 'react';
import { CameraStatus } from '../../../../model';

import styles from './CameraStatusIcon.module.scss';

interface Props {
  status?: CameraStatus;
  className?: string;
}

export const CameraStatusIcon: FunctionComponent<Props> = ({ status = CameraStatus.Inactive, className }) => {
  return <span className={`${styles.icon} ${status} ${className || ''}`}></span>;
};
