import { Action } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { rppZonesLayerActions } from '../..';
import { ISelectedRppZone } from '../../../../../model';
import { rppZones } from '../../../../../services';
import { rppAreas } from '../../../../../services/api/rpp-areas';
import { RootState } from '../../../../../store';
import { citiesActions } from '../../../../common';
import { mapStateActions } from '../../../map-state';
import { selectedRppZonesActions } from './selected-rpp-zones-slice';

const fetchCountEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(rppZonesLayerActions.fetchCount.match),
    concatMap((action) => from(rppZones.getCount()).pipe(map((x) => rppZonesLayerActions.fetchCountSuccess({ count: x })))),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((action) => of(rppZonesLayerActions.fetchCount())),
  );

const loadRppZone = (id: string, state: RootState): Observable<ISelectedRppZone> => {
  const existing = state.selectedRppZones.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(from(rppZones.getRppZone(id)), from(rppAreas.getRppZoneAreaNames(id)), from(rppZones.getPolicies(id))).pipe(
    mergeMap(([zone, areas, policies]) =>
      of({
        ...zone,
        rppAreas: areas,
        policies: policies,
      }),
    ),
  );
};

const rppZoneSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedRppZonesActions.loadRppZone.match),
    concatMap((action) =>
      loadRppZone(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              rppZonesLayerActions.setDisplayRppZones(true),
              selectedRppZonesActions.loadRppZoneSuccess({ zone: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const center = x.Position;
            return of(
              rppZonesLayerActions.setDisplayRppZones(true),
              mapStateActions.setMapCenter(center),
              selectedRppZonesActions.loadRppZoneSuccess({ zone: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedRppZonesActions.loadRppZoneFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedRppZonesActions.closePopups()),
  );

export const rppZonesEpic = combineEpics(fetchCountEpic, citySelectedEpic, rppZoneSelectedEpic, closePopupsEpic);
