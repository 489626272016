import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import {
  IKpiReportItem,
  IOccupancyReportItem,
  IOccupancySourceReport,
  IParkingEvent,
  IRevenueItem,
  ISelectedCurbSpace,
  ISpotRegulation,
  ISpotStatistics,
  OccupancySource,
} from '../../../../model';
import {
  ICameraActivityStatusChangeDto,
  ISignActivityStatusChangeDto,
  ISpotOccupancyStatusChangeDto,
} from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';
import { ITransaction } from '../../../../model/api/meter-transactions';

export interface ISelectedCurbSpaceState {
  selected: ISelectedEntity<ISelectedCurbSpace>[];
  current: number | null;
}

const initialState: ISelectedCurbSpaceState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedCurbSpaces',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.CurbSpaces,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedCurbSpacesActions.load(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.CurbSpaces, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.CurbSpaces);
    },

    load(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadSuccess(state, action: PayloadAction<{ curbSpace: ISelectedCurbSpace; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.curbSpace.Id,
        action.payload.curbSpace,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.CurbSpaces, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.CurbSpaces);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.CurbSpaces, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedCurbSpace | null>) {
      state.current = action.payload?.Id || null;
    },
    changeCameraState(state, action: PayloadAction<ICameraActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.cameraState?.CameraId === action.payload.id) {
          item.entity.cameraState.Status = action.payload.status;
          item.entity.cameraState.LastUpdated = action.payload.lastUpdated;
        }
      });
    },
    changeSignState(state, action: PayloadAction<ISignActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.signState?.SignId === action.payload.id) {
          item.entity.signState.Status = action.payload.status;
          item.entity.signState.LastUpdated = action.payload.lastUpdated;
        }
      });
    },
    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.Id === action.payload.id && item.entity.state) {
          item.entity.state.CompanyName = action.payload.companyName;
          item.entity.state.Status = action.payload.status;
        }
      });
    },

    fetchStatistics(state, action: PayloadAction<{ id: number; period: [Date, Date] }>) {
      const curbSpace = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!curbSpace) {
        return;
      }
      curbSpace.loading = true;
    },
    fetchStatisticsSuccess(state, action: PayloadAction<{ id: number; statistics: ISpotStatistics | null }>) {
      const curbSpace = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!curbSpace) {
        return;
      }
      curbSpace.loading = false;

      if (!curbSpace.entity) {
        return;
      }
      curbSpace.entity.statistics = action.payload.statistics;
    },
    fetchStatisticsFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      const curbSpace = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!curbSpace) {
        return;
      }
      curbSpace.loading = false;
      console.error(action.payload.error);
    },

    fetchOccupancyReport(state, action: PayloadAction<{ curbSpaceId: number; occupancySource: OccupancySource }>) {},
    fetchOccupancyReportSuccess(state, action: PayloadAction<{ id: number; report: IOccupancyReportItem[] }>) {
      const spot = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!spot?.entity) {
        return;
      }
      spot.entity.occupancyReport = action.payload.report;
    },
    fetchOccupancyReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchEvents(state, action: PayloadAction<number>) {},
    fetchEventsSuccess(state, action: PayloadAction<{ id: number; events: IParkingEvent[] }>) {
      const spot = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!spot?.entity) {
        return;
      }
      spot.entity.events = action.payload.events;
    },
    fetchEventsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchRegulation(state, action: PayloadAction<number>) {},
    fetchRegulationSuccess(state, action: PayloadAction<{ id: number; regulation: ISpotRegulation | null }>) {
      const spot = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!spot?.entity) {
        return;
      }
      spot.entity.regulation = action.payload.regulation;
    },
    fetchRegulationFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchTransactions(state, action: PayloadAction<number>) {},
    fetchTransactionsSuccess(state, action: PayloadAction<{ id: number; transactions: ITransaction[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.entity.transactions = action.payload.transactions;
    },
    fetchTransactionsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchRevenue(state, action: PayloadAction<number>) {},
    fetchRevenueSuccess(state, action: PayloadAction<{ id: number; revenue: IRevenueItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchKpiReport(state, action: PayloadAction<number>) {},
    fetchKpiReportSuccess(state, action: PayloadAction<{ id: number; report: IKpiReportItem[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.entity.kpiReport = action.payload.report;
    },
    fetchKpiReportFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },

    fetchOccupancySourceReport(state, action: PayloadAction<{ id: number; period: [Date, Date]; occupancySource: OccupancySource }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancySourceReportSuccess(state, action: PayloadAction<{ id: number; report: IOccupancySourceReport | null }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item?.entity) {
        return;
      }
      item.loading = false;
      item.entity.occupancySourceReport = action.payload.report;
    },
    fetchOccupancySourceReportFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedCurbSpacesActions = slice.actions;

// Selectors
export const selectedCurbSpaces = (state: RootState) => state.selectedCurbSpaces;
export const currentCurbSpace = (state: RootState) =>
  state.selectedCurbSpaces.selected.find((x) => x.id === state.selectedCurbSpaces.current);

// Reducer
export const selectedCurbSpacesReducer = slice.reducer;
