import { ILayersSettings } from '../model';

const _localStorageCache: { [key: string]: string | null } = {};

function layersSettingsKey(cityCode: string) {
  return cityCode + ':LAYERS_STATE';
}

function getLayersSettings(cityCode: string): ILayersSettings | null {
  const key = layersSettingsKey(cityCode);
  let settings = _localStorageCache[key];
  if (!settings) {
    settings = localStorage.getItem(key);
    _localStorageCache[key] = settings;
  }

  if (settings) {
    try {
      return JSON.parse(settings);
    } catch (error) {
      console.log(error);
    }
  }

  return null;
}

function saveLayersSettings(cityCode: string, settings: ILayersSettings) {
  const key = layersSettingsKey(cityCode);
  if (settings) {
    const serialized = JSON.stringify(settings);
    _localStorageCache[key] = serialized;
    localStorage.setItem(key, serialized);
  } else {
    _localStorageCache[key] = null;
    localStorage.removeItem(key);
  }
}

export const layersStateSerivce = {
  getLayersSettings,
  saveLayersSettings,
};
