import { Observable } from 'rxjs';
import { getApi, fromAxiosPromise } from './api';
import { DEFAULT_SEARCH_RESULT, ISearchResult } from '../../model';

const BASE_URL = '/search';

const get = (query: string, layers: Array<string>): Observable<ISearchResult> => {
  const types = layers.map((x) => `type=${x}`).join('&');
  query = encodeURIComponent(query);
  return fromAxiosPromise((token) =>
    getApi()
      .get<ISearchResult>(`${BASE_URL}/?query=${query}&${types}`, { cancelToken: token })
      .then((response) => response.data || DEFAULT_SEARCH_RESULT),
  );
};

export const search = {
  get,
};
