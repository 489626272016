import { FC } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useLocation, useNavigate } from 'react-router-dom';

import { CameraStatusIcon, LoadingZoneIcon, MeterTypeIcon, SignStatusIcon } from '../../../map/icons';
import { CameraStatus, MeterType, SignStatus } from '../../../../model';
import { Link } from '../../../common';
import { dateUtils } from '../../../../utils';

import styles from './AlertList.module.scss';
import { selectAlertsState, selectedLoadingZonesActions } from '../../../../features';
import { useAppSelector } from '../../../../store/hooks';
import { useOpenPopupDispatch } from '../../../../hooks';

const WarningIcon = () => <span className='k-icon k-i-warning' style={{ color: '#DDA000' }}></span>;
const CheckIcon = () => <span className='k-icon k-i-check-outline' style={{ color: '#409640' }}></span>;
const DeleteIcon = () => <span className='k-icon k-i-delete' style={{ color: '#D13438' }}></span>;
const CloseIcon = () => <span className='k-icon k-i-close-circle' style={{ color: '#D13438' }}></span>;
const PaymentIcon = () => <span className={`${styles.icon} ${styles.paymentIcon}`}></span>;

const FakeLink: FC<{ title: string }> = ({ title }) => <Link onClick={() => {}}>{title}</Link>;

const getDate = (subtractMinutes: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - subtractMinutes);
  return dateUtils.toLocalUiString(date);
};

export const AlertList: FC<{ onClose: () => void }> = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const popupDispatch = useOpenPopupDispatch();
  const alerts = useAppSelector(selectAlertsState)
    .alerts.slice()
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  const alertsCount = alerts.length + 5;

  const openLoadingZone = (e: React.MouseEvent<Element>, id: number) => {
    e.preventDefault();

    if (!location.pathname.startsWith('/map')) navigate('map');

    popupDispatch(selectedLoadingZonesActions.loadZone({ id, position: null }));
  };

  return (
    <div>
      <Button className={styles.closeButton} icon='close' onClick={() => onClose()} />

      <div className={styles.alertList}>
        <div style={{ fontWeight: 'bold' }}>Alerts ({alertsCount})</div>
        <div className={styles.alertTableContainer}>
          <table className={styles.alertTable}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: '90px' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((x, idx) => (
                <tr key={`${idx}`}>
                  <td>
                    <PaymentIcon />
                  </td>
                  <td>{dateUtils.toLocalUiString(x.timestamp)}</td>
                  <td>
                    <span>
                      <LoadingZoneIcon className={styles.icon} />
                      Loading zone&nbsp;
                      <Link onClick={(e) => openLoadingZone(e, x.id)}>#{x.data.code}</Link>: payment ${x.data.amount} has been made
                    </span>
                  </td>
                </tr>
              ))}

              <tr>
                <td>
                  <CheckIcon />
                </td>
                <td>{getDate(3)}</td>
                <td>
                  <span>
                    <MeterTypeIcon typeId={MeterType.SingleSpot} className={styles.icon} />
                    Meter <FakeLink title='#789' /> updated
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <DeleteIcon />
                </td>
                <td>{getDate(4)}</td>
                <td>
                  <MeterTypeIcon typeId={MeterType.SingleSpot} className={styles.icon} />
                  Meter <FakeLink title='#457' /> deleted
                </td>
              </tr>
              <tr>
                <td>
                  <WarningIcon />
                </td>
                <td>{getDate(5)}</td>
                <td>
                  <span>
                    <SignStatusIcon status={SignStatus.Offline} className={styles.icon} />
                    Sign <FakeLink title='#219' /> status is inactive
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <WarningIcon />
                </td>
                <td>{getDate(6)}</td>
                <td>
                  <span>
                    <CameraStatusIcon status={CameraStatus.Inactive} className={styles.icon} />
                    Camera <FakeLink title='#446' /> status is inactive
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <CloseIcon />
                </td>
                <td>{getDate(7)}</td>
                <td>
                  <span>
                    Failed to upload data for <MeterTypeIcon typeId={MeterType.DoubleSpot} className={styles.icon} /> Meter{' '}
                    <FakeLink title='#690' />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
