import { FunctionComponent } from 'react';

import styles from './StreetSignIcon.module.scss';

interface Props {
  className?: string;
  code: string | undefined;
}

export const StreetSignIcon: FunctionComponent<Props> = ({ className, code }) => {
  return <span className={`${className || ''} ${styles.icon} ${code || ''}`}></span>;
};
