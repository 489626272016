import { Action } from '@reduxjs/toolkit';
import { Observable, of, from } from 'rxjs';
import { filter, concatMap, catchError, mergeMap } from 'rxjs/operators';
import { StateObservable, combineEpics } from 'redux-observable';

import { citiesActions } from '../../common';
import { savedViewsActions } from './saved-views-slice';
import { savedViews as savedViewsApi } from '../../../services/api/saved-views';
import { RootState } from '../../../store';
import { notifications } from '../../../services';
import {
  blockfacesLayerActions,
  camerasLayerActions,
  curbSpacesLayerActions,
  customLayersActions,
  heatmapsFilterActions,
  loadingZonesLayerActions,
  metersLayerActions,
  offstreetZonesLayerActions,
  rppAreasLayerActions,
  rppZonesLayerActions,
  signsLayerActions,
  streetObjectsLayerActions,
  studyAreasLayerActions,
  suggestedStreetsLayerActions,
  zoneObjectsLayerActions,
  zonesLayerActions,
} from '../layers';

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(savedViewsActions.fetch())),
  );

const getSavedViewsEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(savedViewsActions.fetch.match),
    concatMap((action) =>
      from(savedViewsApi.get()).pipe(
        mergeMap((x) => of(savedViewsActions.fetchSuccess(x))),
        catchError((err) => of(savedViewsActions.fetchFailed(err.message))),
      ),
    ),
  );

const addSavedViewEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>) =>
  actions$.pipe(
    filter(savedViewsActions.add.match),
    concatMap((action) => {
      const savedViews = [...state$.value.savedViews.views];
      savedViews.push(action.payload.view);

      return from(savedViewsApi.save(savedViews)).pipe(
        mergeMap((_) => {
          notifications.success('The view has been successfully created.');
          return of(savedViewsActions.saveSuccess(savedViews));
        }),
        catchError((err) => of(savedViewsActions.saveFailed(err.message))),
      );
    }),
  );

const updateSavedViewEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>) =>
  actions$.pipe(
    filter(savedViewsActions.update.match),
    concatMap((action) => {
      const savedViews = [...state$.value.savedViews.views];
      savedViews[action.payload.index] = action.payload.view;

      return from(savedViewsApi.save(savedViews)).pipe(
        mergeMap((_) => {
          notifications.success('The view has been updated successfully.');
          return of(savedViewsActions.saveSuccess(savedViews));
        }),
        catchError((err) => of(savedViewsActions.saveFailed(err.message))),
      );
    }),
  );

const deleteSavedViewEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>) =>
  actions$.pipe(
    filter(savedViewsActions.delete.match),
    concatMap((action) => {
      const savedViews = [...state$.value.savedViews.views].filter((_, idx) => idx !== action.payload.index);

      return from(savedViewsApi.save(savedViews)).pipe(
        mergeMap((_) => {
          notifications.success('The view has been successfully removed.');
          return of(savedViewsActions.saveSuccess(savedViews));
        }),
        catchError((err) => of(savedViewsActions.saveFailed(err.message))),
      );
    }),
  );

const applySavedLayersSettingsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(savedViewsActions.applySavedLayersSettings.match),
    mergeMap((action) =>
      of(
        zonesLayerActions.applySavedLayerSettings(action.payload.zones),
        rppZonesLayerActions.applySavedLayerSettings(action.payload.rppZones),
        rppAreasLayerActions.applySavedLayerSettings(action.payload.rppAreas),
        zoneObjectsLayerActions.applySavedLayerSettings(action.payload.zoneObjects),
        offstreetZonesLayerActions.applySavedLayerSettings(action.payload.offstreetZones),
        loadingZonesLayerActions.applySavedLayerSettings(action.payload.loadingZones),
        curbSpacesLayerActions.applySavedLayerSettings(action.payload.curbSpaces),
        metersLayerActions.applySavedLayerSettings(action.payload.meters),
        signsLayerActions.applySavedLayerSettings(action.payload.signs),
        camerasLayerActions.applySavedLayerSettings(action.payload.cameras),
        streetObjectsLayerActions.applySavedLayerSettings(action.payload.streetObjects),
        heatmapsFilterActions.applySavedLayerSettings(action.payload.heatmapsFilters),
        customLayersActions.applySavedLayerSettings(action.payload.customLayers),
        blockfacesLayerActions.applySavedLayerSettings(action.payload.blockface),
        studyAreasLayerActions.applySavedLayerSettings(action.payload.studyArea),
        suggestedStreetsLayerActions.applySavedLayerSettings(action.payload.suggestedStreets),
      ),
    ),
  );

export const savedViewsEpic = combineEpics(
  citySelectedEpic,
  getSavedViewsEpic,
  addSavedViewEpic,
  updateSavedViewEpic,
  deleteSavedViewEpic,
  applySavedLayersSettingsEpic,
);
