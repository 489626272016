import { AxiosError, AxiosResponse } from 'axios';
import {
  ICreateScreenChangeRequest,
  IRemoveScreenChangeRequest,
  IScreenChangeRequest,
  ISign,
  ISignScreen,
  ISignState,
  ISignStatusCount,
  ISignTelemetry,
  IUpdateScreenChangeRequest,
  ScreenChangeType,
} from '../../model';
import { Utils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/sign';

const get = async (id: number): Promise<ISign> => {
  const result = await getApi()
    .get<ISign>(`${BASE_URL}/${id}`)
    .then((x) => x.data);
  result.Address = Utils.capitalize(result.Address);
  return result;
};

const getZoneSigns = (zoneId: number): Promise<Array<number>> =>
  getApi()
    .get<Array<number>>(`${BASE_URL}/by-zone/?id=${zoneId}`)
    .then((x) => x.data || []);

const getSpotSignsId = (spotsIds: number[]): Promise<Array<number>> => {
  const ids = spotsIds.map((x) => `id=${x}`).join('&');
  return getApi()
    .get<Array<number>>(`${BASE_URL}/by-spots/?${ids}`)
    .then((x) => x.data || []);
};

const getSignTelemetry = async (id: number): Promise<ISignTelemetry | null> => {
  const result = await getApi({ ignoreErrorStatuses: [404] })
    .get<ISignTelemetry>(`${BASE_URL}/${id}/telemetry`)
    .then((x: AxiosResponse) => x.data)
    .catch((error: AxiosError) => {
      if (error.response?.status === 404) {
        return null;
      }
      throw error;
    });

  if (result?.LastUpdated) {
    result.LastUpdated = new Date(result.LastUpdated);
  }
  return result;
};

const getSignState = async (id: number): Promise<ISignState> => {
  const signStatePromise = getApi()
    .get<ISignState>(`${BASE_URL}/${id}/state`)
    .then((x) => x.data);

  const activeScreenPromise = getActiveSignScreen(id);

  const result = await Promise.all([signStatePromise, activeScreenPromise]);

  const signState = result[0];
  signState.LastUpdated = signState.LastUpdated ? new Date(signState.LastUpdated) : null;
  signState.ActiveScreen = result[1];
  return signState;
};

const getActiveSignScreen = (signId: number): Promise<ISignScreen | null> =>
  getApi()
    .get<ISignScreen | null>(`${BASE_URL}/${signId}/active-screen`)
    .then((x) => x.data);

const linkedWithOthers = (signId: number, screenId: number): Promise<boolean> => {
  return getApi()
    .get<{ Value: boolean }>(`${BASE_URL}/${signId}/screen/${screenId}/linked-with-others`)
    .then((x) => x.data.Value);
};

const applyChanges = (changes: IScreenChangeRequest[]): Promise<object> => {
  const formData: FormData = new FormData();

  let index = 0;
  for (const change of changes) {
    switch (change.ChangeType) {
      case ScreenChangeType.Create: {
        const createScreenChangeRequest = change as ICreateScreenChangeRequest;
        formData.append(`[${index}].ImageFile`, createScreenChangeRequest.File, createScreenChangeRequest.File.name);
        formData.append(`[${index}].Led`, createScreenChangeRequest.Led.toString());

        if (createScreenChangeRequest.SignId !== undefined && createScreenChangeRequest.SignId !== null) {
          formData.append(`[${index}].SignId`, createScreenChangeRequest.SignId.toString());
        }
        break;
      }

      case ScreenChangeType.Update: {
        const updateScreenChangeRequest = change as IUpdateScreenChangeRequest;
        if (updateScreenChangeRequest.Led !== undefined && updateScreenChangeRequest.Led !== null) {
          formData.append(`[${index}].Led`, updateScreenChangeRequest.Led.toString());
        }

        if (updateScreenChangeRequest.File !== undefined && updateScreenChangeRequest.File !== null) {
          formData.append(`[${index}].ImageFile`, updateScreenChangeRequest.File, updateScreenChangeRequest.File.name);
        }

        if (updateScreenChangeRequest.SignId !== undefined && updateScreenChangeRequest.SignId !== null) {
          formData.append(`[${index}].SignId`, updateScreenChangeRequest.SignId.toString());
        }

        formData.append(`[${index}].Id`, updateScreenChangeRequest.Id.toString());
        break;
      }

      case ScreenChangeType.Remove: {
        const removeScreenChangeRequest = change as IRemoveScreenChangeRequest;
        formData.append(`[${index}].Id`, removeScreenChangeRequest.Id.toString());
      }
    }

    formData.append(`[${index}].ChangeType`, change.ChangeType.toString());

    index++;
  }

  return getApi()
    .post(`${BASE_URL}/screen`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((x) => x.data);
};

const getSignScreens = (): Promise<ISignScreen[]> =>
  getApi()
    .get<ISignScreen[]>(`${BASE_URL}/screen`)
    .then((x) => x.data || []);

const getStatusCount = (): Promise<ISignStatusCount[]> =>
  getApi()
    .get<ISignStatusCount[]>(`${BASE_URL}/status-count`)
    .then((x) => x.data || []);

export const signs = {
  get,
  getZoneSigns,
  getSpotSignsId,
  getSignTelemetry,
  getSignState,
  linkedWithOthers,
  applyChanges,
  getSignScreens,
  getStatusCount,
};
