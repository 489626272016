import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface IPopupRelationLinesState {
  features: { [Key: string]: GeoJSON.Feature<GeoJSON.LineString> };
}

const initialState: IPopupRelationLinesState = {
  features: {}
};

const slice = createSlice({
  name: 'popup-relation-lines',
  initialState: initialState,
  reducers: {
    setLine(state, action: PayloadAction<{ id: string; line: GeoJSON.LineString } >) {
      state.features[action.payload.id] = { properties: {}, type: 'Feature', geometry: action.payload.line };
    },

    removeLine(state, action: PayloadAction<{ id: string }>) {
      delete state.features[action.payload.id];
    },
    
    clear(state) {
      state.features = {};
    },
  },
});

// Actions
export const popupRelationsLinesActions = slice.actions;

// Selectors
export const selectSelfOrientedPopupConnectingLines = (state: RootState) => {
  return {
    type: 'FeatureCollection',
    features: Object.entries(state.selfOrientedPopup.features).map((x) => x[1]),
  } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
};

// Reducer
export const popupRelationLinesReducer = slice.reducer;
