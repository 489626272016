import { debounce, interval, Subject } from 'rxjs';
import { MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl';
import { useCallback, useContext, useEffect } from 'react';
import { MapContext } from 'react-map-gl/dist/esm/components/map';

const clicks = new Subject<{ evt: MapLayerMouseEvent; features: MapboxGeoJSONFeature[]; handler: (event: MapLayerMouseEvent) => void }>();
clicks.pipe(debounce((x) => interval(10))).subscribe((x) => {
  x.evt.features = x.features;
  x.handler(x.evt);
});

export const useMapLayerPopup = (handler: (event: MapLayerMouseEvent) => void, ...layers: Array<string>) => {
  const { map } = useContext(MapContext);

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const features = evt.features ? evt.features : [];
      clicks.next({ evt, features, handler });
    },
    [handler],
  );

  useEffect(
    () => {
      map.on('click', layers, handleLayerClick);

      return () => {
        map.off('click', layers, handleLayerClick);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, layers],
  );
};
