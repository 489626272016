import { Action, AnyAction } from '@reduxjs/toolkit';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

import { sidebarActions } from './sidebar-slice';
import { citiesActions } from '../../common';

const citySelectedEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(citiesActions.selectCity.match),
        map(() => sidebarActions.clearSelected()));

export const sidebarEpic = combineEpics<AnyAction>(citySelectedEpic);
