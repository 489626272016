import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedArea } from '../../../../model';
import { RootState } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';
import { ISelectedEntity, SelectedEntityName } from '../../../../services';

export interface ISelectedAreaState {
  selected: ISelectedEntity<ISelectedArea>[];
  current: number | null;
}

const initialState: ISelectedAreaState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedArea',
  initialState: initialState,
  reducers: {
    loadArea(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadAreaSuccess(state, action: PayloadAction<{ area: ISelectedArea; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.area.Id,
        action.payload.area,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadAreaFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Areas, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Areas);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Areas, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedArea | null>) {
      state.current = action.payload?.Id || null;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Areas, action.payload.position);
    },
  },
});

// Actions
export const selectedAreaActions = slice.actions;

// Selectors
export const selectedAreas = (state: RootState) => state.selectedAreas;
export const currentArea = (state: RootState) => state.selectedAreas.selected.find((x) => x.id === state.selectedAreas.current);

// Reducer
export const selectedAreaReducer = slice.reducer;
