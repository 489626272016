import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../../services';
import { ISelectedRppZone } from '../../../../../model';
import { RootState, store } from '../../../../../store';
import { selectedSliceLogic } from '../../selected-slice-logic';

export interface ISelectedRppZoneState {
  selected: ISelectedEntity<ISelectedRppZone, string>[];
  current: string | null;
}

const initialState: ISelectedRppZoneState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRppZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.RppZones,
        initialState,
        (id) => id,
        (selected) => {
          selected.map((x) => store.dispatch(selectedRppZonesActions.loadRppZone(x)));
        },
      ).selected;
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.RppZones);
    },
    loadRppZone(state, action: PayloadAction<{ id: string; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadRppZoneSuccess(state, action: PayloadAction<{ zone: ISelectedRppZone; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.zone.Id,
        action.payload.zone,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    change(state, action: PayloadAction<{ id: string; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.RppZones, action.payload.position);
    },
    loadRppZoneFailed(state, action: PayloadAction<{ id: string; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<string | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.RppZones, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.RppZones);
    },
    openPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.RppZones, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedRppZone | null>) {
      state.current = action.payload?.Id || null;
    },
  },
});

// Actions
export const selectedRppZonesActions = slice.actions;

// Selectors
export const selectedRppZones = (state: RootState) => state.selectedRppZones;
export const currentRppZone = (state: RootState) => state.selectedRppZones.selected.find((x) => x.id === state.selectedRppZones.current);

// Reducer
export const selectedRppZonesReducer = slice.reducer;
