import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedCustomLayersLayer } from '../../../../model';
import { RootState } from '../../../../store';

export interface ICustomLayersState {
  layers: { [index: string]: ICustomLayer };
  notActiveLayers: Array<string>;
}

export interface ICustomLayer {
  id: string;
  name: string;
  visibility: boolean;
}

const initialState: ICustomLayersState = {
  layers: {},
  notActiveLayers: [],
};

const slice = createSlice({
  name: 'customLayers',
  initialState: initialState,
  reducers: {
    putLayers(state, action: PayloadAction<ICustomLayer[]>) {
      action.payload.forEach((x) => {
        if (state.notActiveLayers.indexOf(x.id) >= 0) {
          x.visibility = false;
        }
        state.layers[x.id] = x;
      });
    },
    setLayerVisability(state, action: PayloadAction<{ id: string; visibility: boolean }>) {
      const layer = state.layers[action.payload.id];
      if (!layer) {
        return;
      }

      layer.visibility = action.payload.visibility;
    },
    applySavedLayerSettings(state, action: PayloadAction<SavedCustomLayersLayer | undefined>) {
      if (!action.payload?.length) {
        return;
      }

      action.payload.forEach((x) => {
        const layer = state.layers[x];
        if (!layer) {
          return;
        }

        layer.visibility = false;
      });

      state.notActiveLayers = action.payload;
    },
  },
});

// Actions
export const customLayersActions = slice.actions;

// Selectors
export const selectCustomLayers = (state: RootState) => state.customLayers;

// Reducer
export const customLayersReducer = slice.reducer;
