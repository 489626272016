/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useRoutes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { PanelBar, PanelBarSelectEventArguments, PanelBarUtils } from '@progress/kendo-react-layout';

import { Breadcrumbs } from '../../components/reports/breadcrumbs/Breadcrumbs';
import { ReportIFrame } from '../../components/reports/ReportIFrame';
import { useAppSelector } from '../../store/hooks';
import { selectCity } from '../../features';
import { useLoadingZonesEnabled, useReportBuilder } from '../../hooks';
import { ReportsBuilder } from '../../utils';

interface IReport {
  route: string;
  title: string;
  reportUrl: string;
}

const getReports = (reportsBuilder: ReportsBuilder) => {
  const reports = new Array<IReport>();
  const addReportToList = (route: string, title: string, reportUrl: string | null) => {
    if (reportUrl) {
      reports.push({ route, title, reportUrl });
    }
  };

  const dashboard = reportsBuilder.getLoadingZonesDashboard();
  if (dashboard) {
    addReportToList('overview/dashboard', 'Dashboard', dashboard);
    addReportToList('overview/revenue', 'Revenue', reportsBuilder.getLoadingZonesRevenue());
    addReportToList('overview/transactions', 'Transactions', reportsBuilder.getLoadingZonesTransactions());
    addReportToList('overview/occupancy', 'Occupancy', reportsBuilder.getLoadingZonesOccupancy());
    addReportToList('overview/duration', 'Duration', reportsBuilder.getLoadingZonesDuration());
    addReportToList('overview/violations', 'Violations', reportsBuilder.getLoadingZonesViolations());
    addReportToList('overview/customer-analysis', 'Customer Analysis', reportsBuilder.getLoadingZonesCustomerAnalysis());
  }

  return reports;
};

const mapRoutes = (reports: IReport[]) =>
  reports.map((x) => ({
    path: x.route,
    element: <ReportIFrame url={x.reportUrl} />,
    breadcrumb: x.title,
  }));

const mapNavItems = (reports: IReport[]) => reports.map((x) => ({ title: x.title, route: x.route, id: x.route }));

const LoadingZones: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const city = useAppSelector(selectCity);
  const loadingZonesEnabled = useLoadingZonesEnabled();
  const reportsBuilder = useReportBuilder();
  const reports = getReports(reportsBuilder);

  if (!city || reports.length === 0 || !loadingZonesEnabled) {
    return null;
  }

  const routes: Array<{ path: string; element?: JSX.Element; breadcrumb?: string }> = [
    {
      path: '/',
      element: <Navigate to='overview' />,
    },
    {
      path: '/loading-zones', //for breadcrumbs
      breadcrumb: 'Loading Zones',
    },
    {
      path: 'overview',
      element: <ReportIFrame url={reports[0].reportUrl} />,
      breadcrumb: 'Overview',
    },
    ...mapRoutes(reports),
    { path: '*', element: <Navigate to='/loading-zones' replace /> },
  ];

  const setSelectedIndex = (pathName: string) => {
    const path = pathName.replace('/loading-zones/', '');
    return routes.find((item) => item.path === path)?.path;
  };
  const selected = setSelectedIndex(location.pathname);

  const onSelect = (event: PanelBarSelectEventArguments) => {
    event.target.props.route && navigate(event.target.props.route);
  };

  const ReportRouter = () => useRoutes(routes);
  const navItems: Array<any> = [{ title: 'Overview', expanded: true, children: mapNavItems(reports) }];
  const navChildren: any = PanelBarUtils.mapItemsToComponents(navItems);

  return (
    <div className='page report-page'>
      <div className='nav-sidebar'>
        <PanelBar onSelect={onSelect} selected={selected}>
          {navChildren}
        </PanelBar>
      </div>
      <div className='content'>
        <div className='breadcrumbs'>
          <Breadcrumbs routes={routes} />
        </div>
        <ReportRouter />
      </div>
    </div>
  );
};

export default LoadingZones;
