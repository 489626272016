import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from '../../../model';

import { RootState } from '../../../store';

export interface IUserProfileState {
  loading: boolean;
  data: UserProfile | null;
}

const initialState: IUserProfileState = {
  loading: false,
  data: null,
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState,
  reducers: {
    setProfileLoading(state) {
      state.loading = true;
    },
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

// Actions
export const userProfileActions = userProfileSlice.actions;

// Selectors
export const selectUserProfileLoading = (state: RootState) => state.userProfile.loading;
export const selectUserProfile = (state: RootState) => state.userProfile.data;

// Reducer
export const userProfileReducer = userProfileSlice.reducer;
