import { LngLat } from 'mapbox-gl';
import { FunctionComponent } from 'react';
import { Position } from 'geojson';

interface Props {
  className?: string;
  coordinates?: [number, number]
  lngLat?: LngLat;
  position?: Position;
}

export const GeoCoordinatesText: FunctionComponent<Props> = ({ className, coordinates, lngLat, position }) => {
    const getCoords = (): [number, number] => {
        if (coordinates) {
            return coordinates;
        } else if (lngLat) {
            return [lngLat.lng, lngLat.lat]
        } else if (position) {
            return [position[0], position[1]];
        } else {
            throw new Error('Coordinates data isn\'t defined');
        }        
    };

    const coords = getCoords();

    return <span className={`${className ?? ''}`}>{coords[0].toFixed(4)}, {coords[1].toFixed(4)}</span>;
};