import React, { FC, ReactElement } from 'react';

import styles from './ContentPlaceholder.module.scss';

interface Props {
  loading?: boolean | undefined;
  children: React.ReactNode;
  className?: string;
  element: ReactElement;
}

export const ContentPlaceholder: FC<Props> = ({ loading, children, className, element }) => (
  <div className={`${styles.wrapper} ${className || ''}`}>
    {children}
    {loading && <div className={styles.placeholder}>{element}</div>}
  </div>
);
