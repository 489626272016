import { useLocalization } from '@progress/kendo-react-intl';
import { createContext, useContext } from 'react';
import { ExtendedLocalizationService } from '../features/common/globalization/extended-localization-service';


export const ExtendedLocalizationContext = createContext<ExtendedLocalizationService | null>(null);

export function useExtendedLocalization() {
    const extendedLocalizationService = useContext(ExtendedLocalizationContext);
    if (!extendedLocalizationService) {
        throw new Error('Extended localization context has to be set');
    }
    const localizationService = useLocalization();    
    extendedLocalizationService.selLocalizationServiceIfNew(localizationService);
    return extendedLocalizationService;
}