import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Anchor, Popup, PopupEvent, PopupProps, useMap } from 'react-map-gl';
import { selectSidebarState } from '../../../features';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import classNames from 'classnames';
import { Position } from 'geojson';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import styles from './SelfOrientedPopup.module.scss';
import { popupRelationsLinesActions } from './popup-relation-line-slice';

export type SelfOrientedPopupProps = PopupProps & {
  data?: unknown;
  updateOn?: unknown;
  initPosition?: Position | null;
  onDragEnd?: (position: Position) => void;
};

export const SelfOrientedPopup: FunctionComponent<SelfOrientedPopupProps> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const _map = useMap();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popup, setPopup] = useState<any>();
  const sidebar = useAppSelector(selectSidebarState);

  const draggableDivElement = useRef<HTMLDivElement | null>(null);
  const [divMouseCoords, setDivMouseCoords] = useState<[number, number] | null>();
  const mapElement = useMemo(() => document.querySelectorAll('div.mapboxgl-map').item(0), []);

  const [anchor, setAnchor] = useState<Anchor | undefined>(undefined);
  const { map } = useContext(MapContext);

  const popupId = useMemo(() => `popup${Date.now()}`, []);
  const [coords, setCoords] = useState<Position | null>(null);

  const [moved, setMoved] = useState(false);
  const [zoom, setZoom] = useState(map.getZoom());

  useEffect(() => {
    if (!props.initPosition) {
      return;
    }

    const isMoved = !(props.initPosition[0] === props.longitude && props.initPosition[1] === props.latitude);

    setMoved(isMoved);
    if (isMoved) {
      setAnchor('top-left');
    }

    if (!isMoved) {
      dispatch(popupRelationsLinesActions.removeLine({ id: popupId }));
    } else {
      if (!draggableDivElement?.current) {
        return;
      }

      const popupLeftTopPos = map.project({ lng: props.longitude, lat: props.latitude });
      const draggableDivRect = draggableDivElement.current.getBoundingClientRect();
      const poputCenter = [popupLeftTopPos.x + draggableDivRect.width / 2, popupLeftTopPos.y + draggableDivRect.height / 2] as [
        number,
        number,
      ];
      const poputCenterCoords = map.unproject(poputCenter);

      dispatch(
        popupRelationsLinesActions.setLine({
          id: popupId,
          line: { type: 'LineString', coordinates: [props.initPosition, [poputCenterCoords.lng, poputCenterCoords.lat]] },
        }),
      );
    }
  }, [props.initPosition, props.latitude, props.longitude, zoom]);

  const adjustZoom = () => setZoom(map.getZoom());

  useEffect(() => {
    map.on('zoom', adjustZoom);

    return () => {
      map.off('zoom', adjustZoom);
      dispatch(
        popupRelationsLinesActions.removeLine({
          id: popupId,
        }),
      );
    };
  }, []);

  const defineAnchor = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (popup: any): Anchor | undefined => {
      if (moved) {
        return 'top-left';
      }

      const pos: { x: number; y: number } = popup._pos;
      const map = _map.current;
      const element = popup?.getElement();

      if (!popup || !map || !element) return;

      const sidebarWidth = sidebar ? 300 : 0;
      const canvas = map.getCanvas();
      const mapWidth = canvas.width - sidebarWidth;
      const mapHeight = canvas.height;

      const clientRect = element.getBoundingClientRect();
      const elementHeight = clientRect.height;
      const elementWidth = clientRect.width;

      const isRight = pos.x + elementWidth > mapWidth;

      const isBottom = pos.y + elementHeight > mapHeight;
      const isTop = pos.y - elementHeight < 0;

      if (isRight) {
        if (isBottom && isTop) return 'right';

        if (isBottom) return 'bottom-right';
        if (isTop) return 'top-right';
        return 'right';
      }

      return undefined;
    },
    [_map, sidebar, moved],
  );

  useEffect(() => {
    if (props.data && popup) {
      setCoords([props.longitude + 0.00000000001, props.latitude + 0.00000000001]);

      const anchor = defineAnchor(popup);
      setAnchor(anchor);
    } else {
      setCoords(null);
    }
  }, [defineAnchor, popup, props.data, props.latitude, props.longitude, props.updateOn]);

  const onOpen = (e: PopupEvent) => {
    setPopup(e.target);
  };

  const onDragStart = useCallback(
    (clientX: number, clientY: number) => {
      const divRect = draggableDivElement.current?.getBoundingClientRect();
      if (!divRect) {
        return;
      }
      setDivMouseCoords([clientX - divRect.left, clientY - divRect.top]);
    },
    [draggableDivElement],
  );

  const movePopupOnDrag = useCallback(
    (clientX: number, clientY: number, completeDrag: boolean) => {
      if (!divMouseCoords) {
        return;
      }

      if (!draggableDivElement?.current) {
        return;
      }

      setAnchor('top-left');

      const mapDivRect = mapElement.getBoundingClientRect();
      const popupLeftTopPos = [clientX - divMouseCoords[0] + mapDivRect.left, clientY - divMouseCoords[1] - mapDivRect.top] as [
        number,
        number,
      ];
      const popupLeftTopCoords = map.unproject(popupLeftTopPos);
      setCoords([popupLeftTopCoords.lng, popupLeftTopCoords.lat]);

      if (completeDrag && props.onDragEnd) {
        props.onDragEnd([popupLeftTopCoords.lng, popupLeftTopCoords.lat]);
      }
    },
    [divMouseCoords, map, mapElement, props],
  );

  return (
    <Popup
      {...props}
      longitude={coords ? coords[0] : props.longitude}
      latitude={coords ? coords[1] : props.latitude}
      onOpen={onOpen}
      anchor={anchor}
      className={classNames({ [`${styles.hiddenTip}`]: moved }, props.className)}
    >
      <div
        draggable='true'
        ref={draggableDivElement}
        className={classNames(styles.draggable)}
        onDragStart={(e) => onDragStart(e.clientX, e.clientY)}
        onDragEnd={(e) => movePopupOnDrag(e.clientX, e.clientY, true)}
        onTouchStart={(e) => onDragStart(e.changedTouches[0].clientX, e.changedTouches[0].clientY)}
        onTouchMove={(e) => movePopupOnDrag(e.changedTouches[0].clientX, e.changedTouches[0].clientY, false)}
        onTouchEnd={(e) => movePopupOnDrag(e.changedTouches[0].clientX, e.changedTouches[0].clientY, true)}
      >
        {props.children}
      </div>
    </Popup>
  );
};
