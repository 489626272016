import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../store';
import { SavedZoneLayer } from '../../../../model';

export interface IZonesLayerState {
  enabled: boolean;
  count: number;
  displayZoneId: boolean;
  timeLimitEnabled: boolean;
  showPerformanceParkingOnly: boolean;
}

const initialState: IZonesLayerState = {
  enabled: false,
  count: 0,
  displayZoneId: true,
  timeLimitEnabled: true,
  showPerformanceParkingOnly: true,
};

const slice = createSlice({
  name: 'zonesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.count = 0;
      console.log(action);
    },

    setDisplayZoneId(state, action: PayloadAction<boolean>) {
      state.displayZoneId = action.payload;
    },

    setTimeLimitEnabled(state, action: PayloadAction<boolean>) {
      state.timeLimitEnabled = action.payload;
    },

    setPerformanceParkingFilterValue(state, action: PayloadAction<boolean>) {
      state.showPerformanceParkingOnly = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedZoneLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.displayZoneId = action.payload.displayZoneId;
        state.timeLimitEnabled = action.payload.timeLimitEnabled;
        state.showPerformanceParkingOnly = action.payload.showPerformanceParkingOnly ?? initialState.showPerformanceParkingOnly;
      }
    },
  },
});

// Actions
export const zonesLayerActions = slice.actions;

// Selectors
export const selectZonesLayer = (state: RootState) => state.zonesLayer;

// Reducer
export const zonesLayerReducer = slice.reducer;
