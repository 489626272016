import { FunctionComponent } from 'react';
import { OccupancyStatus } from '../../../../model';

import styles from './CurbSpaceStatusIcon.module.scss';

interface Props {
  status: OccupancyStatus | undefined;
  className?: string;
}

export const CurbSpaceStatusIcon: FunctionComponent<Props> = ({ status, className }) => {
  return <span className={`${styles.icon} ${status?.toString() || ''} ${className || ''}`}></span>;
};
