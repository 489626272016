import { IPeriodsReport, ISpotState, IViolationInfo, IZone } from '../../api';

export enum ViolationMeasure {
  Amount = 'Amount',
  Count = 'Count',
}

export interface ISelectedEnforcement {
  zone: IZone;
  spotsStates: ISpotState[];
  violations: IPeriodsReport<IViolationInfo> | null;
}

export const VIOLATION_GROUP_ALL = -1;
