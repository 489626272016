import { LocalizationService } from '@progress/kendo-react-intl';

export class ExtendedLocalizationService extends LocalizationService {
  private static EmptyString = '';
  private _localizationService?: LocalizationService;

  public selLocalizationServiceIfNew(value: LocalizationService): void {
    if (this._localizationService !== value) {
      this._localizationService = value;
    }
  }

  public toLanguageString = (key: string, defaultValue: string): string => {
    if (!this._localizationService) {
      throw new Error('localization service has to be set');
    }
    return this._localizationService.toLanguageString(key, defaultValue);
  };

  public toLanguageStringF = (key: string, args?: Array<any>): string => {
    let languageString = this.toLanguageString(key, ExtendedLocalizationService.EmptyString);

    if (args?.length) {
      for (const arg in args) {
        languageString = languageString.replace('{' + arg + '}', args[arg] ?? ExtendedLocalizationService.EmptyString);
      }
    }
    return languageString;
  };
}
