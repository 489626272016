import { useCallback, useContext, useEffect } from 'react';
import { MapContext } from 'react-map-gl/dist/esm/components/map';

import { Feature, GeoJsonProperties, Point } from 'geojson';
import { LngLat, MapLayerMouseEvent, MapMouseEvent } from 'mapbox-gl';

export const useMapLayerEditing = (
  layer: string,
  isEditing: boolean,
  onMove: (feature: Feature<Point, GeoJsonProperties>, position: LngLat) => void,
  onFinish: () => void,
) => {
  const { map } = useContext(MapContext);

  const mouseDown = useCallback(
    (event: MapLayerMouseEvent) => {
      if (isEditing) {
        event.preventDefault();
        const feature = (event.features as Feature<Point, GeoJsonProperties>[])[0];
        const mouseMove = (event: MapMouseEvent) => {
          if (feature && isEditing) {
            onMove(feature, event.lngLat);
          }
        };

        event.target.once('mouseup', () => {
          event.target.off('mousemove', mouseMove);
          onFinish();
        });

        event.target.on('mousemove', mouseMove);
      }
    },
    [isEditing, onMove, onFinish],
  );

  useEffect(() => {
    map.on('mousedown', layer, mouseDown);

    return () => {
      map.off('mousedown', layer, mouseDown);
    };
  }, [map, layer, isEditing, onMove, onFinish, mouseDown]);
};
