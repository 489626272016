import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { occupancyData } from '../../../../services';
import { occupancyDataActions } from './occupancy-slice';

const fetchMeterOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchMeterOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getMeterOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchMeterOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchMeterOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchZoneOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchZoneOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getZoneOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchZoneOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchZoneOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchBlockfaceOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchBlockfaceOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getBlockfaceOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchBlockfaceOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchBlockfaceOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchStudyAreaOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchStudyAreaOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getStudyAreaOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchStudyAreaOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchStudyAreaOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchSpotOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchSpotOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getSpotOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchSpotOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchSpotOccupancyDataFailed(err.message))),
      ),
    ),
  );

export const occupancyDataEpic = combineEpics(
  fetchMeterOccupancyDataEpic,
  fetchZoneOccupancyDataEpic,
  fetchBlockfaceOccupancyDataEpic,
  fetchStudyAreaOccupancyDataEpic,
  fetchSpotOccupancyDataEpic,
);
